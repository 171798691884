import {Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '../root-store/state';
import { loginAsAdmin } from '../root-store/actions';
import { Observable } from 'rxjs';
import { selectIsSignInError, selectIsSignInLoading } from '../root-store/selectors';

@Component({
  selector: 'app-admin-signin',
  templateUrl: './admin-signin.component.html',
  styleUrls: ['./admin-signin.component.scss']
})
export class AdminSigninComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private store: Store<AppState>) {
  }

  public isSigninLoading$: Observable<boolean>;
  public isSigninError$: Observable<boolean>;

  ngOnInit(): void {

    this.isSigninLoading$ = this.store.pipe(select(selectIsSignInLoading));
    this.isSigninError$ = this.store.pipe(select(selectIsSignInError));

    this.route.paramMap.subscribe(p =>{
      if(p.has('token'))
      {
        const token = p.get('token');
        this.store.dispatch(loginAsAdmin({ payload: { token: token }}));
      }
    });
  }
  
}
