import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/root-store/state';
import { CategoryPickerComponent } from '../../category-picker/category-picker.component';

@Injectable({
  providedIn: 'root'
})
export class CategoryPickerService {

  public pickCategory(successActionDispatcher: (categoryIds: number[])=>void, initialData: number[]=[]){
    let dialogRef = this.dialog.open(CategoryPickerComponent, {
      //height: 'calc(100vh - 4rem)',
      //maxHeight: '48rem',
      width: '48rem',
      panelClass: 'common-dialog-container',
      data: {
        categoryIds: initialData
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined && result !== null){
        successActionDispatcher(result);
      }
    });
  }

  constructor(private dialog: MatDialog, private store: Store<AppState>) { }
}
