import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Patterns } from '@hrra/core';
import { ButtonTypeAttributeEnum, ButtonTypeEnum } from '@hrra/ui';
import { IconButtonTypeEnum } from '@hrra/ui';
import { changeWorkExperienceIsCurrentForAdmin, downloadWorkExperienceAttachmentForAdmin, editJobseekerWorkExperienceForAdmin, setJobseekerWorkExperienceForAdmin, setSelectedCategoriesForAdmin } from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/actions';
import { isFileUploading, selectedCategoires, selectFileName, selectIsFileSizeValid, selectIsJobseekerProfileUpdating, selectJobseekerId, selectJobseekerModerationLastChangeDate, selectJobseekerWorkExperience, selectMonthList, selectSelectedCategoryIds, selectSelectedFileText, selectWorkExperienceIsCurrent } from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/selectors';
import { selectSystemConfigs } from 'src/app/root-store/selectors';
import { AppState } from 'src/app/root-store/state';
import { SystemConfig } from 'src/app/shared/models/system-config-model';
import { CategoryPickerService } from '../shared/services/category-picker.service';
import { JobseekerFileService } from '../shared/services/jobseeker-file.service';
import { FileUploadResultViewModel, JobseekerWorkExperienceModel } from '../shared/services/jobseeker.service';
import { JobseekerWorkExperienceViewModel, BasicOptionItem } from '@hrra/common-models';

@Component({
  selector: 'app-jobseeker-work-experience-edit-for-admin',
  templateUrl: './jobseeker-work-experience-edit-for-admin.component.html',
  styleUrls: ['./jobseeker-work-experience-edit-for-admin.component.scss']
})
export class JobseekerWorkExperienceEditForAdminComponent implements OnInit {
  navigationSubs: Subscription;

  editForm: UntypedFormGroup;

  jobseekerWorkExperience$: Observable<JobseekerWorkExperienceViewModel>;
  monthList$: Observable<BasicOptionItem[]>;
  selectedFile$: Observable<FileUploadResultViewModel>;
  isFileUploading$: Observable<boolean>;
  selectedCategoryIds$: Observable<number[]>;
  selectedCategories$: Observable<any>;
  isCurrent$: Observable<boolean>;
  isUpdating$: Observable<boolean>;
  selectedFileText$: Observable<String>;
  isFileSizeValid$: Observable<boolean>;
  systemConfigs$: Observable<SystemConfig>;

  jobseekerId$: Observable<number>;
  moderationLastChangeDate$: Observable<Date>;
  
  public title: string;
  public buttonType: any;
  public buttonAttributeType: any;
  public iconButtonType: any;
  public categoryButtonIsTouched: boolean;

  constructor(private fb: UntypedFormBuilder, 
              private store: Store<AppState>, @Inject(MAT_DIALOG_DATA) public data, 
              private categoryPickerService: CategoryPickerService, 
              public dialogRef: MatDialogRef<JobseekerWorkExperienceEditForAdminComponent>,
              private fileService: JobseekerFileService,
              private router: Router) {
    this.iconButtonType = IconButtonTypeEnum;
    this.buttonType = ButtonTypeEnum;
    this.buttonAttributeType = ButtonTypeAttributeEnum;

    this.editForm = this.fb.group({
      positionTitle: new UntypedFormControl(null, [Validators.required]),
      employer: new UntypedFormControl(null, [Validators.required]),
      dateFromMonth: new UntypedFormControl(null),
      dateFromYear: new UntypedFormControl(null, [Validators.required, Validators.pattern(Patterns.year)]),
      dateToMonth: new UntypedFormControl(null),
      dateToYear: new UntypedFormControl(null, [Validators.pattern(Patterns.year)]),
      isCurrent: new UntypedFormControl(false),
      description: new UntypedFormControl(null),
    }, { validator: this.customValidator},)

    this.navigationSubs = this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.dialogRef.close();
      }
    });
   }

   customValidator(group : UntypedFormGroup) : {[s:string ]: boolean} {
    if (group) {
      let dateToYear = (group.controls['dateToYear'].value == null || group.controls['dateToYear'].value == '') ? null : +group.controls['dateToYear'].value;
      let dateToMonth = group.controls['dateToMonth'].value;
      let dateFromYear = +group.controls['dateFromYear'].value;
      let dateFromMonth = group.controls['dateFromMonth'].value;
      let isCurrent = group.controls['isCurrent'].value;

      dateFromMonth = dateFromMonth || 1;
      dateToMonth = dateToMonth || 12;

      var isDateValid = isCurrent || (dateToYear == null || (dateFromYear < dateToYear || (dateToYear == dateFromYear && dateFromMonth <= dateToMonth)));

      let customValidations:  {[s:string ]: boolean} = {};

      if(!isDateValid) {
          customValidations['date'] = true;
      }

      if(!isCurrent && dateToYear == null) {
          customValidations['RequiredYear'] = true;
      }

      return customValidations;
    }
  }

  ngOnInit(): void {
    this.title = this.data.title;

    this.monthList$ = this.store.pipe(select(selectMonthList));
    this.jobseekerWorkExperience$ = this.store.pipe(select(selectJobseekerWorkExperience));
    this.selectedFile$ = this.store.pipe(select(selectFileName));
    this.isFileUploading$ = this.store.pipe(select(isFileUploading));
    this.selectedCategoryIds$ = this.store.pipe(select(selectSelectedCategoryIds));
    this.selectedCategories$ = this.store.pipe(select(selectedCategoires));
    this.isCurrent$ = this.store.pipe(select(selectWorkExperienceIsCurrent));
    this.isUpdating$ = this.store.pipe(select(selectIsJobseekerProfileUpdating));
    this.selectedFileText$ = this.store.pipe(select(selectSelectedFileText));
    this.isFileSizeValid$ = this.store.pipe(select(selectIsFileSizeValid));
    this.systemConfigs$ = this.store.pipe(select(selectSystemConfigs));

    this.jobseekerId$ = this.store.select(selectJobseekerId);
    this.moderationLastChangeDate$ = this.store.select(selectJobseekerModerationLastChangeDate);

    setTimeout(() => {
      this.store.dispatch(setJobseekerWorkExperienceForAdmin({payload: this.data.workExperience}));
    }, 0);
  }

  onFileSelect(event, fileLimit, jobseeekerId){
    this.fileService.uploadTempFileForAdmin(event, fileLimit, jobseeekerId);
  }

  downloadAttachment(objectId: string, jobseekerId: number){
    this.store.dispatch(downloadWorkExperienceAttachmentForAdmin({payload: {objectId, jobseekerId}}));
  }

  onSave(filename: string, categories: number[], isFileSizeValid: boolean, jobseekerId: number, moderationLastChangeDate: Date, objectId: string){
    if(this.editForm.valid && categories?.length > 0 && isFileSizeValid){
      var value = this.editForm.value;

      let jobseekerWorkexperience = <JobseekerWorkExperienceModel>{
        jobseekerWorkExperienceId: this.data.workExperience?.jobseekerWorkExperienceId,
        dateFromMonth: value.dateFromMonth,
        dateFromYear: value.dateFromYear,
        dateToMonth: value.dateToMonth,
        dateToYear: value.dateToYear,
        isCurrent: value.isCurrent,
        employer: value.employer,
        positionTitle: value.positionTitle,
        description: value.description,
        occupationCategories: categories,
        filename: filename,
        objectId: objectId
      }

      this.store.dispatch(editJobseekerWorkExperienceForAdmin({payload: { jobseekerWorkexperience, jobseekerId, moderationLastChangeDate }}));
    }
  }

  onCurrentExperience(isCurrent){
    if(!isCurrent){
      this.editForm.get('dateToMonth').reset();
      this.editForm.get('dateToYear').reset();
    }
    this.store.dispatch(changeWorkExperienceIsCurrentForAdmin());
  }

  public categoryPicker(categories: number[]) {
    this.categoryButtonIsTouched = true;

    this.categoryPickerService.pickCategory(categoryIds => {
      this.store.dispatch(setSelectedCategoriesForAdmin({payload: categoryIds}));
    },
      categories
    );
  }

  ngOnDestroy() {
    this.navigationSubs.unsubscribe();
  }
}
