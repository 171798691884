import { Component, OnInit } from '@angular/core';
import { theme } from 'src/environments/theme';
import { Customer } from '../shared/models/customer-model';
import { Store, select } from '@ngrx/store';
import * as fromCustomerSelectors from 'src/app/root-store/customer-store/selectors';
import { changeFullDescriptionMode, cvBoxApply, loadCustomer } from 'src/app/root-store/customer-store/public-page-actions';
import { Observable, Subscription } from 'rxjs';
import { AppState } from 'src/app/root-store/state';
import { ActivatedRoute } from '@angular/router';
import { PageNameEnum } from 'src/app/shared/models/enums/page-names-enum';
import { User } from 'src/app/user/shared/models/user-model';
import { UserTypeEnum } from 'src/app/user/shared/models/enums/user-type-enum';
import { ProviderWebsiteUrlMap, selectCurrentUserType, selectIsCustomerSignedIn, selectIsJobseekerSignedIn, selectIsSignedIn, selectUser } from 'src/app/root-store/selectors';
import { isCustomerDescriptionLong, isWithFullDescription, selectCustomerAnnouncements, selectCustomerMetaData, selectCustomerDescription, selectIsCustomerLoading, selectCustomerPageDoesNotExist, selectIsCustomerLoaded } from 'src/app/root-store/customer-store/selectors';
import { MetaService } from 'src/app/shared/services/meta.service';
import { Announcement } from 'src/app/announcement/shared/models/announcement-model';
import { loadLastListingPage } from 'src/app/root-store/actions';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-customer-public-page',
  templateUrl: `./${theme.name}/customer-public-page.component.html`,
  styleUrls: [`./${theme.name}/customer-public-page.component.scss`]
})
export class CustomerPublicPageComponent implements OnInit {

  metaDataSubscription: Subscription;

  date = new Date();

  isSignedIn$: Observable<boolean>;
  isJobseekerSignedIn$: Observable<boolean>;
  isCustomerSignedIn$: Observable<boolean>;
  user$: Observable<User>;
  
  IsBannerVisible = false;
  
  customer$: Observable<Customer>;
  isLoading$: Observable<boolean>;
  customerAnnouncementList$: Observable<Announcement[]>;
  customerDescription$: Observable<String>;
  isCustomerDescriptionLong$: Observable<boolean>;
  isWithFullDescription$: Observable<boolean>;
  pageDoesNotExist$: Observable<boolean>;
  isCustomerLoaded$: Observable<boolean>;
  providerWebsiteUrlMap$: Observable<{ [index: number]: string }>;

  userType$: Observable<any>;

  public pageName: any;
  public userType: any;
  public customerStatus: any;

  constructor(private store: Store<AppState>, 
              private route: ActivatedRoute,
              private metaService: MetaService,
              private dialogService: DialogService,
              private translate: TranslateService) { this.pageName = PageNameEnum, this.userType = UserTypeEnum
}


  ngOnInit(): void {
    this.store.dispatch(loadLastListingPage({payload: PageNameEnum.CustomerPublicPage}));

    this.route.paramMap.subscribe(p => {
      let id = +p.get('id');
      let urlTitle = p.has('title') ? p.get('title') : null;
      
      this.store.dispatch(loadCustomer({ payload: { customerId: id, urlTitle: urlTitle }}));
    });

    this.customer$ = this.store.pipe(select(fromCustomerSelectors.selectCustomer));

    this.isSignedIn$ = this.store.pipe(select(selectIsSignedIn));
    this.isJobseekerSignedIn$ = this.store.pipe(select(selectIsJobseekerSignedIn));
    this.isCustomerSignedIn$ = this.store.pipe(select(selectIsCustomerSignedIn));
    this.user$ = this.store.pipe(select(selectUser));
    this.providerWebsiteUrlMap$ = this.store.pipe(select(ProviderWebsiteUrlMap));

    this.isLoading$ = this.store.pipe(select(selectIsCustomerLoading));
    this.customerAnnouncementList$ = this.store.pipe(select(selectCustomerAnnouncements));
    this.customerDescription$ = this.store.pipe(select(selectCustomerDescription));
    this.isCustomerDescriptionLong$ = this.store.pipe(select(isCustomerDescriptionLong));
    this.isWithFullDescription$ = this.store.pipe(select(isWithFullDescription));
    this.pageDoesNotExist$ = this.store.pipe(select(selectCustomerPageDoesNotExist));
    this.isCustomerLoaded$ = this.store.pipe(select(selectIsCustomerLoaded));
    this.metaDataSubscription = this.store.pipe(select(selectCustomerMetaData)).subscribe(
      (data) => {
        this.metaService.updateMetaData(data.website, data.metaData);
      }
    );

    this.userType$ = this.store.select(selectCurrentUserType);
  }

  ngOnDestroy() {
    this.metaDataSubscription.unsubscribe()
  }

  showText(){
    this.store.dispatch(changeFullDescriptionMode());
  }

  onCvSend(customerId: number, isSignedIn: boolean, isJobseekerSignedIn: boolean) {
    if(!isSignedIn){
      let text =  this.translate.instant('message.ApplicantMustSignInToApplyToAnnouncement');
      let routerText = this.translate.instant('label.SignIn');
      let routerLink = '/jobseeker/sign-in'

      this.dialogService.openExeptionDialog(text,  routerText,  routerLink);
    }
    else if(isJobseekerSignedIn){
      this.dialogService.openConfirmDialog('message.AreYouSure')
      .afterClosed().subscribe(res => {
        if(res){
          this.store.dispatch(cvBoxApply({payload: {customerId: customerId}}));
        }
      });
    }
  }
}
