<div *ngIf="{ jobseekerLanguage: jobseekerLanguage$ | async,
              languageList: languageList$ | async,
              languageProficiencyLevelList: languageProficiencyLevelList$ | async,
              languageHasAttachment: languageHasAttachment$ | async,
              selectedFile: selectedFile$ | async,
              isFileUploading: isFileUploading$ | async,
              isUpdating: isUpdating$ | async,
              selectedFileText: selectedFileText$ | async,
              systemConfigs: systemConfigs$ | async,
              isFileSizeValid: isFileSizeValid$ | async
            } as data">
    <app-spinner [isLoading]="isUpdating$ | async" [isFixedPosition]="false">
        <form [formGroup]="editForm" #ngForm="ngForm" [appConnectForm]="data.jobseekerLanguage" class="form">
            <div class="title">{{title | translate}}</div>
    
            <div class="form-item language">
                <label *ngIf="isCreateForm" class="form-item__label language__label">
                    {{'label.ChooseLanguage' | translate}}
                </label>
    
                <div class="form-item__value--edit-language-name" *ngIf="!isCreateForm">{{data.jobseekerLanguage?.name}}</div>
                <select class="form-item__value language__value" *ngIf="isCreateForm" formControlName="worldLanguageId">
                    <option *ngFor="let language of data.languageList" [ngValue]="language.value">
                        {{language.name}}
                    </option>
                </select>
                <mat-error *ngIf="editForm.controls['worldLanguageId'].hasError('required') && (editForm.controls['worldLanguageId'].touched || ngForm.submitted)" class="validation-message">
                    {{'message.MandatoryField' | translate}}
                </mat-error>
            </div>
    
            <div class="form-item level">
                <label class="form-item__label level__label">{{'label.LevelOfKnowledge' | translate}}</label>
    
                <select class="form-item__value level__value"  formControlName="languageProficiencyLevelId">
                    <option *ngFor="let level of data.languageProficiencyLevelList" [ngValue]="level.value">
                        {{level.name}}
                    </option>
                </select>
                <mat-error *ngIf="editForm.controls['languageProficiencyLevelId'].hasError('required') && (editForm.controls['languageProficiencyLevelId'].touched || ngForm.submitted)" class="validation-message">
                    {{'message.MandatoryField' | translate}}
                </mat-error>
            </div>
    
            <input type="file" style="display:none;" #hiddenfileinput (change)="onFileSelect($event, data.systemConfigs.jobseeker.jobseekerAttachmentMaxSize)"/>
            <div *ngIf="!data.languageHasAttachment" class="form-item attachment">
                <div>
                                    <div class="attachment__text">{{ 'label.UploadDocuments' | translate }}</div>
                <button (click)="hiddenfileinput.click()" class="attachment__choose-button" type="button">{{'label.Select' | translate}}</button>                
                <div *ngIf="data.selectedFile?.filename != null" class="selected-file">
                    {{data.selectedFileText}}
                </div>
                </div>
            </div>
            <div *ngIf="data.languageHasAttachment" class="form-item attachment">
                <app-advanced-button (click)="downloadAttachment(data.jobseekerLanguage?.jobseekerLanguageId, jobseekerId)" [iconType]="iconButtonType.attachment" [text]="'label.Attachment' | translate" [type]="'blue'" class="attachment__btn attachment__btn--uploaded"></app-advanced-button>
                <button class="attachment__delete" type="button">{{'label.Delete' | translate}}</button>
                <button  (click)="hiddenfileinput.click()" class="attachment__replace" type="button">{{'label.Change' | translate}}</button>
            </div>
            <mat-progress-bar *ngIf="data.isFileUploading" mode="indeterminate"></mat-progress-bar>
            <div *ngIf="!data.isFileSizeValid && data.isFileSizeValid != null" class="attachment-size-validation">
                <mat-error>
                    {{'message.JobseekerFileSizeValidation' | translate}}
                </mat-error>
            </div>
    
            <mat-dialog-actions>
                <div class="buttons">
                    <app-button (click)="onSave(data.selectedFile?.filename, data.isFileSizeValid)" [text]="'label.Save' | translate" [type]="buttonType.coloured" [typeAttribute]="buttonAttributeType.submit" class="button-save"></app-button>
                    <app-button [mat-dialog-close] ="true" [text]="'label.Cancel' | translate" [type]="buttonType.uncoloured" [typeAttribute]="buttonAttributeType.button"></app-button>
                </div>
            </mat-dialog-actions>
        </form>
    </app-spinner>
</div>
