import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobseekerLanguage, UserTypeEnum } from '@hrra/common-models';
import { IconButtonTypeEnum } from '@hrra/ui';

@Component({
  selector: 'hrra-jobseejer-language',
  templateUrl: './jobseejer-language.component.html',
  styleUrls: ['./jobseejer-language.component.scss']
})
export class JobseejerLanguageComponent implements OnInit {

  public iconButtonType: any;
  public type: any;

  @Input() language: JobseekerLanguage;
  @Input() languageIsInMod: boolean;
  @Input() userType: UserTypeEnum;

  @Output() onEditLanguage: EventEmitter<{id: number, objectId: string}> = new EventEmitter<{id: number, objectId: string}>();
  @Output() onDeleteLanguage: EventEmitter<{id: number, objectId: string}> = new EventEmitter<{id: number, objectId: string}>();
  @Output() onDownloadLanguageAttachment: EventEmitter<string> = new EventEmitter<string>();

  constructor() { this.iconButtonType = IconButtonTypeEnum, this.type = UserTypeEnum }

  ngOnInit(): void {
  }

  public onEdit(id: number, objectId: string){
    this.onEditLanguage.emit({ id: id, objectId: objectId });
  }

  public onDelete(id: number, objectId:string){
    this.onDeleteLanguage.emit({id: id, objectId: objectId});
  }

  downloadAttachment(objectId: string){
    this.onDownloadLanguageAttachment.emit(objectId);
  }
}
