import { Component, OnInit, Input } from '@angular/core';
import { theme } from 'src/environments/theme';
import { Announcement } from '../shared/models/announcement-model';
import { AnnouncementFrameTypeEnum, BrandingAnnouncementTileTypeEnum, UserTypeEnum } from '@hrra/common-models';
import { AnnouncementListingSectionEnum } from '@hrra/common-models';
import { ApplicationMethodEnum } from '@hrra/common-models';
import { PageNameEnum } from 'src/app/shared/models/enums/page-names-enum';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/root-store/state';
import { addToFavorites, removeFromFavorites } from 'src/app/root-store/actions';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { selectTimeZone } from 'src/app/root-store/selectors';
import { AnnouncementBaubleTypeEnum } from '@hrra/common-models';

@Component({
  selector: 'app-announcement-item',
  templateUrl: `./${theme.name}/announcement-item.component.html`,
  styleUrls: [`./${theme.name}/announcement-item.component.scss`]
})
export class AnnouncementItemComponent implements OnInit {
  companyHasCvBox = true;

  @Input() announcement: Announcement;
  @Input() pageName: PageNameEnum;
  @Input() date: Date;
  @Input() providerWebsiteUrlMap: { [index: number]: string };
  @Input() userType: any;

  public page: any;
  public announcementFrameType: any;
  public announcementBaubleType: any;
  public announcementType: any;
  public applicationMethodType: any;
  public brandingAnnouncementTileType: any;

  timeZone$: Observable<string>;
  announcementListSectionMap: { [index: number]: string };

  userTypeEnum: any;

  constructor(private store: Store<AppState>, private translate: TranslateService) {
    this.announcementFrameType = AnnouncementFrameTypeEnum;
    this.announcementBaubleType = AnnouncementBaubleTypeEnum;
    this.announcementType = AnnouncementListingSectionEnum;
    this.applicationMethodType = ApplicationMethodEnum;
    this.brandingAnnouncementTileType = BrandingAnnouncementTileTypeEnum;
    this.page = PageNameEnum;

    this.userTypeEnum = UserTypeEnum;

    this.announcementListSectionMap = {
      [AnnouncementListingSectionEnum.VipCV]: 'label.VipCv',
      [AnnouncementListingSectionEnum.Vip]: 'label.Vip',
      [AnnouncementListingSectionEnum.Exclusive]: 'label.Exclusive',
      [AnnouncementListingSectionEnum.P2]: 'label.PrioStandard',
      [AnnouncementListingSectionEnum.P1]: 'label.PrioStart'
    };
  }

  ngOnInit(): void {
    this.timeZone$ = this.store.pipe(select(selectTimeZone));
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }

  onToggleFavorite() {
    let announcementId = this.announcement.announcementId;
    if (this.announcement.isFavorite) {
      this.store.dispatch(removeFromFavorites({ payload: { announcementId: announcementId } }));
    }
    else {
      this.store.dispatch(addToFavorites({ payload: { announcementId: announcementId } }))
    }
  }

  public isPublishedToday(): boolean {

    let rawActualPublishDate = this.announcement.renewalDate || this.announcement.publishDate;
    let actualPublishDate = new Date(rawActualPublishDate);

    let currentDate = this.date;

    let publishDateKey = this.getDateKey(actualPublishDate);
    let currentDateKey = this.getDateKey(currentDate);

    return publishDateKey == currentDateKey;

  }

  public isDeadLineToday(): boolean {
    let annDeadlineDate = new Date(this.announcement.deadlineDate);
    let currentDate = this.date;

    let deadlineDateKey = this.getDateKey(annDeadlineDate)
    let currentDateKey = this.getDateKey(currentDate);

    return deadlineDateKey == currentDateKey;
  }

  public getDateKey(date: Date): string {
    return date.getDay() + "/" + date.getMonth() + "/" + date.getFullYear();
  }
}
