<div
  *ngIf="{
    isSignedIn: isSignedIn$ | async,
    user: user$ | async,
    currentLanguage: currentLanguage$ | async,
    alternativeLanguage: alternativeLanguage$ | async,
    defaultLanguage: defaultLanguage$ | async
  } as data"
>
  <app-header-public
    *ngIf="!data.isSignedIn"
    [currentLanguage]="data.currentLanguage"
    [alternativeLanguage]="data.alternativeLanguage"
    [defaultLanguage]="data.defaultLanguage"
  >
  </app-header-public>

  <app-header-admin
    *ngIf="data.isSignedIn && data.user?.userType === userType.Internal"
    [currentLanguage]="data.currentLanguage"
    [alternativeLanguage]="data.alternativeLanguage"
    [defaultLanguage]="data.defaultLanguage"
    [user]="data.user"
    [isSignedIn]="data.isSignedIn"
  ></app-header-admin>

  <app-header-jobseeker
    *ngIf="data.isSignedIn && data.user?.userType == userType.Jobseeker"
    [currentLanguage]="data.currentLanguage"
    [alternativeLanguage]="data.alternativeLanguage"
    [defaultLanguage]="data.defaultLanguage"
    [user]="data.user"
    [isSignedIn]="data.isSignedIn"
  >
  </app-header-jobseeker>
</div>
