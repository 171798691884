import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenderEnum, UserTypeEnum } from '@hrra/common-models';
import { IconButtonTypeEnum } from '@hrra/ui';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hrra-jobseeker-personal-info',
  templateUrl: './jobseeker-personal-info.component.html',
  styleUrls: ['./jobseeker-personal-info.component.scss']
})
export class JobseekerPersonalInfoComponent implements OnInit {
  
  public gender: any;
  public iconButtonType: any;
  public type: any;

  @Input() personalInfo;
  @Input() isPreview: boolean;
  @Input() timeZone: string;
  @Input() userType: UserTypeEnum;
  @Input() currentLang: string;

  @Output() onEditPersonalInfo: EventEmitter<void> = new EventEmitter<void>();

  constructor(private translate: TranslateService) {
    this.gender = GenderEnum;
    this.iconButtonType = IconButtonTypeEnum,
    this.type = UserTypeEnum
   }

  ngOnInit(): void {}

  public onEdit(){
    this.onEditPersonalInfo.emit();
  }
}
