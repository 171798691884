<div *ngIf="{ jobseekerEducation: jobseekerEducation$ | async,

              isInstitutionListAvailable: isInstitutionListAvailable$ | async,
              isProgramListAvailable: isProgramListAvailable$ | async,

              institutionSelectModeOn: institutionSelectModeOn$ | async,
              programSelectModOn: programSelectModOn$ | async,

              educationLevel: educationLevel$ | async,
              isProgramAvailable: isProgramAvailable$ | async,
              isSpecializationAvailable: isSpecializationAvailable$ | async,
              hasAttachment: hasAttachment$ | async,

              programList: programList$ | async,
              filteredProgramOptions: filteredProgramOptions$ | async,

              educationalInstitutionList: educationalInstitutionList$ | async,
              filteredInstitutionOptions: filteredInstitutionOptions$ | async,

              selectedFile: selectedFile$ | async,

              educationalInstitutionMap: educationalInstitutionMap$ | async,
              programMap: programMap$ | async,

              isFileUploading: isFileUploading$ | async,
              isUpdating: isUpdating$ | async,
              selectedFileText: selectedFileText$ | async,
              systemConfigs: systemConfigs$ | async,
              isFileSizeValid: isFileSizeValid$ | async,
              moderationLastChangeDate: moderationLastChangeDate$ | async,

            } as data">
        
    <app-spinner [isLoading]="isUpdating$ | async" [isFixedPosition]="false">
        <form [formGroup]="editForm" #ngForm="ngForm" [appConnectForm]="data.jobseekerEducation" class="form">
        
            <div class="title">{{title | translate}}</div>

            <div class="form-item education-level">
                <label class="form-item__label education-level__label">{{'label.EducationLevel' | translate}}</label>
                <select class="form-item__value education-level__value"  type="text" formControlName="educationLevelId" (change)='onLevelChanage($event)'>
                    <option [ngValue]="null"></option>
                    <option *ngFor="let level of educationLevelList$ | async" [ngValue]="level.value">
                        {{level.name}}
                    </option>
                </select>
                <mat-error *ngIf="editForm.controls['educationLevelId'].hasError('required') && (editForm.controls['educationLevelId'].touched || ngForm.submitted)" class="validation-message validation-message--educationLevelId">{{'message.MandatoryField' | translate}}</mat-error>
            </div> 

            <div *ngIf="data.institutionSelectModeOn && data.isInstitutionListAvailable" class="form-item institution">
                <mat-form-field>
                    <label class="form-item__label institution__label" *ngIf="data.educationLevel !== EducationLevelType.College">{{'label.UniversityName' | translate}}</label>
                    <label class="form-item__label institution__label" *ngIf="data.educationLevel === EducationLevelType.College">{{'label.NameOfInstitution' | translate}}</label>
                    <div class="autocomplete-input-container">
                        <input class="form-item__value institution__value autocomplete-input-container__value--autocomplete" (input)="onInstitutionSearchChange($event.target.value)" type="text" matInput [matAutocomplete]="auto" formControlName="educationalInstitutionId"/>
                        <svg-icon src="/assets/icons/chevron-down.svg" [svgClass]="'autocomplete-input-container__icon'"></svg-icon>
                    </div>
                    <mat-autocomplete #auto="matAutocomplete"  (optionSelected)='onChangaeInstitution($event)' [displayWith]="displayInstitution.bind(this, data.educationalInstitutionList)">
                        <mat-option *ngFor="let institution of data.filteredInstitutionOptions" [value]="institution.educationalInstitutionId">
                          {{ institution.displayName }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-error *ngIf="editForm.hasError('institution') && (editForm.controls['educationalInstitutionId'].touched || ngForm.submitted)" class="validation-message validation-message--institution">{{'message.MandatoryField' | translate}}</mat-error>
            </div>
    
    
            <div *ngIf="!data.institutionSelectModeOn" class="form-item institution">
                <label class="form-item__label institution__label">{{ (data.jobseekerEducation?.educationLevelId == null ? 'label.InstitutionName' : institutionNameMap[data.educationLevel]) | translate }}</label>
                <input class="form-item__value institution__value" maxlength="200" type="text" formControlName="institution" name="institution">
                <mat-error *ngIf="editForm.hasError('institution') && (editForm.controls['institution'].touched || ngForm.submitted)" class="validation-message validation-message--institution">{{'message.MandatoryField' | translate}}</mat-error>
            </div>
    
            <div *ngIf="data.isInstitutionListAvailable" class="institution-select">
                <button class="institution-select__button" (click)="onInstitutionSelectMode()" type="button">
                    {{ (data.institutionSelectModeOn ? institutionNameNotInListLabelMap[data.educationLevel] : 'label.SelectFromList') | translate }}
                </button>
            </div>
    
            <div *ngIf="data.programSelectModOn && data.isProgramAvailable"  class="form-item program">
                <mat-form-field>
                    <label class="form-item__label program__label">{{'label.EducationalProgram' | translate}}</label>
                    <div class="autocomplete-input-container">
                        <input class="form-item__value program__value autocomplete-input-container__value--autocomplete" (input)="onProgramSearchChange($event.target.value)" type="text" matInput [matAutocomplete]="auto" formControlName="educationalProgramId"/>
                        <svg-icon src="/assets/icons/chevron-down.svg" [svgClass]="'autocomplete-input-container__icon'"></svg-icon>
                    </div>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProgram.bind(this, data.programList)">
                        <mat-option *ngFor="let program of data.filteredProgramOptions" [value]="program.educationalProgramId">
                          {{ program.displayName }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-error *ngIf="editForm.hasError('program') && (editForm.controls['educationalProgramId'].touched || ngForm.submitted)" class="validation-message validation-message--program">{{'message.MandatoryField' | translate}}</mat-error>
            </div>

            <div *ngIf="!data.programSelectModOn && data.isProgramAvailable"  class="form-item program">
                <label class="form-item__label program__label">{{'label.EducationalProgram' | translate}}</label>
                <input class="form-item__value program__value" maxlength="200" type="text" formControlName="educationalProgramName" name="educationalProgramName"/>
                <mat-error *ngIf="editForm.hasError('program') && (editForm.controls['educationalProgramName'].touched || ngForm.submitted)" class="validation-message validation-message--program">{{'message.MandatoryField' | translate}}</mat-error>
            </div>
    
            <div *ngIf="data.isProgramListAvailable" class="program-select">
                <button class="program-select__button" (click)="onProgramSelectMode()" type="button">{{ (data.programSelectModOn ? 'label.ProgramNotInList' : 'label.SelectFromList') | translate}}</button>
            </div>
        
            <div *ngIf="data.isSpecializationAvailable" class="form-item specialization">
                <label class="form-item__label specialization__label">{{'label.Specialization' | translate}}</label>
                <input class="form-item__value specialization__value" maxlength="200" type="text" formControlName="specialization" name="specialization"/>
            </div>
        
            <div class="form-item date-from">
                <label class="form-item__label date-from__label">{{'label.StartDate' | translate}}</label>
                <div class="date-from__value">
                    <select class="form-item__value date-from__value-month" formControlName="dateFromMonth">
                        <option [ngValue]="null">{{'label.Month' | translate: { count: 1 } }}</option>
                        <option *ngFor="let month of monthList$ | async" [ngValue]="month.value">
                            {{month.name}}
                        </option>
                    </select>
                    <div style="width: 100%;">
                        <input class="form-item__value date-from__value-year" (input)="onChangeYear($event.target.value)" maxlength="4" type="text" formControlName="dateFromYear" name="dateFromYear" placeholder="{{'label.Year' | translate: { count: 1 } }}"/>
                        <mat-error *ngIf="editForm.controls['dateFromYear'].hasError('required') && (editForm.controls['dateFromYear'].touched || ngForm.submitted)" class="validation-message validation-message--from-year">{{'message.MandatoryField' | translate}}</mat-error>
                        <mat-error *ngIf="editForm.controls['dateFromYear'].hasError('pattern') && (editForm.controls['dateFromYear'].touched || ngForm.submitted)" class="validation-message validation-message--from-year">{{'message.IncorrectFormat' | translate}}</mat-error>
                    </div>
                </div>
            </div>
        
            <div class="form-item date-to">
                <label class="form-item__label date-to__label">{{'label.EndDate' | translate}}</label>
                <div class="date-to__value">
                    <select class="form-item__value date-to__value-month" formControlName="dateToMonth">
                        <option [ngValue]="null">{{'label.Month' | translate: { count: 1 } }}</option>
                        <option *ngFor="let month of monthList$ | async" [ngValue]="month.value">
                            {{month.name}}
                        </option>
                    </select>
                    <div style="width: 100%;">
                        <input class="form-item__value date-from__value-year" (input)="onChangeYear($event.target.value)" maxlength="4" type="text" formControlName="dateToYear" name="dateToYear" placeholder="{{'label.Year' | translate: { count: 1 } }}"/>
                        <mat-error *ngIf="editForm.controls['dateToYear'].hasError('required') && (editForm.controls['dateToYear'].touched || ngForm.submitted)" class="validation-message validation-message--to-year">{{'message.MandatoryField' | translate}}</mat-error>
                        <mat-error *ngIf="editForm.controls['dateToYear'].hasError('pattern') && (editForm.controls['dateToYear'].touched || ngForm.submitted)" class="validation-message validation-message--from-year">{{'message.IncorrectFormat' | translate}}</mat-error>
                    </div>
                </div>
                <mat-error *ngIf="editForm.hasError('year') && editForm.controls['dateToYear'].touched && !editForm.controls['dateToYear'].hasError('pattern') && !editForm.controls['dateToYear'].hasError('required')" class="validation-message validation-message--from-date">{{'message.StartDateMustNotComeAfterEndDate' | translate}}</mat-error>
            </div>
        
            <div class="form-item additional-info">
                <label class="form-item__label additional-info__label">{{'label.AdditionalInformation' | translate}}</label>
                <textarea class="form-item__value additional-info__value" type="text" formControlName="description" placeholder="{{'label.DescriptionEgEducation' | translate }}"></textarea>
            </div>
    
            <input type="file" style="display:none;" #hiddenfileinput (change)="onFileSelect($event, data.systemConfigs.jobseeker.jobseekerAttachmentMaxSize)"/>
            <div *ngIf="!data.hasAttachment" class="form-item attachment">
                <app-advanced-button (click)="hiddenfileinput.click()" [iconType]="iconButtonType.attachment" [text]="'label.UploadDocuments' | translate" [type]="'blue'" class="attachment__btn"></app-advanced-button>
    
                <button (click)="hiddenfileinput.click()" class="attachment__choose-btn" type="button">{{'label.Select' | translate}}</button>
                <div *ngIf="data.selectedFile?.filename != null" class="selected-file">
                    {{data.selectedFileText}}
                </div>
                <span class="attachment__choose-text">{{'label.AttachmentEgDiploma' | translate}}</span>
            </div>
            <div *ngIf="data.hasAttachment" class="form-item attachment">
                <app-advanced-button (click)="downloadAttachment(data.jobseekerEducation?.objectId)" [iconType]="iconButtonType.attachment" [text]="'label.Attachment' | translate" [type]="'blue'" class="attachment__btn attachment__btn--uploaded"></app-advanced-button>
                <button class="attachment__delete" type="button">{{'label.Delete' | translate}}</button>
                <button  (click)="hiddenfileinput.click()" class="attachment__replace" type="button">{{'label.Change' | translate}}</button>
                <div *ngIf="data.selectedFile?.filename != null" class="selected-file">
                    {{data.selectedFileText}}
                </div>
            </div>
            <mat-progress-bar *ngIf="data.isFileUploading" mode="indeterminate"></mat-progress-bar>
            <div *ngIf="!data.isFileSizeValid && data.isFileSizeValid != null" class="attachment-size-validation">
                <mat-error>
                    {{'message.JobseekerFileSizeValidation' | translate}}
                </mat-error>
            </div>

            <mat-dialog-actions>
                <div class="buttons">
                    <app-button [ngClass]="{'disabled': editForm.invalid}" type="submit" (click)="onSave(data.jobseekerEducation?.jobseekerEducationId, data.educationalInstitutionMap, data.programMap, data.selectedFile, data.isFileSizeValid, data.jobseekerEducation?.objectId, data.moderationLastChangeDate)" [text]="'label.Save' | translate" [type]="buttonType.coloured"  [typeAttribute]="buttonAttributeType.submit" class="button-save"></app-button>
                    <app-button [mat-dialog-close] ="true" [text]="'label.Cancel' | translate" [type]="buttonType.uncoloured"  [typeAttribute]="buttonAttributeType.button"></app-button>
                </div>
            </mat-dialog-actions>
        </form>
    </app-spinner>
</div>
