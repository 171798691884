import { BrowserModule, TransferState } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { reducers, metaReducers } from './reducers';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { LayoutMainComponent } from './layout-main/layout-main.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ApiResponse, DefaultRouteReuseStrategy, EnvServiceProvider, SmartHttpService, URL_CONFIG_TOKEN, UrlConfigService } from '@hrra/core';
import { RootStoreModule } from './root-store';
import { AnnouncementModule } from './announcement/announcement.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { UiModule } from '@hrra/ui';
import {
  TranslateModule,
  TranslateLoader,
  TranslateCompiler,
} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
// import { LayoutHeaderComponent } from './layout-header/layout-header.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CvBoxModule } from './cv-box/cv-box.module';
import { SharedModule } from './shared/shared.module';
import { LayoutFooterComponent } from './layout-footer/layout-footer.component';
import { CustomerModule } from './customer/customer.module';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { HrUiModule } from './hr-ui/hr-ui.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { HeaderModule } from './header/header.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { translateBrowserLoaderFactory } from '@hrra/core';
import { SvgBrowserLoader } from './core/svg-browser-loader';
import { InfoPagesModule } from './info-pages/info-pages.module';
import { LoggingModule } from '@hrra/core'; 
import { newUrlConfig,} from './core/url-config';
import { BannerModule } from './banner/banner.module';
import { JobseekerEmailVerificationComponent } from './jobseeker-email-verification/jobseeker-email-verification.component';
import { CookieModule } from 'ngx-cookie';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { LocationService } from 'libs/common-components/src/lib/shared/services/location.service';
import { JobseekerLocationService } from './shared/services/jobseeker-location.service';
import { RouteReuseStrategy } from '@angular/router';
import { AdminSigninComponent } from './admin-signin/admin-signin.component';


declare var window: any;


@NgModule({
  declarations: [
    AppComponent,

    LayoutMainComponent,
    //LayoutHeaderComponent,
    LayoutFooterComponent,
    JobseekerEmailVerificationComponent,
    AdminSigninComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    HttpClientModule,
    // AngularSvgIconModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    AngularSvgIconModule.forRoot({
      loader: {
        provide: SvgLoader,
        useFactory: svgLoaderFactory,
        deps: [HttpClient, TransferState],
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    // StoreModule.forRoot(reducers; {
    //   metaReducers
    // }),
    // EffectsModule.forRoot([]),
    AnnouncementModule,
    CvBoxModule,
    //JobseekerModule,
    CustomerModule,
    //SubscriberModule,
    SharedModule,
    InfoPagesModule,
    BannerModule,

    AppRoutingModule,


    HrUiModule,
    UiModule,
    MatSnackBarModule,
    RootStoreModule,
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    BrowserAnimationsModule,
    MatDialogModule,
    MatAutocompleteModule,
    HeaderModule,
    LoggingModule.forBrowser(),
    CookieModule.forRoot(),
    InfiniteScrollModule
  ],
  providers: [EnvServiceProvider,
    {
      provide: URL_CONFIG_TOKEN,
      useValue: newUrlConfig
    },
    {
      provide: 'googleTagManagerId', useFactory: ()=> {
        return window.__env.gtmId;
      }
    },
    {
      provide: LocationService,
      useClass: JobseekerLocationService
    },
    {
      provide: RouteReuseStrategy,
      useClass: DefaultRouteReuseStrategy
    }
    // {
    //   provide: EXTERNAL_LOGGER,
    //   useValue: null
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function svgLoaderFactory(http: HttpClient, transferState: TransferState) {
  return new SvgBrowserLoader(transferState, http);
}
