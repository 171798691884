import { Component, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationPickerStore } from '../shared/store/location-picker-store';
import { Observable, Subscription, of } from 'rxjs';
import { LocationListViewModel, LocationTypeEnum } from '@hrra/common-models';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AbstractControl, AsyncValidator, ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { error } from 'console';

@Component({
  selector: 'hrra-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
  providers: [
    LocationPickerStore,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LocationPickerComponent
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      multi: true,
      useExisting: LocationPickerComponent
    },
  ]
})
export class LocationPickerComponent implements OnInit, ControlValueAccessor, AsyncValidator {

  //------------------------------------------------
  onChange = (value) => {};
  onTouched = () => {};
  
  touched = false;
  disabled = false;
  //------------------------------------------------

  locationForm: FormGroup;
  requiredLevelCountList: {[key: number]: boolean};
  locationNameMap: { [index: number]: string };

  @Input() locationLevelCount: number;
  @Input() firstLevelParentLocationId: string;
  @Input() lastHiddenLevelCount: number;
  @Input() requiredLevelCount: number;
  @Input() locationTypes: number[];

  levels$: Observable<{level: number, parentId: string }[]>;
  locationsMap$: Observable<{[key: string]: LocationListViewModel[]}>;
  selectedLocations$: Observable<{[parenLocationId: string]: string}>;
  firstDisabledLocationIndex$: Observable<number>;
  selectedLocationsCount$: Observable<number>;
  abroadLocationIndex$: Observable<number>;

  isWildCardSelected$: Observable<{loading: boolean, wildcardSelected: boolean }>;

  onFilter(event: any, parentId: string){
    this.store.updateFilter({ query: event.target.value,  parentId: parentId });
  }

  constructor(private store: LocationPickerStore, private fb: FormBuilder){
    this.locationTypes = [LocationTypeEnum.City, LocationTypeEnum.District, LocationTypeEnum.Street];
    this.locationNameMap = {
      [LocationTypeEnum.City]: 'label.LocationCityLabel',
      [LocationTypeEnum.Country]: 'label.LocationCountryLabel',
      [LocationTypeEnum.District]: 'label.LocationDistrictLabel',
      [LocationTypeEnum.State]: 'label.LocationStateLabel',
      [LocationTypeEnum.Street]: 'label.LocationStreetLabel'
    };
  }

  customValidator(group : FormGroup) : {[index: number ]: {valid: boolean, error: string}} {
    if (group){
      let locations = group.get('locations') as FormArray;
      
      let result:  {[index: number ]: {valid: boolean, error: string}} = {};
      for (let i = 0; i < locations.controls.length; i++){
        if(locations.controls[i].value.locationId == '' &&  i < this.requiredLevelCount){
          result[i] = {valid: false, error: "required"};
        }
      }


      if(Object.keys(result).length != 0){
        return result;
      }
    }
    return null;
  }

  customValidatorAsync(group : AbstractControl) : Observable<{[index: number ]: {valid: boolean, error: string}} | null> {

    return this.store.wildCardSelected$.pipe(
      filter(c => !c.loading),
      map(c => {
      if(c.wildcardSelected)
          return null;


      if (group){

        if(group.value == null)
          return null;

        const locations = group.value.locations;
                
        const result:  {[index: number ]: {valid: boolean, error: string}} = {};

        for (let i = 0; i < this.requiredLevelCount; i++){
            if((locations[i]?.locationId == '' || locations[i]?.locationId == undefined )){
            result[i] = {valid: false, error: "required"};
          }
        }
  
        if(Object.keys(result).length != 0){
          return result;
        }
      }
      return null;
    }),
    take(1)
    );
  }

  ngOnInit(): void {
    this.store.setLocationLevelCount(this.locationLevelCount);
    this.store.loadLocations([this.firstLevelParentLocationId]);
    this.locationsMap$ = this.store.locationsMap$;
    this.levels$ = this.store.levels$;

    this.selectedLocations$ = this.store.selectedLocations$;
    this.firstDisabledLocationIndex$ = this.store.selectFirstDisabledLocationIndex$;

    this.isWildCardSelected$ = this.store.wildCardSelected$;
    this.selectedLocationsCount$ = this.store.selectedLocationsCount$;

    this.abroadLocationIndex$ = this.store.selectAbroadLocationIndex$;

    //----------
    this.locationForm = this.fb.group({
      locations: this.fb.array([])    
    }, {
      asyncValidators: [
        (group: FormGroup) => this.customValidatorAsync(group)
      ]
    });

    let locations = this.locationForm.get('locations') as FormArray;
    for (let i = 0; i < this.locationLevelCount; i++) {
      const location = this.fb.group({
        locationId: new FormControl(''),
        isHidden:  new FormControl(false)
      })
      locations.push(location);
    }

    let levelCountList: {[key: number]: boolean} = {};
    for (let i = 0; i < this.locationLevelCount; i++)
    {
      levelCountList[i] = i < this.requiredLevelCount;
    }
    this.requiredLevelCountList = levelCountList; //RequredLevelMap
    //----------------

    this.store.selectFirstDisabledLocationIndex$.subscribe(e => {
      var arrayControl = this.locationForm.get('locations') as FormArray;
      
      for (let i = 0; i < arrayControl.controls.length; i++){
        let isHidden = arrayControl.controls[i].get('isHidden');
        if(i >= e){
          arrayControl.controls[i].disable();
          isHidden?.disable();
        }
        else{
          arrayControl.controls[i].enable();
          isHidden?.enable();
        }
      }
    })
  }


  //--------------------------------------------
  writeValue(value: any) {
    if(value == null){
      this.locationForm.reset();
    }

    let parentIds: string[] = [];
    let locationIds: string[] = value?.locations.map(e => e.locationId);

    parentIds.push(this.firstLevelParentLocationId);

    if(value?.locations.length > 0){
      for(let i = 0; i < value.locations.length; i++){
        parentIds.push(value.locations[i].locationId)
      }
    }
    this.store.setParentLocationIds({parentIds: parentIds, locationIds: locationIds});

    this.store.loadLocations(parentIds);

    //to call setValue after loading the locations
    if(value?.locations.length > 0){
      this.store.loading$.pipe(
        filter(c => !c),
        take(1)
      ).subscribe(e => {
        setTimeout(() => {
          this.locationForm.patchValue(value, {emitEvent: true});
        }, 0);
      })
    }
  }
  
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }
  
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl) {
    return this.customValidatorAsync(<any>control);

  }
  
  //-----------------------------------------------


  displayFn(locations: LocationListViewModel[], locationId: string) {
    let result = locations?.find(e => e.locationId == locationId)?.name;
    return result;
  }

  onChangeCheckboxValue(event){
    this.onChange(this.locationForm.value);
  }
  
  onChangeValue(event, parentId: string, index){
    this.onTouched();

    let locations = this.locationForm.get('locations') as FormArray; 
    for (let i = 0; i < locations.controls.length; i++){
      if(i > index){
        let location = locations.controls[i];
        location.get('isHidden').setValue(false, {emitEvent: false});
        location.get('locationId').setValue(undefined, {emitEvent: false});
      }
    }
  
    let locationId = event;
    this.store.onChangeValue({locationId: locationId, parentLocationId: parentId});

    this.onChange(this.locationForm.value);
  }

  checkLocation(index, locations, parentId, selectedLocation) {
    let locationsForm = this.locationForm.get('locations') as FormArray; 
    let location = locationsForm.controls[index] as FormGroup;
    let locationId = location.controls['locationId'];
    let locationIdValue = locationId.value
    
    let locationByName = locations?.find(e => e.name == locationIdValue);

    if(locationByName){
      locationId?.setValue(locationByName.locationId);
      this.onChangeValue(locationByName.locationId, parentId, index)
    }
    else if(locationIdValue == '' && selectedLocation != null){
      locationId?.setValue(undefined, {emitEvent: false});
      this.onChangeValue(undefined, parentId, index);
    }
    else{
      locationId?.setValue(selectedLocation, {emitEvent: false});
    }
  }
}
