import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { UserTypeEnum } from '@hrra/common-models';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard{
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      if(this.authService.isSignedIn()){
        return true;
      }
      else {
        return this.router.createUrlTree(['/jobseeker/sign-in'], { queryParams: { returnUrl: state.url }});
      }
  }
  
  constructor(private authService: AuthService, private router: Router){

  }
}

export function configureGuard(userType: UserTypeEnum): CanActivateFn {
  return (next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  => {
      const authService = inject(AuthService);
      const router = inject(Router);
      if(authService.isSignedInAdvanced(userType)){
        return true;
      }
      else {
        return router.createUrlTree(['/jobseeker/sign-in'], { queryParams: { returnUrl: state.url }});
      }
  }
};