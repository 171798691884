<div class="container">
    <div class="texts">
        <div class="texts__title">{{title}}</div>
        <div class="texts__right">{{rightText}}</div>
    </div>

    <div [ngClass]="{'out-of-tile-content':hasOutOfTileContent}" class="content">
        <div class="content-main">
            <ng-content select="[tile-content]"></ng-content>
            <div class="buttons">
                <ng-content select="[button]" class="content-main__button"></ng-content>
                <ng-content select="[add-button]" class="content-main__add-button"></ng-content>
            </div>
        </div>
        <div class="content-out-of-tile">
            <ng-content select="[out-of-tile-content]"></ng-content>
        </div>
    </div>
</div>