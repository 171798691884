import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { JobseekerService } from 'src/app/jobseeker/shared/services/jobseeker.service';
import * as JobseekerProfileActions from './actions';
import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FileDownloadService } from '@hrra/core';

@Injectable()
export class JobseekerProfileEffects{

    loadJobseekerProfile$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.loadJobseekerProfile),
        mergeMap(() => this.jobseekerSerivce.getJobseeker()
        .pipe(
            map(response => JobseekerProfileActions.loadJobseekerProfileSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.loadJobseekerProfileFail({payload: response.error})))
        ))
    ));


    //delete
    deleteJobseekerDocument$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.deleteJobseekerDocument),
        mergeMap(a => this.jobseekerSerivce.deleteJobseekerDocument(a.payload.documentId, a.payload.moderationLastChangeDate, a.payload.objectId)
        .pipe(
            map(response => JobseekerProfileActions.deleteJobseekerDocumentSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.deleteJobseekerDocumentFail({payload: response.error})))
        ))
    ));


    deleteJobseekerLanguage$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.deleteJobseekerLanguage),
        mergeMap(a => this.jobseekerSerivce.deleteJobseekerLanguage(a.payload.languageId, a.payload.moderationLastChangeDate, a.payload.objectId)
        .pipe(
            map(response => JobseekerProfileActions.deleteJobseekerLanguageSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.deleteJobseekerLanguageFail({payload: response.error})))
        ))
    ));


    deleteJobseekerEducation$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.deleteJobseekerEducation),
        mergeMap(a => this.jobseekerSerivce.deleteJobseekerEducation(a.payload.educationId, a.payload.moderationLastChangeDate, a.payload.objectId)
        .pipe(
            map(response => JobseekerProfileActions.deleteJobseekerEducationSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.deleteJobseekerEducationFail({payload: response.error})))
        ))
    ));


    deleteJobseekerWorkExperience$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.deleteJobseekerWorkExperience),
        mergeMap(a => this.jobseekerSerivce.deleteJobseekerWorkExperience(a.payload.experienceId, a.payload.objectId, a.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileActions.deleteJobseekerWorkExperienceSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.deleteJobseekerWorkExperienceFail({payload: response.error})))
        ))
    ));

    deleteJobseekerPhoto$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.deleteJobseekerPhoto),
        mergeMap((a) => this.jobseekerSerivce.deleteJobseekerPhoto(a.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileActions.deleteJobseekerPhotoSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.deleteJobseekerPhotoFail({payload: response.error})))
        ))
    ));


    //downloads
    downloadEducationAttachment$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.downloadEducationAttachment),
        mergeMap(action => this.jobseekerSerivce.downloadEducationAttachment(action.payload.jobseekerEducationObjectId)
        .pipe(
            map(response => response.data),
            tap(data => this.fileDownloadService.downloadFile(data.downloadUrl, data.friendlyName)),
            map(() => JobseekerProfileActions.downloadEducationAttachmentSuccess()),
            catchError(response => of(JobseekerProfileActions.downloadEducationAttachmentFail({payload: response.error})))
        ))
    ));

    downloadLanguageAttachment$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.downloadLanguageAttachment),
        mergeMap(action => this.jobseekerSerivce.downloadLanguageAttachment(action.payload.jobseekerLanguageObjectId)
        .pipe(
            map(response => response.data),
            tap(data => this.fileDownloadService.downloadFile(data.downloadUrl, data.friendlyName)),
            map(() => JobseekerProfileActions.downloadLanguageAttachmentSuccess()),
            catchError(response => of(JobseekerProfileActions.downloadLanguageAttachmentFail({payload: response.error})))
        ))
    ));

    downloadDocumentAttachment$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.downloadDocumentAttachment),
        mergeMap(action => this.jobseekerSerivce.downloadDocumentAttachment(action.payload.documentObjectId)
        .pipe(
            map(response => response.data),
            tap(data => this.fileDownloadService.downloadFile(data.downloadUrl, data.friendlyName)),
            map(() => JobseekerProfileActions.downloadDocumentAttachmentSuccess()),
            catchError(response => of(JobseekerProfileActions.downloadDocumentAttachmentFail({payload: response.error})))
        ))
    ));

    downloadWorkExperienceAttachment$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.downloadWorkExperienceAttachment),
        mergeMap(action => this.jobseekerSerivce.downloadExperienceAttachment(action.payload.workExperienceObjectId)
        .pipe(
            map(response => response.data),
            tap(data => this.fileDownloadService.downloadFile(data.downloadUrl, data.friendlyName)),
            map(() => JobseekerProfileActions.downloadWorkExperienceAttachmentSuccess()),
            catchError(response => of(JobseekerProfileActions.downloadWorkExpeienceAttachmentFail({payload: response.error})))
        ))
    ));

    
    //edit/add
    editJobseekerPersonalInfo$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.editJobseekerPersonalInfo),
        mergeMap(action => this.jobseekerSerivce.editJobseekerPersonalInfo(action.payload.jobseekerPersonalInfoModel, action.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileActions.editJobseekerPersonalInfoSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.editJobseekerPersonalInfoFail({payload: response.error})))
        ))
    ));

    editJobseekerPersonalInfoSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.editJobseekerPersonalInfoSuccess),
        tap(() => this.dialog.getDialogById('personalInfo').close())
          ),
        { dispatch: false }
      );

    editJobseekerEducaion$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.editJobseekerEducation),
        mergeMap(action => this.jobseekerSerivce.editJobseekerEducation(action.payload.jobseekerEducationalModel, action.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileActions.editJobseekerEducationSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.editJobseekerEducationFail({payload: response.error})))
        ))
    ));

    editJobseekerEducationSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.editJobseekerEducationSuccess),
        tap(() => this.dialog.getDialogById('education').close())
          ),
        { dispatch: false }
      );

    editJobseekerLanguage$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.editJobseekerLanguage),
        mergeMap(action => this.jobseekerSerivce.editJobseekerLanguage(action.payload.jobseekerLanguageModel, action.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileActions.editJobseekerLanguageSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.editJobseekerLanguageFail({payload: response.error})))
        ))
    ));

    editJobseekerLanguageSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.editJobseekerLanguageSuccess),
        tap(() => this.dialog.getDialogById('language').close())
          ),
        { dispatch: false }
      );

    uploadJobseekerDocument$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.uploadJobseekerDocument),
        mergeMap(action => this.jobseekerSerivce.uploadJobseekerDocument(action.payload.jobseekerDocumentModel, action.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileActions.uploadJobseekerDocumentSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.uploadJobseekerDocumentFail({payload: response.error})))
        ))
    ));

    uploadJobseekerDocumentSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.uploadJobseekerDocumentSuccess),
        tap(() => this.dialog.getDialogById('document').close())
          ),
        { dispatch: false }
      );

    editJobseekerWorkExperience$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.editJobseekerWorkExperience),
        mergeMap(action => this.jobseekerSerivce.editJobseekerWorkExperience(action.payload.jobseekerWorkExperienceModel, action.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileActions.editJobseekerWorkExperienceSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.editJobseekerWorkExperienceFail({payload: response.error})))
        ))
    ));

    setIsWorkExperienceFilled$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.setIsWorkExperienceFilled),
        mergeMap(action => this.jobseekerSerivce.setIsWorkExperienceFilled(action.payload.isWorkExperienceFilled, action.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileActions.setIsWorkExperienceFilledSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.setIsWorkExperienceFilledFail({payload: response.error})))
        ))
    ));

    editJobseekerWorkExperienceSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.editJobseekerWorkExperienceSuccess),
        tap(() => this.dialog.getDialogById('workExperience').close())
          ),
        { dispatch: false }
      );

    //file upload
    uploadTempFile$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.uploadTempFile),
        mergeMap(action => this.jobseekerSerivce.uploadTempFile(action.payload)
        .pipe(
            map(response => JobseekerProfileActions.uploadTempFileSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.editJobseekerEducationFail({payload: response.error})))
        ))
    ));

    //edit photo
    editJobseekePhoto$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.editJobseekerPhoto),
        mergeMap(action => this.jobseekerSerivce.editJobseekerPhoto(action.payload.jobseekerPhotoModel, action.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileActions.editJobseekerPhotoSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileActions.editJobseekerPhotoFail({payload: response.error})))
        ))
    ));

    editJobseekerPhotoSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.editJobseekerPhotoSuccess),
        tap(() => this.dialog.getDialogById('photo').close())
          ),
        { dispatch: false }
      );


    //CV export
    jobseekerCvExport$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileActions.jobseekerCvExport),
        mergeMap(() => this.jobseekerSerivce.cvExport()
        .pipe(
            map(response => response.data),
            tap(data => this.fileDownloadService.downloadFile(data.downloadUrl, data.friendlyName)),
            map(response => JobseekerProfileActions.jobseekerCvExportSuccess()),
            catchError(response => of(JobseekerProfileActions.jobseekerCvExportFailed({payload: response.error})))
        ))
    ));

    constructor(private actions$: Actions, private jobseekerSerivce: JobseekerService, private fileDownloadService: FileDownloadService, private dialog: MatDialog){}
}