import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderPublicComponent } from './header-public/header-public.component';
import { HeaderJobseekerComponent } from './header-jobseeker/header-jobseeker.component';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { HeaderAdminComponent } from './header-admin/header-admin.component';



@NgModule({
  declarations: [HeaderPublicComponent, HeaderJobseekerComponent, HeaderComponent, HeaderAdminComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports: [HeaderPublicComponent, HeaderJobseekerComponent, HeaderComponent, HeaderAdminComponent],
})
export class HeaderModule { }
