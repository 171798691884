import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { AppState } from 'src/app/root-store/state';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { selectSearchState, selectPageCount, selectDisplayPages, selectMatchedAnnouncementCount, selectIsExpanded, selectAnnouncements, selectPreviewAnnouncements, selectIsSearchResultLoading, selectSearchResultMetaData, selectSearchPageListtBanner, selectSearchPageLeftBanner, selectSearchPageTopBanner, selectFilterExpanded, selectNextSearchPageQueryParams, selectPreviuseSearchPageQueryParams, selectSearchPageBannerIds } from 'src/app/root-store/announcement-store/search-page/selectors';
import { AnnouncementFilter } from 'src/app/shared/models/announcement-filter';
import { AnnouncementService } from 'src/app/shared/services/announcement.service';
import { Observable, Subscription, combineLatest, filter, fromEvent, take } from 'rxjs';
import { selectSalaryOptions, selectPublishDateOptions, ProviderWebsiteUrlMap, selectCurrentWebsite, selectCurrentUserType } from 'src/app/root-store/selectors';
import { SearchState } from 'src/app/root-store/announcement-store/search-page/state';
import { loadFrontPageAnnouncements } from 'src/app/root-store/announcement-store/announcement-front-page-actions';
import { PageNameEnum } from 'src/app/shared/models/enums/page-names-enum';
import { Announcement } from '../shared/models/announcement-model';
import { loadLastListingPage, sendBannerViewToAnalytic } from 'src/app/root-store/actions';
import { MetaService } from 'src/app/shared/services/meta.service';
import { Banner } from 'src/app/banner/shared/models/banner-model';
import { BannerTargetEnum } from 'src/app/banner/shared/models/enums/banner-target-enum';
import { BannerPositionEnum } from 'src/app/banner/shared/models/enums/banner-position-enum';
import { getSearchPageBannerList, toggleExpandFilter } from 'src/app/root-store/announcement-store/search-page/actions';
import { Breakpoints } from 'src/app/shared/models/breakpoints-model';
import { SsrService } from 'src/app/core/ssr.service';
import { DOCUMENT } from '@angular/common';
import { PagingNavItem, RoutablePagingNavItem } from '@hrra/ui';
import { OptionItem } from '@hrra/common-models';
import { Range } from '@hrra/core';
import { BannerHeaderPositionId } from 'src/app/shared/models/enums/banner-header-position-id-enum';

@Component({
  selector: 'app-ann-search',
  templateUrl: './ann-search.component.html',
  styleUrls: ['./ann-search.component.scss']
})
export class AnnSearchComponent implements OnInit {

  @ViewChild('searchFilter')
  searchFilter!: any;

  metaDataSubscription: Subscription;

  pageId: any;

  date = new Date();

  targetId: number = BannerTargetEnum.Desktop;
  isDesktop: boolean = false;

  constructor(@Inject(DOCUMENT) private _document,
              private store: Store<AppState>, 
              private router: Router, 
              private announcementService: AnnouncementService, 
              private metaService: MetaService,
              private ssr: SsrService) {
                this.pageName = PageNameEnum;
                this.targetId = window.innerWidth > Breakpoints.huge ? BannerTargetEnum.Desktop : BannerTargetEnum.Responsive;
                this.pageId = BannerHeaderPositionId.SearchResultPage
              }

  public search$: Observable<SearchState>;
  
  public isFilterExpanded$: Observable<boolean>;

  public salaryOptions$: Observable<OptionItem<Range<number>>[]>;
  public publishDateOptions$: Observable<OptionItem<Range<number>>[]>;

  public pageCount$: Observable<number>;
  public navItems$: Observable<RoutablePagingNavItem[]>;
  public previousePageQueryParams$: Observable<any>;
  public nextPageQueryParams$: Observable<any>;


  public pageName: any;
  announcements$: Observable<Announcement[]>;
  previewAnnouncements$: Observable<Announcement[]>;
  matchedAnnouncementCount$: Observable<number>;
  filterIsExpanded$: Observable<boolean>;
  providerWebsiteUrlMap$: Observable<{ [index: number]: string }>;

  topBanner$: Observable<Banner>;
  listBanner$: Observable<Banner>;
  leftBanner$: Observable<Banner>;

  isLoading$: Observable<boolean>;

  userType$: Observable<any>;

  sidebarTop: string = 'relative';
  sidebarPosition: string = 'relative';
  sidebarBottom: string = 'relative';

  ngOnInit(): void {
    this.isDesktop = window.innerWidth > Breakpoints.huge;

    let searchPageBanners$ = this.store.select(selectSearchPageBannerIds);
    let providerWebsiteId$ = this.store.select(selectCurrentWebsite);

    combineLatest([searchPageBanners$, providerWebsiteId$])
    .pipe(
      filter(([banners, provderWebsite]) =>{
        return banners.bannerIdList.length > 0 && provderWebsite !== null && provderWebsite !== undefined;
      }),
      take(1)
    )
    .subscribe(
      ([b, p]) => {
        this.store.dispatch(sendBannerViewToAnalytic({payload:{bannerIdList: b, providerWebsiteId:p.providerWebsiteId, pageId: BannerHeaderPositionId.FrontPage}}))
      }
    )

    this.store.dispatch(loadLastListingPage({payload: PageNameEnum.SearchResultsPage}));
    if(this.ssr.isPlatformBrowser()){
      this.store.dispatch(getSearchPageBannerList({payload: {targetId: this.targetId, bannerHeadId: BannerPositionEnum.Search_results}}));
    }

    this.salaryOptions$ = this.store.pipe(select(selectSalaryOptions({ emptyItemName: 'All'})));
    this.publishDateOptions$ = this.store.pipe(select(selectPublishDateOptions));
    this.search$ = this.store.pipe(select(selectSearchState));
    this.pageCount$ = this.store.pipe(select(selectPageCount));
    this.navItems$ = this.store.pipe(select(selectDisplayPages));
    this.nextPageQueryParams$ = this.store.pipe(select(selectNextSearchPageQueryParams));
    this.previousePageQueryParams$ = this.store.pipe(select(selectPreviuseSearchPageQueryParams))


    this.previewAnnouncements$ = this.store.pipe(select(selectPreviewAnnouncements));
    this.announcements$ = this.store.pipe(select(selectAnnouncements));
    this.matchedAnnouncementCount$ = this.store.pipe(select(selectMatchedAnnouncementCount));
    this.filterIsExpanded$ = this.store.pipe(select(selectIsExpanded));
    this.providerWebsiteUrlMap$ = this.store.pipe(select(ProviderWebsiteUrlMap));

    this.topBanner$ = this.store.pipe(select(selectSearchPageTopBanner));
    this.listBanner$ = this.store.pipe(select(selectSearchPageListtBanner));
    this.leftBanner$ = this.store.pipe(select(selectSearchPageLeftBanner));

    //this.store.dispatch(loadFrontPageAnnouncements());
    
    this.isLoading$ = this.store.pipe(select(selectIsSearchResultLoading));

    this.userType$ = this.store.select(selectCurrentUserType);
 
    this.metaDataSubscription = this.store.pipe(select(selectSearchResultMetaData)).subscribe(
      (data) => {
        this.metaService.updateMetaData(data.website, data.metaData);
      }
    )
  }

  ngOnDestroy() {
    this.metaDataSubscription.unsubscribe();
  }

  lastScrollTop = 0;
  isFirstScrollUp: boolean;

  @HostListener("window:scroll", ["$event"])
  onScroll(){
    this.searchFilter.tree.closeDropdown();//TODO sometimes throws excpetion when tree in sub compmennt is not yet initialized (because of ngIf on categroyTree.length>0,  instead of ngIf it's better to handle logic inside that component so it's rnedered intially)

    this.searchFilter.locationTree.closeDropdown();
    
    this.searchFilter.publishDateDropdown.hide();
    this.searchFilter.salaryDropdown.dropdown.hide();

    this.onScrollAndResize();
  }

  //@HostListener("window:scroll", ["$event"])
  @HostListener('window:resize', ['$event'])

  onScrollAndResize() {
    /*
    this.searchFilter.tree.closeDropdown();
    this.searchFilter.publishDateDropdown.hide();
    this.searchFilter.salaryDropdown.dropdown.hide();
    */
  
    let sideBar = this._document.getElementById("sticky");
    let content = this._document.getElementById("ann-list");

    if(window.innerWidth > Breakpoints.huge && content != null){
      let sidebarTop = Math.round(parseFloat(getComputedStyle(this._document.getElementsByTagName('header')[0]).paddingTop)
      +parseFloat(getComputedStyle(this._document.getElementsByTagName('header')[0]).paddingBottom) 
      + parseFloat(getComputedStyle(this._document.getElementsByTagName('header')[0]).height) 
      + parseFloat(getComputedStyle(this._document.getElementById('ann-info')).height));
  
      let sidebarBottom = 425;
  
      let st = window.pageYOffset || document.documentElement.scrollTop;
   
      const scrollToClustersTop = sideBar.getBoundingClientRect().top <= sidebarTop;
      const clustersHeightAndWindowDiff = sideBar.offsetHeight > window.innerHeight ? 0 : window.innerHeight - sideBar.offsetHeight;
      const scrollToSerpBottom = content.getBoundingClientRect().top + content.offsetHeight + clustersHeightAndWindowDiff < window.innerHeight;
      const scrollToSerpTop = content.getBoundingClientRect().top <= sidebarTop;
      const scrollToClustersBottom = sideBar.getBoundingClientRect().top + sideBar.offsetHeight < window.innerHeight;
      const clustersHeightLessThenWindow = sideBar.offsetHeight < window.innerHeight;
      const isMovingDown = st > this.lastScrollTop;
  
      if (sideBar.offsetHeight > content.offsetHeight) {
        this.setPosition({ position: 'static', top: undefined, bottom: undefined });
        return;
      }
  
      if(content.getBoundingClientRect().bottom < window.innerHeight){
        this.setPosition({ position: 'absolute', top: undefined, bottom: sidebarBottom });
        return;
      }
  
      if (isMovingDown){
        if (sideBar.offsetHeight < window.innerHeight) {
          if (scrollToSerpBottom) {
            this.setPosition({ position: 'absolute', top: undefined, bottom: sidebarBottom })     
            return;
          }
  
          if (scrollToSerpTop) {
            this.setPosition({ position: 'fixed', top: sidebarTop, bottom: undefined });
              return;
          }
        }
        else {
          if (this.sidebarPosition === 'absolute' && this.sidebarTop !== 'auto') {
              if (scrollToClustersBottom) {
                this.setPosition({ position: 'fixed', top: undefined, bottom: 0 });
                return;
              }
              return;
            }
  
          if (this.sidebarPosition === 'fixed' && Math.round(parseFloat(sideBar.getBoundingClientRect().top)) === sidebarTop) {
            this.setPosition({ position: 'absolute', top: sideBar.getBoundingClientRect().top - content.getBoundingClientRect().top, bottom: undefined });      
          }
  
          if (scrollToClustersTop) {
              if (clustersHeightLessThenWindow) {
                this.setPosition({ position: 'fixed', top: sidebarTop, bottom: undefined });           
              }
          }
  
          if (scrollToClustersBottom) {       
            this.setPosition({ position: 'fixed', top: undefined, bottom: 0 });
          }
  
          if (scrollToSerpBottom) {
            this.setPosition({ position: 'absolute', top: undefined, bottom: 425 });      
          }
        }
      }
      else {
        if (this.sidebarPosition === 'fixed' && parseInt(this.sidebarBottom, 10) === 0) {
          this.setPosition({ position: 'absolute', top: sideBar.getBoundingClientRect().top - content.getBoundingClientRect().top, bottom: undefined });     
        }
  
        if (sideBar.getBoundingClientRect().top > sidebarTop && !scrollToClustersTop) {     
          this.setPosition({ position: 'fixed', top: sidebarTop, bottom: undefined });     
        }
  
        if (content.getBoundingClientRect().top >= sidebarTop) {
          this.setPosition({ position: 'static', top: undefined, bottom: undefined });     
  
        }
      }
      this.lastScrollTop = st <= 0 ? 0 : st;
      }
    }

    setPosition({position, bottom, top}){
      this.sidebarPosition = position;
      this.sidebarBottom = bottom !== undefined ? `${bottom}px` : 'auto';
      this.sidebarTop = top !== undefined ? `${top}px` : 'auto';
    }


    clickOnSideBar() {
      if(window.innerWidth > Breakpoints.huge){
        this.onResize();
      }
    }


    onResize(){
      let sidebarTop = Math.round(parseFloat(getComputedStyle(this._document.getElementsByTagName('header')[0]).paddingTop)
      +parseFloat(getComputedStyle(this._document.getElementsByTagName('header')[0]).paddingBottom) 
      + parseFloat(getComputedStyle(this._document.getElementsByTagName('header')[0]).height) 
      + parseFloat(getComputedStyle(this._document.getElementById('ann-info')).height));
      let sidebarBottom = 425;
      
      let sideBar = this._document.getElementById("sticky");
      let content = this._document.getElementById("ann-list");
      
      if(content.offsetHeight < sideBar.offsetHeight){
        return;
      }
      if(content.getBoundingClientRect().bottom < window.innerHeight){
        this.setPosition({ position: 'absolute', top: undefined, bottom: sidebarBottom });
        return;
      }

      if (
        sideBar.getBoundingClientRect().top + sideBar.offsetHeight < window.innerHeight &&
        window.innerHeight < content.offsetHeight &&
        content.offsetHeight > sideBar.offsetHeight &&
        content.getBoundingClientRect().top <= sidebarTop
        ){
          if (content.getBoundingClientRect().top + content.offsetHeight < window.innerHeight) {
            if (
                sideBar.offsetHeight < window.innerHeight &&
                content.getBoundingClientRect().top + content.offsetHeight > window.innerHeight
            ) {
                this.setPosition({ position: 'fixed', top: sidebarTop, bottom: undefined });
            } else {
                this.setPosition({ position: 'absolute', top: undefined, bottom: 0 });
            }
          } 
      else if(
        sideBar.offsetHeight < window.innerHeight &&
        content.offsetParent.offsetTop < window.scrollY + window.innerHeight
        ){
        this.setPosition({ position: 'fixed', top: sidebarTop, bottom: undefined });
       } else {
        this.setPosition({ position: 'fixed', top: undefined, bottom: 0 });
       }
      }
    }

    onResizeWindowWidth(filterIsExpanded) {

      if(this.isDesktop && window.innerWidth < Breakpoints.huge){
        this.isDesktop = !this.isDesktop;

        this.setPosition({ position: 'relative', top: undefined, bottom: undefined });
      }
      else if(!this.isDesktop && window.innerWidth > Breakpoints.huge){
        this.setPosition({ position: 'relative', top: undefined, bottom: undefined });

        this.isDesktop = !this.isDesktop;

        if(filterIsExpanded){
          this.store.dispatch(toggleExpandFilter());
        }
      }
    }
  }
  

// enum AnnouncementFilterQueryParams{
//   AnnouncementType='t',
//   Categories='c',
//   Experience='e',
//   Locality='l',
//   PublishDate='p',
//   Query='q',
//   Salary='s'
// }