import { createAction, createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../state';
import { JobseekerStoreState } from '../state';
import { selectCategoriesMap, selectCurrentDate, selectLocalitiesMap } from '../../selectors';
import { EducationalInstitution, EducationalProgram, EducationLevelEnum, EntityAttributeEnum, JobseekerDocument, JobseekerEducation, JobseekerEducationViewModel, JobseekerLanguage, JobseekerModerationAttributeEnum, JobseekerWorkExperience, JobseekerWorkExperienceViewModel } from '@hrra/common-models';
import { JobseekerPersonalInfoModel, JobseekerEducationModel, JobseekerLanguageModel } from 'src/app/jobseeker/shared/services/jobseeker.service';
import { BasicOptionItem } from '@hrra/common-models';
import { editJobseekerEducation } from './actions';

export const selectJobseekerStoreState = createFeatureSelector<JobseekerStoreState>('jobseeker');

export const selectJobseeker = createSelector(
    selectJobseekerStoreState,
    j => j.jobseekerProfile.jobseeker
);

export const selectJobseekerLookup = createSelector(
    selectJobseekerStoreState,
    s => s.jobseekerLookup.lookup
);

export const selectGenderList = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerLookup.lookup?.genderList
);

export const selectGenderMap = createSelector(
    selectJobseekerLookup,
    e => {
        let map: {[index: number]: BasicOptionItem} ={};
        e?.genderList.reduce((a, c) => {
            a[c.key] = c
            return a;
        }, map)

        return map;
    }
);

export const selectDrivingLicenseList = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerLookup.lookup?.drivingLicenceList
);

export const selectMonthList = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerLookup.lookup?.monthListFull
);

export const selectYearListBirth = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerLookup.lookup?.yearListBirth
);

export const selectEducationLevels = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerLookup.lookup?.educationLevels
);

export const selectEducationalInstitutions = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerLookup.lookup?.educationalInstitutionsList
);

export const selectEducationalInstitutionMap = createSelector(
    selectEducationalInstitutions,
    e => {
        let map: {[index: number]: EducationalInstitution} ={};
        e?.reduce((a, c) => {
            a[c.educationalInstitutionId] = c
            return a;
        }, map)

        return map;
    }
);

export const selectDocumentTypeList = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerLookup.lookup?.documentTypeList
);

export const selectDocumentTypeListMap = createSelector(
    selectJobseekerLookup,
    e => {
        let map: {[index: number]: BasicOptionItem} ={};
        e?.documentTypeList.reduce((a, c) => {
            a[c.key] = c
            return a;
        }, map)

        return map;
    }
);

export const selectLanguageProficiencyLevels = createSelector(
    selectJobseekerLookup,
    e => e.languageProficiencyLevels
);

//#region personal info
export const selectModState = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.jobseeker?.modState
);

export const nameIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.Name) || m?.includes(JobseekerModerationAttributeEnum.Surname)
    }
);

export const emailIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.Email)
    }
);

export const phoneIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.PhoneNumber) || m?.includes(JobseekerModerationAttributeEnum.PhoneNumber2)
    }
);

export const addressIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.Address)
    }
);


export const legalAddressIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.LegalAddress)
    }
);

export const genderIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.Gender)
    }
);

export const birthDateIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.DateOfBirth)
    }
);

export const currentAddressIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.Address)
    }
);

export const drivingLicenseIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.DrivingLicense)
    }
);

export const transportTypeIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.VehicleType)
    }
);

export const ownVehicleIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.IsVehicleOwner)
    }
);

export const selectJobseekerPersonalInfo = createSelector(
    selectJobseekerStoreState,
    selectLocalitiesMap,
    nameIsInModeration,
    emailIsInModeration,
    phoneIsInModeration,
    addressIsInModeration,
    selectGenderMap,
    legalAddressIsInModeration,
    genderIsInModeration,
    birthDateIsInModeration,
    currentAddressIsInModeration,
    drivingLicenseIsInModeration,
    transportTypeIsInModeration,
    ownVehicleIsInModeration,

    (js, l, nm, em, pm, am, g, lm, gm, bm, cm, dm, tm, ow) => {

        let jobseeker = js.jobseekerProfile.jobseeker;

        let month = jobseeker?.birthMonth < 10 ? '0' + jobseeker?.birthMonth : jobseeker?.birthMonth;
        let day = jobseeker?.birthDay < 10 ? '0' + jobseeker?.birthDay : jobseeker?.birthDay;
        
        if(jobseeker == null) return null;

        else{
            return{
                name: jobseeker.name,
                surname: jobseeker.surname,
                nameIsInModeration: nm,
    
                email: jobseeker.email,
                emailIsInModeration: em,
    
                phone: jobseeker.phone,
                phone2: jobseeker.phone2,
                phoneIsInModeration: pm,
        
                genderIsInModeration: gm,
                transportTypeIsInModeration: tm,
                currentAddressIsInModeration:cm,
                drivingLicenseIsInModeration:dm,
                birthDateIsInModeration:bm,
                ownVehicleIsInModeration: ow,
                photoHref: jobseeker.photoHref,
                birthDate: new Date((jobseeker.birthYear + "-" + month + "-" + day)),
                gender: g[jobseeker.genderId]?.name,
                locality: l[jobseeker.cityId]?.name,
                drivingLicenses: jobseeker.jobseekerDrivingLicenses.map(e => e.name),
    
                address: jobseeker.address,
                addressIsInModeration: am,

                legalAddress: jobseeker.legalAddress,
                legalAddressIsInModeration: lm,
    
                isVehicleOwner: jobseeker.isVehicleOwner,

                jobseekerVehicles: jobseeker.jobseekerVehicles,
                jobseekerExtendedLocations: jobseeker.jobseekerExtendedLocations,
                isPendingExtendedLocation: jobseeker.isPendingExtendendLocation
            }
        }
    }
);

export const selectPhotoIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.Photo)
    }
);

export const selectIsWorkExperienceFilledIsInModeration = createSelector(
    selectModState,
    (m) => {
        return m?.includes(JobseekerModerationAttributeEnum.IsWorkExperienceFilled)
    }
);

export const selectPhotoIsSelected = createSelector(
    selectJobseekerStoreState,
    s => s.jobseekerProfile.photoState.isPhotoSelected
);

export const selectBase64 = createSelector(
    selectJobseekerStoreState,
    s => s.jobseekerProfile.photoState?.base64
);

export const selectIsPhotoApproved = createSelector(
    selectJobseekerStoreState,
    s => s.jobseekerProfile.photoState.isPhotoApproved
);

//#endregion


//#region Jobseeke work experience
export const selectPendingNewJobseekerWorkExperiences = createSelector(
    selectJobseeker,
    e => e?.pendingNewJobseekerWorkExperiences
);


export const selectPendingModifiedJobseekerWorkExperiences = createSelector(
    selectJobseeker,
    e => e?.pendingModifiedJobseekerWorkExperiences
);

export const selectAllWorkExpericence = createSelector(
    selectJobseeker,
    selectPendingNewJobseekerWorkExperiences,
    selectPendingModifiedJobseekerWorkExperiences,
    (w, nw, mw) => {
        let result: JobseekerWorkExperience[] = [];

        w?.jobseekerWorkExperiences.forEach((c) => {
           if(!mw.map(e => e.jobseekerWorkExperienceId).includes(c.jobseekerWorkExperienceId) && (!nw.map(e => e.jobseekerWorkExperienceId).includes(c.jobseekerWorkExperienceId))){
               result.push(c);
           }
        });

        result = result.concat(nw, mw);
        
        result = result.sort(function(a, b){
            if (a.dateFromYear > b.dateFromYear) {
                return -1;
              }
            else if(a.dateFromYear == b.dateFromYear && a.dateFromMonth > b.dateFromMonth){
                return -1;
            }
        });
        
        return result;
    }
);


export const selectJobseekerWorkExperiences = createSelector(
    selectJobseeker,
    selectAllWorkExpericence,
    selectPendingNewJobseekerWorkExperiences,
    selectPendingModifiedJobseekerWorkExperiences,
    selectCategoriesMap,
    selectCurrentDate,
    (j, e, np, mp, o, d) => {
        let nowDate = new Date(d);

        if(j === null) return null;

        let result = e.map(e => {

            let x: JobseekerWorkExperienceViewModel = <any>{};
            Object.assign(x, e);

            x.isInModeration = (np?.some(c => c.jobseekerWorkExperienceId == e.jobseekerWorkExperienceId) || mp?.some(c => c.jobseekerWorkExperienceId  == e.jobseekerWorkExperienceId));


            // occupation categories
            let map: { [index: number]: string[] } = {};
            let dict = e.categories.reduce((r, a) => {
                r[a.category.head.id] = r[a.category.head.id] || [];

                r[a.category.head.id].push(a.category.name);


                return r;
            },map);
            x.groupedCategories = Object.keys(dict).map(e => ({ head: o[e]?.name, subcategories: dict[e] }));


            // workExperienceDuration 
            let isCurrentYearWithoutMonth = e?.dateToMonth == null && e?.dateToYear == nowDate.getFullYear();

            let yearFrom = e.dateFromYear;
            let monthFrom = e?.dateFromMonth != null ? (e?.dateFromMonth) : 1;
            let yearTo = 0;
            let monthTo = 0;
        
            let year: number;
            let month: number;

            if(e?.isCurrent){
                yearTo = nowDate.getFullYear();
                monthTo = nowDate.getMonth() + 1;
            }
            else{
                yearTo = e?.dateToYear;
                if(e?.dateToMonth != null){
                    monthTo = e?.dateToMonth;
                }
                else if(isCurrentYearWithoutMonth){
                    monthTo = nowDate.getMonth() + 1;
                }
                else{
                    monthTo = 12;
                }
            }

            if(yearFrom != yearTo){
                month = (12 - monthFrom) + monthTo + 1;
                year = yearTo - yearFrom - 1;
            }
            if(yearFrom == yearTo){
                month = (monthTo - monthFrom) + 1;
                year = yearTo - yearFrom;
            }
            if (month >= 12){
                year = year + (Math.floor(month/12));
                month = month % 12;
            }

            x.workExperienceDuration = {year: year, month: month};


            x.experienceDateFrom = new Date(e?.dateFromYear, e?.dateFromMonth - 1);
            x.experienceDateTo = new Date(e?.dateToYear, e?.dateToMonth - 1);

            x.objectId = e.objectId;
            
            return x;          
        })

        return result;
    }
);

export const selectTotalWorkExperienceMonths = createSelector(
    selectJobseeker,
    e => e?.totalWorkExperienceMonths
);

export const selectTotalWorkExperienceYears = createSelector(
    selectJobseeker,
    e => e?.totalWorkExperienceYears
);
//#endregion


//#region Jobseeke education
export const selectJobseekerEducations = createSelector(
    selectJobseeker,
    e => e?.jobseekerEducations
);

export const selectPendingModifiedJobseekerEducations = createSelector(
    selectJobseeker,
    e => e?.pendingModifiedJobseekerEducations
);

export const selectPendingNewJobseekerEducations = createSelector(
    selectJobseeker,
    e => e?.pendingNewJobseekerEducations
);


export const selectAllEducation = createSelector(
    selectJobseekerEducations,
    selectPendingModifiedJobseekerEducations,
    selectPendingNewJobseekerEducations,
    (w, nw, mw) => {
        let result: JobseekerEducation[] = [];

        w?.forEach((c) => {
           if(!mw.map(e => e.jobseekerEducationId).includes(c.jobseekerEducationId) && !nw.map(e => e.jobseekerEducationId).includes(c.jobseekerEducationId)){
            result.push(c);
        }
        });

        result = result.concat(nw, mw);
        
        result = result.sort(function(a, b){
            if (a.dateFromYear > b.dateFromYear) {
                return -1;
              }
            else if(a.dateFromYear == b.dateFromYear && a.dateFromMonth > b.dateFromMonth){
                return -1;
            }
        });
        
        return result;
    }
);


export const selectEducations = createSelector(
    selectAllEducation,
    selectPendingModifiedJobseekerEducations,
    selectPendingNewJobseekerEducations,
    (e, me, ne) => {
        let result = e.map(e => {
            if(e == null) return null;

            else{
                let x: JobseekerEducationViewModel = <any>{};
                Object.assign(x, e);
    
                x.isInModeration = (me?.some(c => c.jobseekerEducationId == e.jobseekerEducationId) || ne?.some(c => c.jobseekerEducationId == e.jobseekerEducationId));
    
                x.educationDateFrom = new Date(e?.dateFromYear, e?.dateFromMonth - 1);
                x.educationDateTo = new Date(e?.dateToYear, e?.dateToMonth - 1);
    
                x.hasProgram = e.educationLevel.educationLevelId == EducationLevelEnum.Bachelor || e.educationLevel.educationLevelId == EducationLevelEnum.Master || e.educationLevel.educationLevelId == EducationLevelEnum.Doctor || e.educationLevel.educationLevelId == EducationLevelEnum.College;
                x.hasSpecialization = e.educationLevel.educationLevelId == EducationLevelEnum.Bachelor || e.educationLevel.educationLevelId == EducationLevelEnum.College || e.educationLevel.educationLevelId == EducationLevelEnum.Doctor || e.educationLevel.educationLevelId == EducationLevelEnum.Master || e.educationLevel.educationLevelId == EducationLevelEnum.Other;
    
    
                x.educationLevelId = e.educationLevel.educationLevelId;
                x.educationalInstitutionId = e.educationalInstitution?.educationalInstitutionId;
                
                x.objectId = e.objectId;
                
                return x;
            }
        });

        return result;
    }
)


export const selectMaxEducationLevel = createSelector(
    selectJobseeker,
    selectJobseekerLookup,
    (e, l) => {
        let maxRank =  e?.jobseekerEducations.length > 0 ? Math.max.apply(null, e?.jobseekerEducations.map(c => c.educationLevel.rankId)) : null;
        let result = maxRank == null ? maxRank : e?.jobseekerEducations.find(e => e.educationLevel.rankId == maxRank).educationLevel.name; 
 
        return result;
    }
);
//#endregion


//#region  jobseeker languages
export const selectApprovedJobseekerLanguages = createSelector(
    selectJobseeker,
    e => e?.jobseekerLanguages
);

export const selectPendingJobseekerLanguages = createSelector(
    selectJobseeker,
    e => e?.pendingJobseekerLanguages
);

export const selectJobseekerLanguages = createSelector(
    selectApprovedJobseekerLanguages,
    selectPendingJobseekerLanguages,
    (l, pl) => {
        let result: JobseekerLanguage[] = [];
        
        l?.forEach((c) => {
            if(!pl.map(e => e.jobseekerLanguageId).includes(c.jobseekerLanguageId)){
                result.push(c);
            }
        })

        result = result.concat(pl);
        return result;
    }
);

export const selectWorldLanguageList = createSelector(
    selectJobseekerLookup,
    selectJobseekerLanguages,
    (l, lan) => {
        
        let worldLanguages = l.worldLanguages;

        let result = worldLanguages.filter(e => {
            return !lan.some(c => c.worldLanguage.worldLanguageId == e.value)
        })

        return result;
    }
)

export const languageInModeration = createSelector(
    selectApprovedJobseekerLanguages,
    selectPendingJobseekerLanguages,
    (l, pl) => {
        let map: { [index: number]: boolean } = {};
        let dict = l?.reduce((r, a) => {
            r[a.jobseekerLanguageId] = pl.some(e => e.jobseekerLanguageId == a.jobseekerLanguageId);
            return r;
        }, map)

        return dict;
    }
)
//#endregion


//#region jobseeker documents
export const selectUploadedDocuments = createSelector(
    selectJobseeker,
    e => e?.uploadedDocuments
);

export const selectpendingDocuments = createSelector(
    selectJobseeker,
    e => e?.pendingDocuments
);

export const selectJobseekerDocuments = createSelector(
    selectUploadedDocuments,
    selectpendingDocuments,
    (d, pd) => {
        let result: JobseekerDocument[] = [];

        result = result.concat(d, pd);

        return result;
    }
);

export const documentsInModeration = createSelector(
    selectJobseeker,
    selectJobseekerDocuments,
    selectpendingDocuments,
    (j, d, pd) => {
        
        if(j == null) return null;

        let map: { [index: number]: boolean } = {};
        let dict = d.reduce((r, a) => {
            r[a.jobseekerDocumentId] = pd?.some(e => e.jobseekerDocumentId == a.jobseekerDocumentId);
            return r;
        }, map)

        return dict;
    }
)
//#endregion

export const selectPersonalInfo = createSelector(
    selectJobseeker,
    j => {
        let result: JobseekerPersonalInfoModel = <any>{};

        result.name = j?.name;
        result.surname = j?.surname;
        result.email = j?.email;
        result.phone = j?.phone;
        result.phone2 = j?.phone2;
        
        result.address = j?.address;
        result.legalAddress = j?.legalAddress;
        result.sameAsCurrentAddress = j?.sameAsCurrentAddress;
        result.gender = j?.genderId;
        result.city = j?.cityId;
        result.drivingLicenses = j?.jobseekerDrivingLicenses.map(e => e.drivingLicenceId);
        result.ownVehicle = j?.isVehicleOwner;
        result.birthMonth = j?.birthMonth;
        result.birthYear = j?.birthYear;
        result.birthDay = j?.birthDay;
        result.extendedLocationPath = j?.extendedLocationPath;

        result.jobseekerVehicles = j?.jobseekerVehicles.map(c => c.vehicleTypeId);
        result.extendedLocationPath = j?.extendedLocationPath;
        result.jobseekerExtendedLocations = j?.jobseekerExtendedLocations;

        return result;
    }
);

export const selectPersonInfoHasOwnVehicle = createSelector(
    selectJobseeker,
    j => j?.isVehicleOwner
);

export const selectInstitutionSelectModeAnable = createSelector(
    selectJobseekerStoreState,
    (e) => {
        let levelId = e.jobseekerProfile.educationUiState.selectedJobseekerEducation.educationLevelId;
        return levelId == EducationLevelEnum.Bachelor || levelId == EducationLevelEnum.Master || levelId == EducationLevelEnum.Doctor
    }
);

export const selectProgramSelectModeEnable = createSelector(
    selectJobseekerStoreState,
    (e) => {
        let level = e.jobseekerProfile.educationUiState.selectedJobseekerEducation.educationLevelId;
        return level == EducationLevelEnum.Bachelor || level == EducationLevelEnum.Master
    }
);

export const selectEducation = createSelector(
    selectJobseekerStoreState,
    selectEducationalInstitutions,
    (e, i) => {
        let jobseekerEducation = e.jobseekerProfile.educationUiState.selectedJobseekerEducation;
        let x: JobseekerEducationModel = <any>{};

        x.jobseekerEducationId = jobseekerEducation?.jobseekerEducationId;
        x.educationLevelId = jobseekerEducation?.educationLevelId;
        x.educationalInstitutionId = jobseekerEducation?.educationalInstitutionId;
        x.educationalProgramId = jobseekerEducation?.educationalProgramId;
        x.educationalProgramName = jobseekerEducation?.educationalProgramName;
        x.institution = jobseekerEducation?.institution;
        x.specialization = jobseekerEducation?.specialization;
        x.dateFromYear = jobseekerEducation?.dateFromYear;
        x.dateFromMonth = jobseekerEducation?.dateFromMonth;
        x.dateToYear = jobseekerEducation?.dateToYear;
        x.dateToMonth = jobseekerEducation?.dateToMonth;
        x.description = jobseekerEducation?.description;
        x.attachment = jobseekerEducation?.attachment;
        x.objectId = jobseekerEducation?.objectId

        return x;
    }
);

export const selectHasAttachment = createSelector(
    selectJobseekerStoreState,
    (e) => {
        return e.jobseekerProfile.educationUiState.selectedJobseekerEducation?.attachment != null;
    }
)

export const selectEducationLevel = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.educationUiState.selectedJobseekerEducation?.educationLevelId
);

export const selectVehicleTypes = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerLookup.lookup?.vehicleTypes
);

export const selectIsInstitutionListAvailable = createSelector(
    selectEducationLevel,
    e => e == EducationLevelEnum.Bachelor || e == EducationLevelEnum.Doctor || e == EducationLevelEnum.Master || e == EducationLevelEnum.College
);

export const selectIsProgramListAvailable = createSelector(
    selectEducationLevel,
    e => e == EducationLevelEnum.Bachelor || e == EducationLevelEnum.Master || e == EducationLevelEnum.Doctor || e == EducationLevelEnum.College
);

export const selectInstitutionSelectMode = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.educationUiState.isInstitutionSelectModeOn
);

export const selectPrgogramSelectMode = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.educationUiState.isProgramSelectModeOn
);

export const selecIsProgramAvailable = createSelector(
    selectEducationLevel,
    e => e == EducationLevelEnum.Bachelor || e == EducationLevelEnum.Doctor || e == EducationLevelEnum.Master || e == EducationLevelEnum.College
);

export const selectIsSpecializationAvailable = createSelector(
    selectEducationLevel,
    e => e != null && e != EducationLevelEnum.HighSchool && e != EducationLevelEnum.IncompleteHighSchool
);

export const selectProgramList = createSelector(
    selectJobseekerStoreState,
    (s) => s.jobseekerLookup.lookup.educationProgramsList
);

export const selectProgramMap = createSelector(
    selectProgramList,
    e => {
        let map: {[index: number]: EducationalProgram} ={};
        e?.reduce((a, c) => {
            a[c.educationalProgramId] = c
            return a;
        }, map)

        return map;
    }
);

export const searchedInstitutionList = (searchValue: string) => createSelector(
    selectEducationalInstitutions,
    e => e.filter(e => e.name.includes(searchValue) || e.nameLatin.toLowerCase().includes(searchValue.toLowerCase()))
);

export const searchedProgramList = (searchValue: string) => createSelector(
    selectProgramList,
    e =>  e.filter(e => e.name.includes(searchValue) || e.nameLatin.toLowerCase().includes(searchValue.toLowerCase()))
);


export const selectJobseekerLanguageById = (id: number) => createSelector(
    selectJobseekerLanguages,
    e => e.find(c => c.jobseekerLanguageId == id)
);

export const selectJobseekerLanguage = (id: number) => createSelector(
    selectJobseekerLanguageById(id),
    e => {
        if(id != null){
            let x: JobseekerLanguageModel = <any>{};

            x.jobseekerLanguageId = e.jobseekerLanguageId;
            x.worldLanguageId = e.worldLanguage.worldLanguageId;
            x.languageProficiencyLevelId = e?.languageProficiencyLevel.languageProficiencyLevelId;
            x.fileName = e.attachment?.fileName
            x.name = e.worldLanguage.name;
            x.objectId = e.objectId

            return x;
       }
    }
);

export const selectJobseekerModerationLastChangeDate = createSelector(
    selectJobseeker,
    e => e.moderationLastChangeDate
)

export const languageHasAttachment = (id: number) => createSelector(
    selectJobseekerLanguageById(id),
    e => e?.attachment != null
);

export const selectFileName = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.file
);

export const selectSelectedFileText = createSelector(
    selectJobseekerStoreState,
    (e) => {
        return 'file.' + e.jobseekerProfile.file?.filename.split('.').pop()
    }
);

export const isFileUploading = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.fileUploading
);

export const selectSelectedCategoryIds = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.workExperienceUiState?.currentSelectedCategories
);

export const selectedCategoires = createSelector(
    selectSelectedCategoryIds,
    selectCategoriesMap,
    (s, c) => {
        if(s != undefined){
            let categories = s.map(cc => c[cc]);
    
            let map: { [index: number]: string[] } = {};
            let dict  = categories.reduce((r, a) => {
                r[a.head?.id] = r[a.head?.id] || [];
    
                r[a.head?.id].push(a.name);
    
    
                return r;
            },map);
            let result = Object.keys(dict).map(e => ({ head: c[e]?.name, subcategories: dict[e] }));
    
            return result;
        }
    }
);

export const selectJobseekerWorkExperience = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.workExperienceUiState?.workExperience
);

export const selectWorkExperienceIsCurrent = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.workExperienceUiState?.isCurrent
);

export const selectIsProfileLoading = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.loading
);

export const selectIsJobseekerProfileUpdating = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.updateLoading
);

export const selectIsFileSizeValid = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.isFileSizeValid
);

export const selectAddress = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.personalInfoState.selectedAddress
);

export const selectSameAsCurrentAddress = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.personalInfoState.selectedSameAsCurrentAddress
);

export const selectLegalAddress = createSelector(
    selectJobseekerStoreState,
    e => (e.jobseekerProfile.personalInfoState.selectedSameAsCurrentAddress) ? e.jobseekerProfile.personalInfoState.selectedAddress : e.jobseekerProfile.personalInfoState.selectedLegalAddress
);

export const selectDaysList = createSelector(
  selectJobseekerStoreState,
  (e) => {
    let size: number;

    let selectedMonth = e.jobseekerProfile.personalInfoState?.selectedMonth;
    let selectedYear = e.jobseekerProfile.personalInfoState?.selectedYear;

    let isLeapYear = selectedYear != 0 && selectedYear != null && selectedYear % 4 == 0;
    let monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    if (selectedMonth != 0 && selectedMonth != null) {
      if (selectedMonth != 2) {
        size = monthDays[selectedMonth - 1];
      }
      if (selectedMonth == 2) {
        size = isLeapYear ? 29 : 28;
      }
    }
    else {
      size = 31;
    }

    return range(size);
  }
);

function range(size, start = 1) {
    return [...Array(size).keys()].map(i => i + start);
}


export const selectIsCvExportLoading = createSelector(
    selectJobseekerStoreState,
    e => e.jobseekerProfile.cvExportState.loading
);