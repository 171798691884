<div *ngIf="{jobseekerWorkExperience: jobseekerWorkExperience$ | async,
             monthList: monthList$ | async,
             selectedFile: selectedFile$ | async,
             isFileUploading: isFileUploading$ | async,
             selectedCategoryIds: selectedCategoryIds$ | async,
             selectedCategories: selectedCategories$ | async,
             isCurrent: isCurrent$ | async,
             isUpdating: isUpdating$ | async,
             selectedFileText: selectedFileText$ | async,
             systemConfigs: systemConfigs$ | async,
             isFileSizeValid: isFileSizeValid$ | async,
             jobseekerId: jobseekerId$ | async,
             moderationLastChangeDate: moderationLastChangeDate$ | async
            } as data">
    <app-spinner [isLoading]="isUpdating$ | async" [isFixedPosition]="false">
        <form [formGroup]="editForm"  #ngForm="ngForm" [appConnectForm]="data.jobseekerWorkExperience" class="form">
            <div class="title">{{title | translate}}</div>
    
            <div class="form-item position">
                <label class="form-item__label position__label">{{ 'label.Position' | translate }}</label>
                <div>
                    <input class="form-item__value position__value" maxlength="200" type="text" formControlName="positionTitle" name="positionTitle">
                    <mat-error *ngIf="editForm.controls['positionTitle'].hasError('required') && (editForm.controls['positionTitle'].touched || ngForm.submitted)">{{'message.MandatoryField' | translate}}</mat-error>
                </div>
            </div>
    
            <div class="categories">
                <div *ngFor="let headCategories of data?.selectedCategories" class="categories__list">
                    <span class="categories__head">{{headCategories.head}}: </span>
                    <span class="categories__sub">
                        <span *ngFor="let categories of headCategories.subcategories, let last = last">
                            {{categories}}{{last ? '' : '; '}}
                        </span>
                    </span>
                </div>
        
                <button (click)="categoryPicker(data.selectedCategoryIds)" class="categories__button" type="button">
                    {{(data.jobseekerWorkExperience?.groupedCategories == null ? 'label.SelectPositionCategories' : 'label.EditPositionCategories') | translate}}
                </button>
                <div>
                    <mat-error *ngIf="data.selectedCategoryIds?.length == 0 && (categoryButtonIsTouched || ngForm.submitted)">{{'message.MandatoryField' | translate}}</mat-error>
                </div>
            </div>
    
            <div class="form-item employer">
                <label class="form-item__label employer__label">{{ 'label.Employer' | translate }}</label>
                <div>
                    <input class="form-item__value employer__value" maxlength="200" type="text" formControlName="employer" name="employer">
                    <mat-error *ngIf="editForm.controls['employer'].hasError('required') && (editForm.controls['employer'].touched || ngForm.submitted)">{{'message.MandatoryField' | translate}}</mat-error>
                </div>
            </div>
    
            <div>
                <div class="dates">
                    <div class="form-item date-from">
                        <label class="form-item__label date-from__label">{{'label.StartDate' | translate}}</label>
                        <div class="date-from__value">
                            <select class="form-item__value date-from__value-month" formControlName="dateFromMonth">
                                <option [ngValue]="null">{{'label.Month' | translate: { count: 1 } }}</option>
                                <option *ngFor="let month of data.monthList" [ngValue]="month.value">
                                    {{month.name}}
                                </option>
                            </select>
            
                            <div>
                                <input class="form-item__value date-from__value-year" maxlength="4" type="text" formControlName="dateFromYear" name="dateFromYear" placeholder="{{'label.Year' | translate: { count: 1 } }}">
                                <mat-error *ngIf="editForm.controls['dateFromYear'].hasError('required') && (editForm.controls['dateFromYear'].touched || ngForm.submitted)">{{'message.MandatoryField' | translate}}</mat-error>
                                <mat-error *ngIf="editForm.controls['dateFromYear'].hasError('pattern') && (editForm.controls['dateFromYear'].touched || ngForm.submitted)">{{'message.IncorrectFormat' | translate}}</mat-error>
                            </div>
                        </div>
                    </div>
            
                    <div class="form-item date-to">
                        <label class="form-item__label date-to__label">{{'label.EndDate' | translate}}</label>
                        <div class="date-to__value">
                            <select [attr.disabled]="data?.isCurrent ? '' : null" class="form-item__value date-to__value-month" formControlName="dateToMonth">
                                <option [ngValue]="null">{{'label.Month' | translate: { count: 1 } }}</option>
                                <option *ngFor="let month of data.monthList" [ngValue]="month.value">
                                    {{month.name}}
                                </option>
                            </select>
            
                            <div>
                                <input [attr.disabled]="data?.isCurrent ? '' : null" maxlength="4" type="text" class="form-item__value date-to__value-year" formControlName="dateToYear" name="dateToYear" placeholder="{{'label.Year' | translate: { count: 1 } }}">
                                <mat-error *ngIf="editForm.hasError('RequiredYear') && ngForm.submitted">{{'message.MandatoryField' | translate}}</mat-error>
                                <mat-error *ngIf="editForm.controls['dateToYear'].hasError('pattern') && (editForm.controls['dateToYear'].touched || ngForm.submitted)">{{'message.IncorrectFormat' | translate}}</mat-error>
                            </div>
                        </div>
                        <mat-error class="error error--date-to" 
                                    *ngIf="editForm.hasError('date') && (editForm.controls['dateToYear'].touched || ngForm.submitted) && editForm.controls['dateToYear'].valid && editForm.controls['dateFromYear'].valid">
                            {{'message.StartDateMustNotComeAfterEndDate' | translate}}
                        </mat-error>
                    </div>
            
                    <div class="form-item currently-work">
                        <mat-checkbox (click)="onCurrentExperience(data?.isCurrent)" formControlName="isCurrent">{{'label.ICurrentlyWorkHere' | translate}}</mat-checkbox>
                    </div>
                </div>
            </div>
    
            <div class="form-item description">
                <label class="form-item__label description__label">{{'label.JobDescription' | translate}}</label>
                <textarea class="form-item__value description__value" type="text" formControlName="description" placeholder="({{'text.EnterShortListOfDuties' | translate}})"></textarea>
            </div>
    
            <input type="file" style="display:none;" #hiddenfileinput (change)="onFileSelect($event, data.systemConfigs.jobseeker.jobseekerAttachmentMaxSize, data.jobseekerId)"/>
            <div *ngIf="!data.hasAttachment" class="form-item attachment">
                <app-advanced-button (click)="hiddenfileinput.click()" [iconType]="iconButtonType.attachment" [text]="'label.UploadDocuments' | translate" [type]="'blue'" class="attachment__btn"></app-advanced-button>
    
                <button (click)="hiddenfileinput.click()" class="attachment__choose-btn" type="button">{{'label.Select' | translate}}</button>
                <div *ngIf="data.selectedFile?.filename != null" class="selected-file">
                    {{data.selectedFileText}}
                </div>
                <span class="attachment__choose-text">({{'label.EgRecommendationLetter' | translate}})</span>
            </div>
            <div *ngIf="data.hasAttachment" class="form-item attachment">
                sdfd
                <app-advanced-button (click)="downloadAttachment(data.jobseekerWorkExperience?.objectId, data.jobseekerId)" [iconType]="iconButtonType.attachment" [text]="'label.Attachment' | translate" [type]="'blue'" class="attachment__btn attachment__btn--uploaded"></app-advanced-button>
                <button class="attachment__delete" type="button">{{'label.Delete' | translate}}</button>
                <button  (click)="hiddenfileinput.click()" class="attachment__replace" type="button">{{'label.Change' | translate}}</button>
                <div *ngIf="data.selectedFile?.filename != null" class="selected-file">
                    {{data.selectedFileText}}
                </div>
            </div>
            <mat-progress-bar *ngIf="data.isFileUploading" mode="indeterminate"></mat-progress-bar>
            <div *ngIf="!data.isFileSizeValid && data.isFileSizeValid != null" class="attachment-size-validation">
                <mat-error>
                    {{'message.JobseekerFileSizeValidation' | translate}}
                </mat-error>
            </div>

            <mat-dialog-actions>
                <div class="buttons">
                    <app-button [ngClass]="{'disabled': editForm.invalid}" type="submit" (click)="onSave(data.selectedFile?.filename, data?.selectedCategoryIds, data.isFileSizeValid, data.jobseekerId, data.moderationLastChangeDate, data.jobseekerWorkExperience?.objectId)" [text]="'label.Save' | translate" [type]="buttonType.coloured" [typeAttribute]="buttonAttributeType.submit" class="button-save"></app-button>
                    <app-button [mat-dialog-close] ="true" [text]="'label.Cancel' | translate" [type]="buttonType.uncoloured" [typeAttribute]="buttonAttributeType.button"></app-button>
                </div>
            </mat-dialog-actions>
        </form>
   </app-spinner>
</div>
