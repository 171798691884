import { Component, OnInit, Input } from '@angular/core';
import { theme } from 'src/environments/theme';
import { CvBox } from '../shared/models/cv-box-model';
import { UserTypeEnum } from '@hrra/common-models';

@Component({
  selector: 'app-cv-box-item',
  templateUrl: `./${theme.name}/cv-box-item.component.html`,
  styleUrls: [`./${theme.name}/cv-box-item.component.scss`]
})
export class CvBoxItemComponent implements OnInit {

  @Input() cvBox: CvBox;
  @Input() userType: UserTypeEnum;
  isLogoWitBackground = true;

  userTypeEnum: any;
  
  constructor() { 
    this.userTypeEnum = UserTypeEnum;
  }

  ngOnInit(): void {
    console.log(this.userType);
  }

}
