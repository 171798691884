<app-spinner [isLoading]="isLoading$ | async">
    <div class="content">
        <div class="general">
            <h2 class="general__title">{{'label.CvBoxPageTitle' | translate}}</h2>
            <div class="general__search">
                <div class="general__search-input">
                    <button (click)="onSearchClick(searchTerm)" class="general__search-icon-btn">
                        <img class="general__search-icon" src="assets/img/common/search-icon.svg" alt="">
                    </button>
                    <input [(ngModel)]="searchTerm" type="text" class="general__search-text" placeholder="({{'label.SearchCompany' | translate}})">
                </div>    
                <button (click)="onSearchClick(searchTerm)" class="general__search-btn">{{'label.Search' | translate}}</button>
            </div> 
        </div>
        <div class="description">
            <div [innerHTML]="'text.CvBoxDescriptionTitle' | translate :{ website: ((website$ | async)?.displayName | uppercase) } | safeHtml"></div>
            <div [innerHTML]="'text.CvBoxDescriptionAdvantages' | translate | safeHtml"></div><br>

            <div class="description__follow">{{'text.CvBoxDescriptionFollowPart1' | translate}}</div>
            <ol class="description__list">
                <li>
                    <span class="description__link">
                        <a [routerLink]="['/jobseeker/register']">
                        <span>{{'text.CvBoxDescriptionFollowLinkRegister' | translate}} </span>
                    </a>
                    </span>
                    <span>{{'text.CvBoxDescriptionFollowPart2' | translate:{ website: (website$ | async)?.displayName | uppercase } }}</span>
                </li>
                <li>
                    <span class="description__link">
                        <a [routerLink]="['/jobseeker/sign-in']">
                            <span>{{'text.CvBoxDescriptionFollowLinkSignin' | translate}} </span>
                        </a>
                    </span>
                    <span [innerHTML]="'text.CvBoxDescriptionFollowPart3' | translate :{ website: ((website$ | async)?.displayName | uppercase) } | safeHtml"></span>
                </li>
            </ol>
        </div>
        <div *ngIf="(vipCvBoxes$ | async)?.length" class="section section--vip">
            <div class="section section__title">{{'label.Vip' | translate}}</div>
            <div class="section__list">
                <app-cv-box-item *ngFor="let cvBox of vipCvBoxes$ | async" [cvBox]="cvBox" class="item" [userType]="userType$ | async"></app-cv-box-item>
            </div>
        </div>

        <!--Vip banner-->
        <div class="banner">
            <app-banner [banner]="vipBanner$ | async" [pageId]="pageId.CvBoxPage"></app-banner>
        </div>

        <div *ngIf="(premiumCvBoxes$ | async)?.length" class="section section--exclusive">
            <div class="section__title">{{'label.Premium' | translate}}</div>
            <div class="section__list">
                <app-cv-box-item *ngFor="let cvBox of premiumCvBoxes$ | async" [cvBox]="cvBox" class="item" [userType]="userType$ | async"></app-cv-box-item>
            </div>
        </div>

        <!--Premium banner-->
        <div class="banner">
            <app-banner [banner]="premiumBanner$ | async" [pageId]="pageId.CvBoxPage"></app-banner>
        </div>

        <div *ngIf="(standardCvBoxes$ | async)?.length" class="section section--regular">
            <div class="section__title">{{'label.Standard' | translate}}</div>
            <div class="section__list">
                <app-cv-box-item *ngFor="let cvBox of standardCvBoxes$ | async" [cvBox]="cvBox" class="item" [userType]="userType$ | async"></app-cv-box-item>
            </div>
        </div>
        
        <!--Regular banner-->
        <div class="banner">
            <app-banner [banner]="regularBanner$ | async" [pageId]="pageId.CvBoxPage"></app-banner>
        </div>

    </div>
</app-spinner>