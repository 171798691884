<div class="container">
    <div [ngClass]="{'without-photo': photoHref === null && (userType === type.Jobseeker || userType === type.Internal)}" class="photo-content">
        <img *ngIf="photoHref" class="photo-content__img" [src]="photoHref" alt="">
    </div>

    <div *ngIf="!photoIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" class="photo-edit">
        <div (click)="onEdit(photoIsInModeration)" class="edit" [ngClass]="{'edit--center': !photoHref}">
            <svg-icon src="/assets/icons/edit-blue.svg" [svgClass]="'edit__icon'"></svg-icon>
            <div *ngIf="!photoHref" class="photo-edit__text">{{'label.UploadPhoto' | translate}}</div>
        </div>

        <svg-icon *ngIf="photoHref" [ngClass]="{'delete--center': photoIsInModeration}" (click)="onDelete()" src="/assets/icons/delete.svg" [svgClass]="'delete'"></svg-icon>
    </div>

    <app-icon *ngIf="photoIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>
</div>