import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobseekerWorkExperienceViewModel, UserTypeEnum } from '@hrra/common-models';
import { IconButtonTypeEnum } from '@hrra/ui';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hrra-jobseeker-work-experience',
  templateUrl: './jobseeker-work-experience.component.html',
  styleUrls: ['./jobseeker-work-experience.component.scss']
})
export class JobseekerWorkExperienceComponent implements OnInit {

  public iconButtonType: any;
  public type: any;

  @Input() workExperience: JobseekerWorkExperienceViewModel;
  @Input() timeZone: string;
  @Input() userType: UserTypeEnum;

  @Input() currentLang: string;

  @Output() onEditExperience: EventEmitter<JobseekerWorkExperienceViewModel> = new EventEmitter<JobseekerWorkExperienceViewModel>();
  @Output() onDeletExperience: EventEmitter<{id: number, objectId: string}> = new EventEmitter<{id: number, objectId: string}>();
  @Output() onDownloadExperienceAttachment: EventEmitter<string> = new EventEmitter<string>();

  constructor(private translate: TranslateService) {this.iconButtonType = IconButtonTypeEnum; this.type = UserTypeEnum }


  ngOnInit(): void {
  }
  
  public onEdit(event){
    this.onEditExperience.emit(event);
  }

  public onDelete(id: number, objectId: string){
    this.onDeletExperience.emit({id: id, objectId: objectId});
  }

  downloadAttachment(objectId: string){
    this.onDownloadExperienceAttachment.emit(objectId);
  }
}
