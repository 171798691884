import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, combineLatest, filter, take } from 'rxjs';
import { CvBox } from 'src/app/cv-box/shared/models/cv-box-model';
import { theme } from 'src/environments/theme';
import { Announcement } from '../shared/models/announcement-model';
import { select, Store } from '@ngrx/store';
import { AnnouncementStoreState } from 'src/app/root-store/announcement-store/state';
import { loadFrontPageCvBoxes } from 'src/app/root-store/cv-box-store/actions/cv-box-front-page-actions';
import { addAnnouncementsInMergedSectionList, addAnnouncementsInVipSectionList, getFrontPageBannerList, loadFrontPageAnnouncements, resetAnnouncementListCounts, showCvBoxSlider } from 'src/app/root-store/announcement-store/announcement-front-page-actions';
import { loadLastListingPage, sendBannerViewToAnalytic } from 'src/app/root-store/actions';
import { PageNameEnum } from 'src/app/shared/models/enums/page-names-enum';
import { selectFrontPageBannerIds, selectFrontPageBottomBanner, selectFrontPageCityLinks, selectFrontPageMetaData, selectFrontPageMidBanner, selectFrontPageMidBanner2, selectFrontPageTopBanner, selectIsFrontPageLinkVisible, selectIsLoading, selectIsMainPaigCvBoxSliderVisible, selectMergedAnnouncements, selectVipAnnouncementsInfiniteScoll, selectVipCvAnnouncements } from 'src/app/root-store/announcement-store/selectors';
import { MetaService } from 'src/app/shared/services/meta.service';
import { FrontPageCityLink } from '../shared/services/announcement.service';
import { selectAllPremiumCvBoxes, selectAllVipCvBoxes } from 'src/app/root-store/cv-box-store/selectors';
import { AnnouncementFilter } from 'src/app/shared/models/announcement-filter';
import { AnnouncementService } from 'src/app/shared/services/announcement.service';
import { Router } from '@angular/router';
import { BannerTypeEnum } from 'src/app/banner/shared/models/enums/banner-type-enum';
import { BannerTargetEnum } from 'src/app/banner/shared/models/enums/banner-target-enum';
import { Banner } from 'src/app/banner/shared/models/banner-model';
import { BannerPositionEnum } from 'src/app/banner/shared/models/enums/banner-position-enum';
import { Breakpoints } from 'src/app/shared/models/breakpoints-model';
import { SsrService } from 'src/app/core/ssr.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { getOwlOptions } from 'src/app/shared/helpers/cv-box-slider-otions ';
import { selectCurrentUserType, selectCurrentWebsite } from 'src/app/root-store/selectors';
import { BannerHeaderPositionId } from 'src/app/shared/models/enums/banner-header-position-id-enum';

@Component({
  selector: 'app-front-page',
  templateUrl: `./${theme.name}/./front-page.component.html`,
  styleUrls: [`./${theme.name}/front-page.component.scss`]
})
export class MainPageComponent implements OnInit {
 
  metaDataSubscription: Subscription;

  date = new Date();

  pageId:any;
  
  targetId: number = BannerTargetEnum.Desktop;

  vipCvAnnouncements$: Observable<Announcement[]>;
  vipAnnouncements$: Observable<Announcement[]>;

  mergedAnnouncements$: Observable<Announcement[]>;

  selectFrontPageCityLinks$: Observable<FrontPageCityLink[]>;

  frontPageTopBanner$: Observable<Banner>;
  frontPageMidBanner$: Observable<Banner>;
  frontPageMidBanner2$: Observable<Banner>;
  frontPageBottomBanner$: Observable<Banner>;

  vipCvBoxes$: Observable<CvBox[]>;
  premiumCvBoxes$: Observable<CvBox[]>;

  isLoading$: Observable<boolean>;
  isFrontPageLinkVisible$: Observable<boolean>;
  isCvBoxSliderVisible$: Observable<boolean>;

  bannerType: any;
  cvBoxSliderOptions: OwlOptions;

  pageName: any;

  bannerHeaderId: any;

  userType$: Observable<any>;

  constructor(private store: Store<AnnouncementStoreState>, 
              private metaService: MetaService, 
              private announcementService: AnnouncementService, 
              private router: Router,
              private ssr: SsrService) 
              { 
                this.bannerHeaderId = BannerHeaderPositionId
                this.pageName = PageNameEnum;
                this.cvBoxSliderOptions = getOwlOptions();
                this.bannerType = BannerTypeEnum;
                this.targetId = window.innerWidth > Breakpoints.medium ? BannerTargetEnum.Desktop : BannerTargetEnum.Responsive;
                this.pageId = BannerHeaderPositionId
              }

  ngOnInit(): void {
    let frontPageBanners$ = this.store.select(selectFrontPageBannerIds);
    let providerWebsiteId$ = this.store.select(selectCurrentWebsite);

    combineLatest([frontPageBanners$, providerWebsiteId$])
    .pipe(
      filter(([banners, provderWebsite]) =>{
        return banners.bannerIdList.length > 0 && provderWebsite !== null && provderWebsite !== undefined;
      }),
      take(1)
    )
    .subscribe(
      ([b, p]) => {
        this.store.dispatch(sendBannerViewToAnalytic({payload:{bannerIdList: b, providerWebsiteId:p.providerWebsiteId, pageId: BannerHeaderPositionId.FrontPage}}))
      }
    )

    this.store.dispatch(loadLastListingPage({payload: PageNameEnum.FrontPage}));
    this.isCvBoxSliderVisible$ =  this.store.pipe(select(selectIsMainPaigCvBoxSliderVisible));

    if(this.ssr.isPlatformBrowser()){
      this.store.dispatch(getFrontPageBannerList({payload: {targetId: this.targetId, bannerHeadPositionId: BannerPositionEnum.Main_page}}));
      this.store.dispatch(showCvBoxSlider({payload: true}));
    }

    this.store.dispatch(loadFrontPageAnnouncements());
    this.store.dispatch(loadFrontPageCvBoxes());

    this.metaDataSubscription = this.store.pipe(select(selectFrontPageMetaData)).subscribe(
      (data) => {
        this.metaService.updateMetaData(data.website, data.metaData);
      }
    )


    this.vipCvAnnouncements$ = this.store.select(selectVipCvAnnouncements);

    this.vipAnnouncements$ = this.store.select(selectVipAnnouncementsInfiniteScoll);
    this.mergedAnnouncements$ = this.store.select(selectMergedAnnouncements);

    this.selectFrontPageCityLinks$ = this.store.select(selectFrontPageCityLinks);

    this.frontPageTopBanner$ = this.store.select(selectFrontPageTopBanner);
    this.frontPageMidBanner$ = this.store.select(selectFrontPageMidBanner);
    this.frontPageMidBanner2$ = this.store.select(selectFrontPageMidBanner2);
    this.frontPageBottomBanner$ = this.store.select(selectFrontPageBottomBanner);
    
    this.vipCvBoxes$ = this.store.select(selectAllVipCvBoxes);
    this.premiumCvBoxes$ = this.store.select(selectAllPremiumCvBoxes);

    this.isLoading$ = this.store.pipe(select(selectIsLoading));
    this.isFrontPageLinkVisible$ = this.store.pipe(select(selectIsFrontPageLinkVisible));

    this.userType$ = this.store.select(selectCurrentUserType);
  }

  announcementId(index: number, announcement: Announcement){
    return announcement.announcementId;
  }

  cityLinkId(index: number, cityLink: FrontPageCityLink){
    return cityLink.localityId;
  }

  cvBoxId(index: number, cvBox: CvBox){
    return cvBox.customerId;
  }

  onLocalityLinkClick(localityOption:  number){
    let searchData = <AnnouncementFilter>{
      localityIds: [localityOption]
    };

    let q = this.announcementService.convertLocalityOptionToQueryParams(searchData);

    this.router.navigate(['./search-posting'], {
      queryParams: q
    });
  }

  ngOnDestroy() {
    this.metaDataSubscription.unsubscribe();
    this.store.dispatch(resetAnnouncementListCounts());
  }

  onScrollDown(ev) {
    this.store.dispatch(addAnnouncementsInVipSectionList());
  }

  onScrollDownMergedAnn(ev) {
    this.store.dispatch(addAnnouncementsInMergedSectionList());
  }
}
function loadFroAddAnnouncementsInVipSectionListntPageCvBoxes(): any {
  throw new Error('Function not implemented.');
}

