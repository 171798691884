import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobseekerDocument, JobseekerDocumentTypeEnum, UserTypeEnum } from '@hrra/common-models';
import { IconButtonTypeEnum } from '@hrra/ui';

@Component({
  selector: 'hrra-jobseeker-document',
  templateUrl: './jobseeker-document.component.html',
  styleUrls: ['./jobseeker-document.component.scss']
})
export class JobseekerDocumentComponent implements OnInit {

  public iconButtonType: any;
  public documentType: any;
  public type: any;

  @Input() document: JobseekerDocument;
  @Input() documentIsInMod: boolean;
  @Input() documentTypeMap;
  @Input() userType: UserTypeEnum;

  @Output() onDeletDocument: EventEmitter<{id: number, objectId: string}> = new EventEmitter<{id: number, objectId: string}>();
  @Output() onDownloadDocment: EventEmitter<string> = new EventEmitter<string>();

  constructor() {this.iconButtonType = IconButtonTypeEnum, this.documentType = JobseekerDocumentTypeEnum, this.type = UserTypeEnum }

  ngOnInit(): void {
  }

  public onDelete(id: number, objectId: string){
    this.onDeletDocument.emit({id: id, objectId: objectId});
  }

  downloadAttachment(objectId: string){
    this.onDownloadDocment.emit(objectId);
  }
}
