import { LocationListViewModel, LocationTypeEnum, LocationsViewModel } from "@hrra/common-models";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { LocationService } from "../services/location.service";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";

export interface LocationPickerState{
    loading: boolean;
    locations: LocationsViewModel[];
    searchValue: {[parenLocationId: string]: string};

    locationLevelCount: number;

    selectedParentIds: string[];
    selectedLocationIds: {[parenLocationId: string]: string};
    hiddenLocations: {[parenLocationId: string]: boolean};
}

export const initialState: LocationPickerState = {
    loading: false,
    locations: [],
    searchValue: {},

    locationLevelCount: 0,

    selectedParentIds: [],
    selectedLocationIds: {},
    hiddenLocations: {},
}

@Injectable()
export class LocationPickerStore extends ComponentStore<LocationPickerState>{

    constructor(private locationService: LocationService){
        super(initialState)
    }

    loading$ = this.select(s => s.loading);
    parentIds$ = this.select(state => state.selectedParentIds);
    locationLevelCount$ = this.select(state => state.locationLevelCount);
    selectedLocations$ = this.select(state => state.selectedLocationIds);
    stateLocations$ = this.select(state => state.locations);
    // dropdownChanges$ = this.select(
    //     this.loading$,
    //     this.stateLocations$,
    //     (loading, locations) => {
    //         return {
    //             loading: loading,
    //             locations: locations
    //         }
    //     }
    // );

    levels$ = this.select(
        this.parentIds$,
        this.locationLevelCount$,

        (parentIds, locationLevelCount)=> {
            const result: {level: number; parentId: string}[] = [];

            for(let i = 0; i < locationLevelCount; i++){
                const level: {level: number; parentId: string} = {level: 0, parentId: ''};
                level.level = i;

                if(i < parentIds.length){
                    level.parentId = parentIds[i];
                }

                result.push(level);
            }

            return result;
        });


    locationsMap$ = this.select(state => {
        const groupedLocations = state.locations;
        const searchValue = state.searchValue;

        const result: { [key: string]: LocationListViewModel[] } = {};

        for(let i = 0; i < groupedLocations.length; i++) {
            const key = groupedLocations[i].parentLocationId;
            if(searchValue[key] != null){
                result[key] = groupedLocations[i].locations.filter(c => c.name.includes(searchValue[key]));
            }
            else{
                result[key] = groupedLocations[i].locations;
            }
        }

        return result;
    });

    selectAbroadLocationIndex$ = this.select(
        this.state$,
        this.locationsMap$,
        this.levels$,
        (state, locationsMap, levels) => {

        let selectedLocations = state.selectedLocationIds;
        let selectedLocationParentIds = Object.keys(selectedLocations);

        let selectedLocationLevels = levels.filter(e => selectedLocationParentIds.includes(e.parentId)).sort((a, b) => a.level - b.level);

        var result: number = -1;

        if(Object.keys(locationsMap).length > 0){
            for(let item of selectedLocationLevels){
                let selectedLocation = locationsMap[item.parentId]?.find(c => c.locationId == selectedLocations[item.parentId]);
    
                if(selectedLocation?.type == LocationTypeEnum.Abroad){
                    result = item.level;
                    break;
                }
            }
        }

        return result;
    });

    selectedLocationsCount$ = this.select(state => {
        let selectedLocations = state.selectedLocationIds;
        let selectedLocationsCount = Object.keys(selectedLocations).length;

        return selectedLocationsCount;
    })

    wildCardSelected$ = this.select(
        this.state$,
        this.locationsMap$,
        this.levels$,
        this.loading$,
        (state, locations, levels, loading) => {
            
            let wildcardSelected = false;

            let selectedLocations = state.selectedLocationIds;
            let selectedLocationIds = Object.keys(selectedLocations);
            let selectedLocationsCount = selectedLocationIds.length;

            let selectedLocationLevels = levels.filter(e => selectedLocationIds.includes(e.parentId)).sort((a, b) => a.level - b.level);

            if(selectedLocationsCount > 0 && locations !== undefined){
                let lastSelectedParentLocationId = selectedLocationLevels[selectedLocationsCount-1]?.parentId;
                let lastSelectedLocationId = selectedLocations[lastSelectedParentLocationId];

                let lastSelectedLocation = locations[lastSelectedParentLocationId]?.find(e => e.locationId == lastSelectedLocationId);

                wildcardSelected = lastSelectedLocation?.type === LocationTypeEnum.Wildcard;
            }

            return { wildcardSelected: wildcardSelected, loading: loading };
        }
    );
    
    selectFirstDisabledLocationIndex$ = this.select(
        this.state$,
        this.locationsMap$,
        this.levels$,

        (state, locationsMap, levels) => {

            let selectedLocationIds = state.selectedLocationIds;
            let selectedLocationIdsKeys = Object.keys(selectedLocationIds);

            let lastSelectedLocationParentId = levels[selectedLocationIdsKeys.length - 1]?.parentId;
            let lastSelectedLocationId = selectedLocationIds[lastSelectedLocationParentId];

            let lastLocationType = locationsMap[lastSelectedLocationParentId]?.find(e => e.locationId == lastSelectedLocationId)?.type;

            let result: number = 1;

            if(lastLocationType == LocationTypeEnum.Other || lastLocationType == LocationTypeEnum.Wildcard || lastLocationType == LocationTypeEnum.Undefined) {
                result = selectedLocationIdsKeys.length
            }
            else {
                result = selectedLocationIdsKeys.length + 1;
            }
            return result;
        });




    //updates

    readonly updateLoading = this.updater((state, loading: boolean) => {
        return {
            ... state,
            loading: loading
        }
    });

    readonly updateLocations = this.updater((state, locations: LocationsViewModel[])=> {

        let selectedParentIds = state.selectedParentIds;

        if(selectedParentIds.length == 0){
            selectedParentIds = [locations[0].parentLocationId];
        }

        return {
            ...state,
            loading: false,
            selectedParentIds: selectedParentIds,
            locations: locations
        }
    });

    readonly setParentLocationIds = this.updater((state, data: { parentIds: string[], locationIds: string[] })=> {

        let selectedLocations: { [parentId: string]: string } = {};

        for(let i = 0; i < data.locationIds?.length; i++){
            selectedLocations[data.parentIds[i]] = data.locationIds[i];
        }

        return {
            ...state,
            selectedParentIds: data.parentIds,
            selectedLocationIds: selectedLocations
        }
    });

    readonly setLocationLevelCount = this.updater((state, count: number)=> {
        return {
            ...state,
            locationLevelCount: count
        }
    });

    readonly updateFilter = this.updater((state, data: { query: string, parentId: string })=> {

        let newSearchValue = { ...state.searchValue };

        newSearchValue[data.parentId] = data.query;

        return {
            ...state,
            searchValue: newSearchValue
        }
    });

    readonly onChangeValue = this.updater((state, data: { locationId: string, parentLocationId: string }) => {
        let newParentLocationIs: string[] = [];

        for(let item of state.selectedParentIds){
            if(item != data.parentLocationId){
                newParentLocationIs.push(item);
            }
            else{
                newParentLocationIs.push(item);
                if(data.locationId != undefined){
                    newParentLocationIs.push(data.locationId); //as new parent
                }
                break;
            }
        };

        let selectedLocationIds = state.selectedLocationIds;
        let keys = Object.keys(selectedLocationIds);
        let newSelectedLocationIds: { [parenLocationId: string]: string } = {};
        if (keys.length == 0){
            newSelectedLocationIds[data.parentLocationId] = data.locationId;
        }
        for(let key of keys){
            if(key != data.parentLocationId){
                newSelectedLocationIds[key] = selectedLocationIds[key];
            }
            else {
                break;
            }
        }

        newSelectedLocationIds[data.parentLocationId] = data.locationId;

        this.loadLocations(newParentLocationIs);
        
        return {
            ...state,
            selectedParentIds: newParentLocationIs,
            selectedLocationIds: newSelectedLocationIds
        }
    });

    //effects

    readonly loadLocations = this.effect((locationParentIds$: Observable<string[]>)=> {
        return locationParentIds$.pipe(
            switchMap((locationParentIds: string[]) => {
                this.updateLoading(true);
                return this.locationService.getLocations(locationParentIds).pipe(
                    tapResponse({
                        next: (response) => {
                            this.updateLocations(response.data);
                        },
                        error: error => {
                            this.updateLoading(false);
                        }
                    })

                );
            }),

        );
    });

}
