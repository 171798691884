import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ButtonTypeEnum } from '@hrra/ui';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/root-store/state';
import { languageHasAttachment, selectFileName, selectJobseekerLanguage, selectWorldLanguageList, selectLanguageProficiencyLevels, isFileUploading, selectIsJobseekerProfileUpdating, selectSelectedFileText, selectIsFileSizeValid, selectJobseekerModerationLastChangeDate } from 'src/app/root-store/jobseeker-store/jobseeker-profile/selectors';
import { Observable, Subscription } from 'rxjs';
import { FileUploadResultViewModel, JobseekerLanguageModel } from '../shared/services/jobseeker.service';
import { IconButtonTypeEnum } from '@hrra/ui';
import { downloadLanguageAttachment, editJobseekerLanguage, uploadTempFile } from 'src/app/root-store/jobseeker-store/jobseeker-profile/actions';
import { ButtonTypeAttributeEnum } from '@hrra/ui';
import { SystemConfig } from 'src/app/shared/models/system-config-model';
import { selectSystemConfigs } from 'src/app/root-store/selectors';
import { JobseekerFileService } from '../shared/services/jobseeker-file.service';
import { NavigationEnd, Router } from '@angular/router';
import { BasicOptionItem } from '@hrra/common-models';

@Component({
  selector: 'app-jobseeker-language-edit',
  templateUrl: './jobseeker-language-edit.component.html',
  styleUrls: ['./jobseeker-language-edit.component.scss']
})
export class JobseekerLanguageEditComponent implements OnInit {
  navigationSubs: Subscription;

  editForm: UntypedFormGroup;

  jobseekerLanguage$: Observable<JobseekerLanguageModel>;
  languageList$: Observable<BasicOptionItem[]>;
  languageProficiencyLevelList$: Observable<BasicOptionItem[]>;
  languageHasAttachment$: Observable<Boolean>;
  selectedFile$: Observable<FileUploadResultViewModel>;
  isFileUploading$: Observable<boolean>;
  isUpdating$: Observable<boolean>;
  selectedFileText$: Observable<String>;
  isFileSizeValid$: Observable<boolean>;
  systemConfigs$: Observable<SystemConfig>;
  moderationLastChangeDate$: Observable<Date>;

  
  public title: string;
  public buttonType: any;
  public iconButtonType: any;
  public isCreateForm: boolean;
  public buttonAttributeType: any;

  constructor(private store: Store<AppState>, @Inject(MAT_DIALOG_DATA) public data, private router: Router, public dialogRef: MatDialogRef<JobseekerLanguageEditComponent>, private fileService: JobseekerFileService) {
    this.buttonType = ButtonTypeEnum;
    this.isCreateForm = this.data.isCreateForm;
    
    this.editForm = new UntypedFormGroup({
      worldLanguageId: new UntypedFormControl(null, [Validators.required]),
      languageProficiencyLevelId: new UntypedFormControl(null, [Validators.required]),
    }),

    this.iconButtonType = IconButtonTypeEnum;
    this.buttonAttributeType = ButtonTypeAttributeEnum;

    this.navigationSubs = this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.dialogRef.close();
      }
    });
  }

  ngOnInit(): void {
    this.title = this.data.title;

    this.languageList$ = this.store.pipe(select(selectWorldLanguageList));
    this.moderationLastChangeDate$ = this.store.pipe(select(selectJobseekerModerationLastChangeDate));
    this.jobseekerLanguage$ = this.store.pipe(select(selectJobseekerLanguage(this.data.languageId)));
    this.languageProficiencyLevelList$ = this.store.pipe(select(selectLanguageProficiencyLevels));
    this.languageHasAttachment$ = this.store.pipe(select(languageHasAttachment(this.data.languageId)));
    this.selectedFile$ = this.store.pipe(select(selectFileName));
    this.isFileUploading$ = this.store.pipe(select(isFileUploading));
    this.isUpdating$ = this.store.pipe(select(selectIsJobseekerProfileUpdating));
    this.selectedFileText$ = this.store.pipe(select(selectSelectedFileText));
    this.isFileSizeValid$ = this.store.pipe(select(selectIsFileSizeValid));
    this.systemConfigs$ = this.store.pipe(select(selectSystemConfigs));
  }

  onSave(filename: string, isFileSizeValid: boolean, objectId: string, moderationLastChangeDate:Date){
    if(this.editForm.valid && isFileSizeValid){
      let updateData = <JobseekerLanguageModel>{
        jobseekerLanguageId: this.data.languageId,
        worldLanguageId: this.editForm.get('worldLanguageId').value,
        languageProficiencyLevelId: this.editForm.get('languageProficiencyLevelId').value,
        fileName: filename,
        objectId: objectId
      }
      
      this.store.dispatch(editJobseekerLanguage({payload: {jobseekerLanguageModel:updateData, moderationLastChangeDate: moderationLastChangeDate}}));
    }
  }

  onFileSelect(event, fileLimit){
    this.fileService.uploadTempFile(event, fileLimit);
  }

  downloadAttachment(objectId: string){
    this.store.dispatch(downloadLanguageAttachment({payload: {jobseekerLanguageObjectId: objectId}}));
  }

  ngOnDestroy() {
    this.navigationSubs.unsubscribe();
  }
}
