<div class="content">
    <div class="content--right">
        <div class="educational-level">{{education.educationLevel.name}}</div>
        <div class="dates">
            <span>{{education.dateFromMonth == null ? education.dateFromYear : (education.educationDateFrom | date:'y MMMM':timeZone:currentLang)}} - </span>
            <span>{{education.dateToMonth == null ? education.dateToYear : (education.educationDateTo | date:'y MMMM':timeZone:currentLang)}}</span>
        </div>
        
    
        <div class="buttons">
            <app-icon-button *ngIf="(userType === type.Jobseeker || userType === type.Internal)" (click)="onEdit(education.jobseekerEducationId)" [iconButtonType]="iconButtonType.edit" class="buttons__edit"></app-icon-button>
            <app-icon-button *ngIf="userType === type.Jobseeker || userType === type.Internal" (click)="onDelete(education.jobseekerEducationId, education.objectId)" [iconButtonType]="iconButtonType.delete" class="buttons__delete"></app-icon-button>
        </div>
    </div>

    <app-icon *ngIf="education.isInModeration" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon mod-icon--desktop"></app-icon>
    <div class="institution">
        <app-icon *ngIf="education.isInModeration" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon mod-icon--mobile"></app-icon>
        <span>{{education.institution}}</span>
    </div>

    <div class="education">
        <div *ngIf="education.hasProgram" class="education___program">{{education.educationalProgramName}}</div>
        <div *ngIf="education.hasSpecialization" class="education__specialization">{{education.specialization}}</div>
        <div class="education__description">{{education.description}}</div>
    </div>

    <div *ngIf="education.attachment != null" class="attachment" [ngClass]="{'attachment--filled' : education.attachment != null}">
        <app-advanced-button (click)="downloadAttachment(education.objectId)" [iconType]="iconButtonType.attachment" [text]="'label.Attachment' | translate" [type]="'blue'" class="add-button"></app-advanced-button>
    </div>
</div>
