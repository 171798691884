<ng-container *ngIf="{
    displayHeadCategories: displayHeadCategories$ | async,
    headCategoriesExpandedMap: headCategoriesExpandedMap$ | async,
    categoriesSelectedMap: categoriesSelectedMap$ | async,
    allCategoriesMap: allCategoriesMap$ | async,
    selectedCategoriesDisplayTags: selectedCategoriesDisplayTags$ | async,
    maxCategoriesReached: maxCategoriesReached$ | async,
    systemConfigs: systemConfigs$ | async
} as data">
    <div>
        <h2 mat-dialog-title>{{'label.ChoosePositionCategories' | translate}}</h2>
    </div>




    <!-- <mat-dialog-content> -->
    <div class="top-container">

        <div class="categories-selection">
            <div class="query-container">
                <input class="query-container__input" (input)="onSearch($event.target.value)" type="text"
                    placeholder="({{ 'label.Search' | translate }})" />
            </div>
            <div class="categories-container">
                <div class="head-category" *ngFor="let head of data.displayHeadCategories">
                    <div style="display: flex; cursor: pointer;" (click)="onToggleExpandHeadCategory(head)">
                        <div style="padding-bottom: 0.2rem;">
                            <svg-icon src="/assets/icons/chevron-down.svg"
                                [svgStyle]="{ 'width.rem':0.81, 'stroke': 'black', 'transform': data.headCategoriesExpandedMap[head.categoryId] ? 'none' : 'rotate(180deg)' }">
                            </svg-icon>
                        </div>
                        <div style="padding-left: 0.7rem;">{{head.name}}</div>
                    </div>

                    <div style="padding-top: 1.5rem; padding-left: 1.7rem;"
                        [ngStyle]="{'display': data.headCategoriesExpandedMap[head.categoryId]  ? 'block' : 'none'}">
                        <div *ngFor="let categoryId of head.childCategoryIds">
                            <mat-checkbox [disabled]="data.maxCategoriesReached && !data.categoriesSelectedMap[categoryId]" (change)="onToggleCategory(categoryId, $event)"
                                [ngModel]="data.categoriesSelectedMap[categoryId]"
                                [ngModelOptions]="{standalone: true}">{{data.allCategoriesMap[categoryId].name}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="category-chips">
            <div class="chips-container">
                <div class="category-tag" *ngFor="let category of data.selectedCategoriesDisplayTags">

                    <div (click)="onRemoveCategory(category.categoryId)" class="category-tag__item">

                        {{ category.name }}

                        <svg-icon src="/assets/icons/remove.svg" class="remove-icon"></svg-icon>

                    </div>

                </div>
            </div>
            <div class="warning-message">
                <div>{{ 'message.PositionMaxCategoriesWarning' | translate: {max: data.systemConfigs.jobseeker.workExperienceMaxCategories} }}</div>
            </div>
        </div>
    </div>

    <!-- </mat-dialog-content> -->





    <mat-dialog-actions>
        <div class="buttons">
            <app-button [ngClass]="{'disabled': false}"
                (click)="onSave(data.selectedCategoriesDisplayTags)" [text]="'label.Save' | translate"
                [type]="buttonType.coloured" [typeAttribute]="buttonAttributeType.submit" class="button-save"></app-button>
            <app-button [text]="'label.Cancel' | translate" [type]="buttonType.uncoloured" [typeAttribute]="buttonAttributeType.button" (click)="onCancel()">
            </app-button>
        </div>
    </mat-dialog-actions>

</ng-container>