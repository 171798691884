<div class="container">
    <app-icon *ngIf="documentIsInMod" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>

    <div class="document">
        <div (click)="downloadAttachment(document.objectId)" class="document__type">{{documentTypeMap[document.type]?.name}}</div>
        <div class="document__upload-date">{{document.createDate | date:'shortDate'}}</div>
    </div>
    <div class="description">{{document.label}}</div>
    <app-icon-button *ngIf="userType === type.Jobseeker || userType === type.Internal" (click)="onDelete(document.jobseekerDocumentId, document.objectId)" [iconButtonType]="iconButtonType.delete" class="button-delete"></app-icon-button>
</div>
