import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ButtonTypeAttributeEnum } from '@hrra/ui';
import { ButtonTypeEnum } from '@hrra/ui';
import { filterCategories, loadCategories, removeCategory, resetCategoryFilter, selectCategory, toggleExpandHeadCategory, unselectCategory } from 'src/app/root-store/jobseeker-store/category-picker/actions';
import { selectCategoriesSelectedMap, selectDisplayHeadCategories, selectDisplaySelectedCategoriesTags, selectHeadCategoriesExpandedMap, selectMaxCategoriesReached } from 'src/app/root-store/jobseeker-store/category-picker/selectors';
import { selectCategoriesMap, selectSystemConfigs } from 'src/app/root-store/selectors';
import { AppState } from 'src/app/root-store/state';
import { Dictionary } from '@hrra/core';
import { SystemConfig } from 'src/app/shared/models/system-config-model';
import { OccupationCategory } from '@hrra/common-models';

@Component({
  selector: 'app-category-picker',
  templateUrl: './category-picker.component.html',
  styleUrls: ['./category-picker.component.scss']
})
export class CategoryPickerComponent implements OnInit {
  navigationSubs: Subscription;

  public displayHeadCategories$: Observable<OccupationCategory[]>;
  public headCategoriesExpandedMap$: Observable<Dictionary<boolean>>;
  public categoriesSelectedMap$: Observable<Dictionary<boolean>>;
  public allCategoriesMap$: Observable<Dictionary<OccupationCategory>>;
  public selectedCategoriesDisplayTags$: Observable<OccupationCategory[]>;
  public maxCategoriesReached$: Observable<boolean>;
  public systemConfigs$: Observable<SystemConfig>;


  public buttonType: any;
  public buttonAttributeType: any;

  constructor(public dialogRef: MatDialogRef<CategoryPickerComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private store: Store<AppState>, private router: Router) {
    this.buttonType = ButtonTypeEnum;
    this.buttonAttributeType = ButtonTypeAttributeEnum;

    this.navigationSubs = this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.dialogRef.close();
      }
    });
  }

  public onToggleExpandHeadCategory(head: OccupationCategory){
    this.store.dispatch(toggleExpandHeadCategory({ payload: { categoryId: head.categoryId }}));
  }

  onToggleCategory(categoryId: number, event: MatCheckboxChange){
    if(event.checked){
      this.store.dispatch(selectCategory({ payload: { categoryId: categoryId }}));
    }
    else {
      this.store.dispatch(unselectCategory({ payload: { categoryId: categoryId }}));
    }
  }

  onRemoveCategory(categoryId: number){
    this.store.dispatch(removeCategory({ payload: { categoryId: categoryId }}));
  }

  onSearch(query: string){
    this.store.dispatch(filterCategories({ payload: { query: query }}));
  }

  onSave(categories: OccupationCategory[]){
    let categoryIds = categories.map(c => c.categoryId);
    this.dialogRef.close(categoryIds);
  }

  onCancel(){
    this.dialogRef.close(null);
  }

  ngOnInit(): void {

    this.displayHeadCategories$ = this.store.pipe(select(selectDisplayHeadCategories));
    this.headCategoriesExpandedMap$ = this.store.pipe(select(selectHeadCategoriesExpandedMap));
    this.categoriesSelectedMap$ = this.store.pipe(select(selectCategoriesSelectedMap));
    this.allCategoriesMap$ = this.store.pipe(select(selectCategoriesMap));
    this.selectedCategoriesDisplayTags$ = this.store.pipe(select(selectDisplaySelectedCategoriesTags));
    this.maxCategoriesReached$ = this.store.pipe(select(selectMaxCategoriesReached));
    this.systemConfigs$ = this.store.pipe(select(selectSystemConfigs));

    setTimeout(() => {
      this.store.dispatch(resetCategoryFilter());
      this.store.dispatch(loadCategories({ payload: { categoryIds: this.data.categoryIds }}));
    }, 0);
  }

  ngOnDestroy() {
    this.navigationSubs.unsubscribe();
  }
}