import { Directive, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[appConnectForm]'
})
export class ConnectFormDirective {

  loaded: boolean;

  @Input('appConnectForm')
  set data(val: FormReloadData) {
    if (val && (!this.loaded || val.forceReload)) {
      this.formGroupDirective.form.patchValue(val);
      this.formGroupDirective.form.markAsPristine();
      this.loaded = true;
    }
  }

  constructor(private formGroupDirective: FormGroupDirective) { }

}

export interface FormReloadData{
  forceReload: boolean;
}
