import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobseekerEducationViewModel, UserTypeEnum } from '@hrra/common-models';
import { TranslateService } from '@ngx-translate/core';
import { IconButtonTypeEnum } from '../../../../ui/src/lib/shared/models/enums/icon-button-type-enum';

@Component({
  selector: 'hrra-jobseeker-education',
  templateUrl: './jobseeker-education.component.html',
  styleUrls: ['./jobseeker-education.component.scss']
})
export class JobseekerEducationComponent implements OnInit {

  public iconButtonType: any;
  public type: any;

  @Input() education: JobseekerEducationViewModel;
  @Input() timeZone: string;
  @Input() userType: UserTypeEnum;
  @Input() currentLang: string;

  @Output() onEditEducation: EventEmitter<number> = new EventEmitter<number>();
  @Output() onDeletEducation: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownloadEducationAttachment: EventEmitter<string> = new EventEmitter<string>();

  constructor(private translate: TranslateService,
              ) { this.iconButtonType = IconButtonTypeEnum, this.type = UserTypeEnum }

  ngOnInit(): void {
  }

  public onEdit(id: number){
    this.onEditEducation.emit(id);
  }

  public onDelete(id: number, objectId:string){
    this.onDeletEducation.emit({id:id,objectId:objectId});
  }

  downloadAttachment(objectId: string){
    this.onDownloadEducationAttachment.emit(objectId);
  }
}
