import { Component, OnInit, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/root-store/state';
import { JobseekerEducationModel, FileUploadResultViewModel } from '../shared/services/jobseeker.service';
import { Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { Patterns } from '@hrra/core';
import { selectEducationLevels, selectEducationalInstitutions, selectEducation, selectMonthList, selectInstitutionSelectMode, 
         selectEducationLevel, selecIsProgramAvailable, selectIsSpecializationAvailable, selectProgramList, selectPrgogramSelectMode, 
  selectIsProgramListAvailable, searchedInstitutionList, searchedProgramList, selectHasAttachment, selectFileName, selectEducationalInstitutionMap, selectProgramMap, selectIsInstitutionListAvailable, isFileUploading, selectIsJobseekerProfileUpdating, selectSelectedFileText, selectIsFileSizeValid, 
  selectJobseekerModerationLastChangeDate} from 'src/app/root-store/jobseeker-store/jobseeker-profile/selectors';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { editJobseekerEducation, programSelectMode, selectJobseekerEducationLevel, institutionSelectMode, selectJobseekerEdcuationalInstitution, downloadEducationAttachment } from 'src/app/root-store/jobseeker-store/jobseeker-profile/actions';
import { ButtonTypeEnum, IconButtonTypeEnum } from '@hrra/ui';
import { ButtonTypeAttributeEnum } from '@hrra/ui';
import { SystemConfig } from 'src/app/shared/models/system-config-model';
import { selectSystemConfigs } from 'src/app/root-store/selectors';
import { JobseekerFileService } from '../shared/services/jobseeker-file.service';
import { NavigationEnd, Router } from '@angular/router';
import { EducationalInstitution, EducationLevelEnum, BasicOptionItem, EducationalProgram } from '@hrra/common-models';

@Component({
  selector: 'app-jobseeker-education-edit',
  templateUrl: './jobseeker-education-edit.component.html',
  styleUrls: ['./jobseeker-education-edit.component.scss']
})
export class JobseekerEducationEditComponent implements OnInit {

  editForm: UntypedFormGroup;

  educationLevelList$: Observable<BasicOptionItem[]>;
  educationalInstitutionList$: Observable<EducationalInstitution[]>;
  monthList$: Observable<BasicOptionItem[]>;

  jobseekerEducation$: Observable<JobseekerEducationModel>;

  isInstitutionListAvailable$: Observable<Boolean>;
  institutionSelectModeOn$: Observable<boolean>;

  isProgramListAvailable$: Observable<boolean>;
  programSelectModOn$: Observable<boolean>;
  moderationLastChangeDate$: Observable<Date>;

  
  educationLevel$: Observable<number>;
  isProgramAvailable$: Observable<boolean>;
  isSpecializationAvailable$: Observable<boolean>;
  programList$: Observable<EducationalProgram[]>;
  hasAttachment$: Observable<Boolean>;
  selectedFile$: Observable<FileUploadResultViewModel>;

  educationalInstitutionMap$: Observable<any>;
  programMap$: Observable<any>;

  filteredInstitutionOptions$: Observable<EducationalInstitution[]>;
  filteredProgramOptions$: Observable<EducationalProgram[]>;

  isFileUploading$: Observable<boolean>;
  isUpdating$: Observable<boolean>;
  selectedFileText$: Observable<String>;
  isFileSizeValid$: Observable<boolean>;
  systemConfigs$: Observable<SystemConfig>;
  
  public title: string;
  public buttonType: any;
  public buttonAttributeType: any;
  public EducationLevelType: any;
  public iconButtonType: any;
  public isYearValid: boolean = true;

  public institutionNameMap :  {[index: number]: string}
  public institutionNameNotInListLabelMap: {[index: number]: string};

  
  constructor(private fb: UntypedFormBuilder, private store: Store<AppState>, 
             @Inject(MAT_DIALOG_DATA) public data, 
             public dialogRef: MatDialogRef<JobseekerEducationEditComponent>,
             private router: Router, 
             private fileService: JobseekerFileService) {

    this.editForm = this.fb.group({
      educationLevelId: new UntypedFormControl(null, [Validators.required]),
      educationalInstitutionId: new UntypedFormControl(null),
      dateFromMonth: new UntypedFormControl(null),
      dateFromYear: new UntypedFormControl('', [Validators.required, Validators.pattern(Patterns.year)]),
      dateToMonth: new UntypedFormControl(null),
      dateToYear: new UntypedFormControl('', [Validators.required, Validators.pattern(Patterns.year)]),
      educationalProgramId: new UntypedFormControl(null),
      educationalProgramName: new UntypedFormControl(''),
      institution: new UntypedFormControl(''),
      specialization: new UntypedFormControl(''),
      description: new UntypedFormControl('')
    }, { validator: this.customValidator},);

    this.iconButtonType = IconButtonTypeEnum;
    this.buttonType = ButtonTypeEnum;
    this.buttonAttributeType = ButtonTypeAttributeEnum;

    this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.dialogRef.close();
      }
    });
  }

  customValidator(group : UntypedFormGroup) : {[s:string ]: boolean}[] {
    if (group) {
      let result: {[s:string ]: boolean}[] = <any>{};

      let levelId = group.controls['educationLevelId'].value;

      let dateToYear = group.controls['dateToYear'].value;
      let dateToMonth = group.controls['dateToMonth'].value;

      let dateFromYear = group.controls['dateFromYear'].value;
      let dateFromMonth = group.controls['dateFromMonth'].value;

      dateFromMonth = dateFromMonth || 1;
      dateToMonth = dateToMonth || 12;
      var isDateValid = dateFromYear < dateToYear || (dateToYear == dateFromYear && dateFromMonth <= dateToMonth);

      if(!isDateValid) {
        result['year'] = true;
      }
      if(group.controls['educationalInstitutionId'].value == null && group.controls['institution'].value == null) {
        result['institution'] = true;
      }
      if(group.controls['educationalProgramId'].value == null && group.controls['educationalProgramName'].value == null && 
        (levelId == EducationLevelEnum.Bachelor || levelId == EducationLevelEnum.Master || levelId == EducationLevelEnum.Doctor || levelId == EducationLevelEnum.College)) {
          result['program'] = true;
      }
      return result;
    }
    return null;
  }

  ngOnInit(): void {
    this.title = this.data.title;
    
    this.educationLevelList$ = this.store.pipe(select(selectEducationLevels));
    this.educationalInstitutionList$ = this.store.pipe(select(selectEducationalInstitutions));
    this.monthList$ = this.store.pipe(select(selectMonthList));
    this.jobseekerEducation$ = this.store.pipe(select(selectEducation));

    this.isInstitutionListAvailable$ = this.store.pipe(select(selectIsInstitutionListAvailable));
    this.isProgramListAvailable$ = this.store.pipe(select(selectIsProgramListAvailable));

    this.institutionSelectModeOn$ = this.store.pipe(select(selectInstitutionSelectMode));
    this.programSelectModOn$ = this.store.pipe(select(selectPrgogramSelectMode));

    this.educationLevel$ = this.store.pipe(select(selectEducationLevel));
    this.isProgramAvailable$ = this.store.pipe(select(selecIsProgramAvailable));
    this.isSpecializationAvailable$ = this.store.pipe(select(selectIsSpecializationAvailable));
    this.programList$ = this.store.pipe(select(selectProgramList));

    this.hasAttachment$ = this.store.pipe(select(selectHasAttachment));
    this.moderationLastChangeDate$ = this.store.pipe(select(selectJobseekerModerationLastChangeDate))


    this.filteredInstitutionOptions$ = this.educationalInstitutionList$;
    this.filteredProgramOptions$ = this.programList$;

    this.selectedFile$ = this.store.pipe(select(selectFileName));
    this.educationalInstitutionMap$ = this.store.pipe(select(selectEducationalInstitutionMap));
    this.programMap$ = this.store.pipe(select(selectProgramMap));

    this.isFileUploading$ = this.store.pipe(select(isFileUploading));
    this.selectedFileText$ = this.store.pipe(select(selectSelectedFileText));
    this.isFileSizeValid$ = this.store.pipe(select(selectIsFileSizeValid));
    this.systemConfigs$ = this.store.pipe(select(selectSystemConfigs));

    this.isUpdating$ = this.store.pipe(select(selectIsJobseekerProfileUpdating));
    
    this.institutionNameMap = {
      [EducationLevelEnum.HighSchool]: 'label.SchoolName',
      [EducationLevelEnum.IncompleteHighSchool]: 'label.SchoolName',
      [EducationLevelEnum.Bachelor]: 'label.UniversityName',
      [EducationLevelEnum.Doctor]: 'label.UniversityName',
      [EducationLevelEnum.Master]: 'label.UniversityName',
      [EducationLevelEnum.College]: 'label.NameOfInstitution',
      [EducationLevelEnum.Other]: 'label.InstitutionName',
    }

    this.institutionNameNotInListLabelMap = {
      [EducationLevelEnum.Bachelor]: 'label.UniversityNotInList',
      [EducationLevelEnum.Doctor]: 'label.UniversityNotInList',
      [EducationLevelEnum.Master]: 'label.UniversityNotInList',
      [EducationLevelEnum.College]: 'label.NameOfInstitutionNotInList',
    }

    this.EducationLevelType = EducationLevelEnum;
  }


  onInstitutionSearchChange(searchValue: string): void {
    this.filteredInstitutionOptions$ = this.store.pipe(select(searchedInstitutionList(searchValue)));
  }

  onProgramSearchChange(searchValue: string): void {  
    this.filteredProgramOptions$ = this.store.pipe(select(searchedProgramList(searchValue)));
  }

  onSave(id: number, instituionList: any, programList: any, filename: FileUploadResultViewModel, isFileSizeValid: boolean, objectId:string, moderationLastChangeDate: Date){
    if(this.editForm.valid && isFileSizeValid){
      let institution = this.editForm.get('institution').value;
      let institutionId = this.editForm.get('educationalInstitutionId').value;
      if(institutionId != null){
        institution = instituionList[institutionId].name;
      }

      let programName = this.editForm.get('educationalProgramName').value;
      let programId = this.editForm.get('educationalProgramId').value;
      if(programId != null){
        programName = programList[programId].name;
      }

      let levelId =  this.editForm.get('educationLevelId').value;
      let specialization = this.editForm.get('specialization').value;
      if(levelId == EducationLevelEnum.IncompleteHighSchool || levelId == EducationLevelEnum.HighSchool){
        specialization = null;
      }

      let updateData = <JobseekerEducationModel>{
        jobseekerEducationId: id,
        educationLevelId: this.editForm.get('educationLevelId').value,
        educationalInstitutionId: institutionId,
        educationalProgramId: programId,
        institution: institution,
        educationalProgramName: programName,
        specialization: specialization,
        dateFromYear: this.editForm.get('dateFromYear').value,
        dateFromMonth: this.editForm.get('dateFromMonth').value,
        dateToMonth: this.editForm.get('dateToMonth').value,
        dateToYear: this.editForm.get('dateToYear').value,
        description: this.editForm.get('description').value,
        fileName: filename?.filename,
        objectId: objectId,
      }
      
      this.store.dispatch(editJobseekerEducation({payload: {jobseekerEducationalModel:updateData, moderationLastChangeDate: moderationLastChangeDate}}));
    }
  }


  displayInstitution(institutionList: EducationalInstitution[], institution: number) {
    if(institution != null){
      return institutionList.find(e => e.educationalInstitutionId == institution)?.displayName;
    }
  }

  displayProgram(programList: EducationalProgram[], program: number) {
    if(program != null){
      return programList?.find(e => e.educationalProgramId == program)?.displayName;
    }
  }

  onLevelChanage(event){
    let educationLevelId: number = this.editForm.get('educationLevelId').value;
    this.editForm.get('educationalProgramName').reset();
    this.editForm.get('educationalProgramId').reset();
    this.store.dispatch(selectJobseekerEducationLevel({payload: {levelId: educationLevelId}}));
  }

  onInstitutionSelectMode(){
    this.editForm.get('institution').reset();
    this.editForm.get('educationalInstitutionId').reset();
    this.store.dispatch(institutionSelectMode());
  }

  onProgramSelectMode(){
    this.editForm.get('educationalProgramName').reset();
    this.editForm.get('educationalProgramId').reset();
    this.store.dispatch(programSelectMode());
  }

  onChangaeInstitution(event){
    let institutioId: number = this.editForm.get('educationalInstitutionId').value;
    this.store.dispatch(selectJobseekerEdcuationalInstitution({payload: {institutionId: institutioId}}));
  }

  onChangeYear(event){
    let fromYear = this.editForm.get('dateFromYear').value;
    let toYear = this.editForm.get('dateToYear').value;

    this.isYearValid = Number(fromYear) < Number(toYear);
  }

  onFileSelect(event, fileLimit){
    this.fileService.uploadTempFile(event, fileLimit);
  }

  downloadAttachment(objectId: string){
    this.store.dispatch(downloadEducationAttachment({payload: {jobseekerEducationObjectId: objectId}}));
  }
}
