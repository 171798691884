import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, act } from '@ngrx/effects';
import { JobseekerService } from 'src/app/jobseeker/shared/services/jobseeker.service';
import * as JobseekerProfileForAdminActions from './actions';
import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FileDownloadService } from '@hrra/core';

@Injectable()
export class JobseekerProfileForAdminEffects{

    loadJobseekerProfileForAdmin$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.loadJobseekerProfileForAdmin),
        mergeMap((data) => this.jobseekerSerivce.getJobseekerForAdmin(data.payload.jobseekerId)
        .pipe(
            map(response => JobseekerProfileForAdminActions.loadJobseekerProfileForAdminSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.loadJobseekerProfileForAdminFail({payload: response.error})))
        ))
    ));

    //delete
    deleteJobseekerDocumentForAdmin$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.deleteJobseekerDocument),
        mergeMap(a => this.jobseekerSerivce.deleteJobseekerDocumentForAdmin(a.payload.documentId, a.payload.objectId, a.payload.jobseekerId, a.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileForAdminActions.deleteJobseekerDocumentSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.deleteJobseekerDocumentFail({payload: response.error})))
        ))
    ));


    deleteJobseekerLanguage$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.deleteJobseekerLanguage),
        mergeMap(a => this.jobseekerSerivce.deleteJobseekerLanguageForAdmin(a.payload.languageId, a.payload.objectId, a.payload.jobseekerId, a.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileForAdminActions.deleteJobseekerLanguageSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.deleteJobseekerLanguageFail({payload: response.error})))
        ))
    ));


    deleteJobseekerEducation$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.deleteJobseekerEducationForAdmin),
        mergeMap(a => this.jobseekerSerivce.deleteJobseekerEducationnForAdmin(a.payload.educationId, a.payload.objectId, a.payload.jobseekerId, a.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileForAdminActions.deleteJobseekerEducationForAdminSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.deleteJobseekerEducationForAdminFail({payload: response.error})))
        ))
    ));


    deleteJobseekerWorkExperience$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.deleteJobseekerWorkExperienceForAdmin),
        mergeMap(a => this.jobseekerSerivce.deleteJobseekerWorkExperienceForAdmin(a.payload.experienceId, a.payload.objectId, a.payload.jobseekerId, a.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileForAdminActions.deleteJobseekerWorkExperienceForAdminSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.deleteJobseekerWorkExperienceForAdminFail({payload: response.error})))
        ))
    ));

    deleteJobseekerPhoto$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.deleteJobseekerPhotoFromAdmin),
        mergeMap(a => this.jobseekerSerivce.deleteJobseekerPhotoFromAdmin(a.payload.jobseekerId, a.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileForAdminActions.deleteJobseekerPhotoFromAdminSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.deleteJobseekerPhotoFromAdminFail({payload: response.error})))
        ))
    ));


    //downloads
    downloadEducationAttachment$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.downloadEducationAttachmentForAdmin),
        mergeMap(action => this.jobseekerSerivce.downloadEducationAttachmentFromAdmin(action.payload.objectId, action.payload.jobseekerId)
        .pipe(
            map(response => response.data),
            tap(data => this.fileDownloadService.downloadFile(data.downloadUrl, data.friendlyName)),
            map(() => JobseekerProfileForAdminActions.downloadEducationAttachmentForAdminSuccess()),
            catchError(response => of(JobseekerProfileForAdminActions.downloadEducationAttachmentForAdminFail({payload: response.error})))
        ))
    ));

    downloadLanguageAttachment$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.downloadLanguageAttachmentForAdmin),
        mergeMap(action => this.jobseekerSerivce.downloadLanguageAttachmentForAdmin(action.payload.objectId, action.payload.jobseekerId)
        .pipe(
            map(response => response.data),
            tap(data => this.fileDownloadService.downloadFile(data.downloadUrl, data.friendlyName)),
            map(() => JobseekerProfileForAdminActions.downloadLanguageAttachmentForAdminSuccess()),
            catchError(response => of(JobseekerProfileForAdminActions.downloadLanguageAttachmentForAdminFail({payload: response.error})))
        ))
    ));

    downloadDocumentAttachment$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.downloadDocumentAttachmentFromAdmin),
        mergeMap(action => this.jobseekerSerivce.downloadDocumentAttachmentFromAdmin(action.payload.objectId, action.payload.jobseekerId)
        .pipe(
            map(response => response.data),
            tap(data => this.fileDownloadService.downloadFile(data.downloadUrl, data.friendlyName)),
            map(() => JobseekerProfileForAdminActions.downloadDocumentAttachmentFromAdminSuccess()),
            catchError(response => of(JobseekerProfileForAdminActions.downloadDocumentAttachmentFromAdminFail({payload: response.error})))
        ))
    ));

    downloadWorkExperienceAttachment$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.downloadWorkExperienceAttachmentForAdmin),
        mergeMap(action => this.jobseekerSerivce.downloadExperienceAttachmentFromAdmin(action.payload.objectId, action.payload.jobseekerId)
        .pipe(
            map(response => response.data),
            tap(data => this.fileDownloadService.downloadFile(data.downloadUrl, data.friendlyName)),
            map(() => JobseekerProfileForAdminActions.downloadWorkExperienceAttachmentForAdminSuccess()),
            catchError(response => of(JobseekerProfileForAdminActions.downloadWorkExpeienceAttachmentForAdminFail({payload: response.error})))
        ))
    ));

    
    //edit/add
    editJobseekerPersonalInfoForAdmin$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.editJobseekerPersonalInfoForAdmin),
        mergeMap(action => this.jobseekerSerivce.editJobseekerPersonalInfoForAdmin(action.payload.jobseekerPersonalInfoModel, action.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileForAdminActions.editJobseekerPersonalInfoForAdminSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.editJobseekerPersonalInfoForAdminFail({payload: response.error})))
        ))
    ));

    editJobseekerPersonalInfoSuccessForAdmin$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.editJobseekerPersonalInfoForAdminSuccess),
        tap(() => this.dialog.getDialogById('personalInfo').close())
          ),
        { dispatch: false }
      );

    editJobseekerEducaion$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.editJobseekerEducationForAdmin),
        mergeMap(action => this.jobseekerSerivce.editJobseekerEducationForAdmin(action.payload.jobseekerEducationalModel, action.payload.moderationLastChangeDate, action.payload.jobseekerId)
        .pipe(
            map(response => JobseekerProfileForAdminActions.editJobseekerEducationForAdminSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.editJobseekerEducationForAdminFail({payload: response.error})))
        ))
    ));

    editJobseekerEducationSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.editJobseekerEducationForAdminSuccess),
        tap(() => this.dialog.getDialogById('education').close())
          ),
        { dispatch: false }
      );

    editJobseekerLanguage$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.editJobseekerLanguageForAdmin),
        mergeMap(action => this.jobseekerSerivce.editJobseekerLanguageForAdmin(action.payload.language, action.payload.jobseekerId, action.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileForAdminActions.editJobseekerLanguageForAdminSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.editJobseekerLanguageForAdminFail({payload: response.error})))
        ))
    ));

    editJobseekerLanguageSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.editJobseekerLanguageForAdminSuccess),
        tap(() => this.dialog.getDialogById('language').close())
          ),
        { dispatch: false }
      );

    uploadJobseekerDocument$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.uploadJobseekerDocumentForAdmin),
        mergeMap(action => this.jobseekerSerivce.uploadJobseekerDocumentForAdmin(action.payload.document, action.payload.jobseekerId, action.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileForAdminActions.uploadJobseekerDocumentForAdminSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.uploadJobseekerDocumentForAdminFail({payload: response.error})))
        ))
    ));

    uploadJobseekerDocumentSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.uploadJobseekerDocumentForAdminSuccess),
        tap(() => this.dialog.getDialogById('document').close())
          ),
        { dispatch: false }
      );

    editJobseekerWorkExperience$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.editJobseekerWorkExperienceForAdmin),
        mergeMap(action => this.jobseekerSerivce.editJobseekerWorkExperienceForAdmin(action.payload.jobseekerWorkexperience, action.payload.jobseekerId, action.payload.moderationLastChangeDate)
        .pipe(
            map(response => JobseekerProfileForAdminActions.editJobseekerWorkExperienceForAdminSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.editJobseekerWorkExperienceForAdminFail({payload: response.error})))
        ))
    ));

    setIsWorkExperienceFilled$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.setIsWorkExperienceFilledForAdmin),
        mergeMap(action => this.jobseekerSerivce.setIsWorkExperienceFilledForAdmin(action.payload)
        .pipe(
            map(response => JobseekerProfileForAdminActions.setIsWorkExperienceFilledForAdminSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.setIsWorkExperienceFilledForAdminFail({payload: response.error})))
        ))
    ));

    editJobseekerWorkExperienceSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.editJobseekerWorkExperienceForAdminSuccess),
        tap(() => this.dialog.getDialogById('workExperience').close())
          ),
        { dispatch: false }
      );

    //file upload
    uploadTempFile$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.uploadTempFileForAdmin),
        mergeMap(action => this.jobseekerSerivce.uploadTempFileForAdmin(action.payload)
        .pipe(
            map(response => JobseekerProfileForAdminActions.uploadTempFileForAdminSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.editJobseekerEducationForAdminFail({payload: response.error})))
        ))
    ));

    //edit photo
    editJobseekePhoto$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.editJobseekerPhotoForAdmin),
        mergeMap(action => this.jobseekerSerivce.editJobseekerPhotoForAdmin(action.payload.jobseekerPhotoModel, action.payload.moderationLastChangeDate, action.payload.jobseekerId)
        .pipe(
            map(response => JobseekerProfileForAdminActions.editJobseekerPhotoForAdminSuccess({payload: response.data})),
            catchError(response => of(JobseekerProfileForAdminActions.editJobseekerPhotoForAdminFail({payload: response.error})))
        ))
    ));

    editJobseekerPhotoSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.editJobseekerPhotoForAdminSuccess),
        tap(() => this.dialog.getDialogById('photo').close())
          ),
        { dispatch: false }
      );


    //CV export
    jobseekerCvExport$ = createEffect(() => this.actions$.pipe(
        ofType(JobseekerProfileForAdminActions.jobseekerCvExport),
        mergeMap(() => this.jobseekerSerivce.cvExport()
        .pipe(
            map(response => response.data),
            tap(data => this.fileDownloadService.downloadFile(data.downloadUrl, data.friendlyName)),
            map(response => JobseekerProfileForAdminActions.jobseekerCvExportSuccess()),
            catchError(response => of(JobseekerProfileForAdminActions.jobseekerCvExportFailed({payload: response.error})))
        ))
    ));

    constructor(private actions$: Actions, private jobseekerSerivce: JobseekerService, private fileDownloadService: FileDownloadService, private dialog: MatDialog){}
}