<div class="container">
    <svg-icon *ngIf="userType === type.Jobseeker || userType === type.Internal" (click)="onEdit()" src="/assets/icons/edit-blue.svg" class="edit-icon"></svg-icon>

    <div class="info-item full-name">
        <span class="full-name__name">{{personalInfo?.name}}</span>
        <span [ngClass]="{'blurry-text': isPreview}" class="full-name__surname">{{personalInfo?.surname}}</span>
        <app-icon *ngIf="personalInfo?.nameIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>
    </div>
   
    <div class="info-item contact-info-email">
        <svg-icon class="contact-info-email__icon" src="/assets/icons/envelope.svg" [svgClass]="'envelope'"></svg-icon>
        <span [ngClass]="{'blurry-text': isPreview}" class="contact-info-email__text">{{personalInfo?.email}}</span>
        <app-icon *ngIf="personalInfo?.emailIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>
    </div>

    <div class="info-item contact-info-phone">
        <svg-icon class="contact-info-phone__icon" src="/assets/icons/phone.svg" [svgClass]="'phone'"></svg-icon>
        <div class="contact-info-phone__texts">
            <span [ngClass]="{'blurry-text': isPreview}" class="contact-info-phone__text">{{personalInfo?.phone}}</span>
            <span *ngIf="personalInfo?.phone2 !=null">;</span>
        </div>
        <span [ngClass]="{'blurry-text': isPreview}" class="contact-info-phone__text2">{{personalInfo?.phone2}}</span>
        <app-icon *ngIf="personalInfo?.phoneIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>
    </div>

    <div class="info-item gender">
        <span class="label">{{'label.Gender' | translate}}:</span>
        <span class="gender__text">{{personalInfo?.gender}}</span>
        <app-icon *ngIf="personalInfo?.genderIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>
    </div>
    <div class="info-item birth-date">
        <span class="label">{{'label.DateOfBirth' | translate}}:</span>
        <span class="birth-date__text">{{personalInfo?.birthDate | date:'dd MMMM y':timeZone:currentLang}}</span>
        <app-icon *ngIf="personalInfo?.birthDateIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>
    </div>
    <div class="info-item locality">
        <span class="label">{{'label.CurrentLocation' | translate}}:</span>
        <span class="locality__text">
            <span *ngFor="let location of personalInfo.jobseekerExtendedLocations, let last = last">
                {{ location }}{{last ? '' : ', '}}
            </span>
        </span>
        <app-icon *ngIf="personalInfo?.currentAddressIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>
    </div>
    <div class="info-item address">
        <span class="label">{{'label.ExactAddress' | translate}}:</span>
        <span class="address__text">{{personalInfo?.address}}
            <app-icon *ngIf="personalInfo?.addressIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>
        </span>
    </div>
    <div class="info-item address">
        <span class="label">{{'label.LegalAddress' | translate}}:</span>
        <span class="address__text">{{personalInfo?.legalAddress}}
            <app-icon *ngIf="personalInfo?.legalAddressIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>
        </span>
    </div>
    <div class="info-item driving-licence">
        <span class="label">{{'label.DrivingLicence' | translate}}:</span>
        <span *ngFor="let drivingLicense of personalInfo?.drivingLicenses, let last = last"  class="driving-licence__item">
            {{drivingLicense}}{{last ? '' : ', '}}
        </span>
        <app-icon *ngIf="personalInfo?.drivingLicenseIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>

    </div>
    <div class="info-item own-vehicle">
        <span class="label">{{'label.OwnTransport' | translate}}:</span>
        <span class="own-vehicle__text">
            {{ (personalInfo?.isVehicleOwner ? 'label.Yes' : 'label.No') | translate }}
        </span>
        <app-icon *ngIf="personalInfo?.ownVehicleIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>
    </div>
    <div class="info-item transport-type">
        <span class="label">{{'label.TransportType' | translate}}:</span>
        <span class="transport-type__text">
            <span *ngFor="let vehicleItem of personalInfo.jobseekerVehicles, let last = last">
                {{ vehicleItem.vehicleName }}{{last ? '' : ', '}}
            </span>
        </span>
        <app-icon *ngIf="personalInfo?.transportTypeIsInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="mod-icon"></app-icon>

    </div>
</div>
