import { Component, Input, OnInit } from '@angular/core';
import { UserTypeEnum } from '@hrra/common-models';
import { ButtonTypeEnum } from '@hrra/ui';

@Component({
  selector: 'hrra-jobseeker-profile-tile',
  templateUrl: './jobseeker-profile-tile.component.html',
  styleUrls: ['./jobseeker-profile-tile.component.scss']
})
export class JobseekerProfileTileComponent implements OnInit {

  public buttonType: any;
  public type: any;

  @Input() title;
  @Input() rightText;
  @Input() hasOutOfTileContent;
  @Input() userType: UserTypeEnum;

  constructor() { this.buttonType = ButtonTypeEnum, this.type = UserTypeEnum}

  ngOnInit(): void {
  }

}
