import { createSelector } from '@ngrx/store';
import { selectCategoriesMap, selectChildCategories, selectHeadCategories } from '../../selectors';
import { selectJobseekerStoreState } from '../jobseeker-profile/selectors';

export const selectCategoryPickerState = createSelector(
    selectJobseekerStoreState,
    s => s.categoryPicker
);


export const selectSelectedCategoryIds = createSelector(
    selectCategoryPickerState,
    s => s.selectedCategoryIds
)

export const selectMaxCategoriesReached = createSelector(
    selectSelectedCategoryIds,
    s => s.length >= 7
);



export const selectSelectedCategories = createSelector(
    selectSelectedCategoryIds,
    selectCategoriesMap,
    (s, c) => s.map(cc => c[cc])
);



export const selectHeadCategoriesExpandedMap = createSelector(
    selectHeadCategories,
    selectCategoryPickerState,
    (headCategories, uiState) => {
      let result: { [index: number]: boolean } = {};
  
      headCategories.forEach((c) => {
        result[c.categoryId] = false;
      });
  
      uiState.expandedCategoryIds.forEach((c) => {
        result[c] = true;
      });
  
      return result;
    }
  );
  
  export const selectCategoriesSelectedMap = createSelector(
    selectCategoryPickerState,
    selectChildCategories,
    (f, o) => {
      let result = {};
      o.forEach((c) => (result[c.categoryId] = false));
      f.selectedCategoryIds.forEach((c) => (result[c] = true));
  
      return result;
    }
  );


export const selectDisplaySelectedCategoriesTags = createSelector(
    selectSelectedCategories,
    s => s.concat().sort((a, b) => a.headCategoryId - b.headCategoryId)
);


export const selectQuery = createSelector(
    selectCategoryPickerState,
    s => s.query
);

export const selectDisplayHeadCategories = createSelector(
    selectQuery,
    selectHeadCategories,
    selectChildCategories,
    (query, headCategories, childCategories) => {

        let matchedHeads = headCategories.filter(c => checkQuery(c.name, query));//head with matching names, take with all children
        let matchedHeadIdSet = new Set(matchedHeads.map(c => c.categoryId));
        
        let remainingMatchingChildren = childCategories.filter(c => checkQuery(c.name, query) && !matchedHeadIdSet.has(c.headCategoryId));

        let remainingMatchingChildrenHeadIdSet = new Set(remainingMatchingChildren.map(c => c.headCategoryId));
        let remainingMatchingChildrenIdSet = new Set(remainingMatchingChildren.map(c => c.categoryId));

        let remainingMatchigHeads = headCategories.filter(c => remainingMatchingChildrenHeadIdSet.has(c.categoryId)).map(c => {
            let result = Object.assign({}, c, { childCategoryIds: c.childCategoryIds.filter(cc => remainingMatchingChildrenIdSet.has(cc)) });

            return result;
        });

        return matchedHeads.concat(remainingMatchigHeads).sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
    }
);


function checkQuery(text: string, query: string){
    return query === null || query ==='' || query.length < 2 || text.includes(query);
}


export const selectAtLeastOneCategorySelected = createSelector(
    selectSelectedCategoryIds,
    s => s.length > 0
);