import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotExistingPageComponent } from './hr-ui/not-existing-page/not-existing-page.component';
import { JobseekerEmailVerificationComponent } from './jobseeker-email-verification/jobseeker-email-verification.component';
import { JobseekerProfileForAdminComponent } from './jobseeker/jobseeker-profile-for-admin/jobseeker-profile-for-admin.component';
import { AdminSigninComponent } from './admin-signin/admin-signin.component';
import { configureGuard } from './core/auth.guard';
import { UserTypeEnum } from '@hrra/common-models';

const routes: Routes = [
  {
    path: 'action/activate/:code',
    pathMatch: 'full',
    component: JobseekerEmailVerificationComponent,
  },
  {
    path: 'admin-sign-in/:token',
    pathMatch: 'full',
    component: AdminSigninComponent
  },
  {
    path: 'jobseeker',
    loadChildren: () =>
      import('./jobseeker/jobseeker.module').then((m) => m.JobseekerModule),
  },
  {
    path: 'admin/jobseeker-edit/:jobseekerId',
    component: JobseekerProfileForAdminComponent,
    canActivate: [configureGuard(UserTypeEnum.Internal)]
  },
  {
    path: 'subscriber',
    loadChildren: () => import('./subscriber/subscriber.module').then((m) => m.SubscriberModule),
  },
  { path: '**', component: NotExistingPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
