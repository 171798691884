import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest, filter, take } from 'rxjs';
import { loadLastListingPage, sendBannerViewToAnalytic } from 'src/app/root-store/actions';
import { getFrontPageBannerList, loadFavoriteAnnouncements } from 'src/app/root-store/announcement-store/announcement-front-page-actions';
import { selectFavoriteAnnouncements, selectIsLoading,selectFrontPageTopBanner,selectFrontPageBottomBanner, selectFavoritePageBannerIds } from 'src/app/root-store/announcement-store/selectors';
import { ProviderWebsiteUrlMap, selectCurrentUserType, selectCurrentWebsite, selectCurrentWebsiteNameForSeo } from 'src/app/root-store/selectors';
import { BannerTypeEnum } from 'src/app/banner/shared/models/enums/banner-type-enum';
import { BannerTargetEnum } from 'src/app/banner/shared/models/enums/banner-target-enum';
import { Banner } from 'src/app/banner/shared/models/banner-model';
import { AppState } from 'src/app/root-store/state';
import { PageNameEnum } from 'src/app/shared/models/enums/page-names-enum';
import { MetaService } from 'src/app/shared/services/meta.service';
import { Announcement } from '../shared/models/announcement-model';
import { Breakpoints } from 'src/app/shared/models/breakpoints-model';
import { SsrService } from 'src/app/core/ssr.service';
import { BannerPositionEnum } from 'src/app/banner/shared/models/enums/banner-position-enum';
import { BannerHeaderPositionId } from 'src/app/shared/models/enums/banner-header-position-id-enum';

@Component({
  selector: 'app-ann-favorites',
  templateUrl: './ann-favorites.component.html',
  styleUrls: ['./ann-favorites.component.scss']
})
export class AnnFavoritesComponent implements OnInit {

  metaDataSubscription: Subscription;

  pageId: any

  date = new Date();

  favoriteAnnouncements$: Observable<Announcement[]>;
  isLoading$: Observable<boolean>;
  frontPageTopBanner$: Observable<Banner>;
  frontPageBottomBanner$: Observable<Banner>;
  providerWebsiteUrlMap$: Observable<{ [index: number]: string }>;

  targetId: number = BannerTargetEnum.Desktop;
  bannerType: any;

  userType$: Observable<any>;

  constructor(private store: Store<AppState>, private metaService: MetaService, private ssr: SsrService) {
    this.bannerType = BannerTypeEnum;
    this.targetId = window.innerWidth > Breakpoints.medium ? BannerTargetEnum.Desktop : BannerTargetEnum.Responsive;
    this.pageId = BannerHeaderPositionId
  }

  ngOnInit(): void {
    this.store.dispatch(loadLastListingPage({payload: PageNameEnum.Favorites}));

    let favoritePageBanners$ = this.store.select(selectFavoritePageBannerIds)
    let providerWebsiteId$ = this.store.select(selectCurrentWebsite)

    combineLatest([favoritePageBanners$, providerWebsiteId$])
    .pipe(
      filter(([banners, provderWebsite]) =>{
        return banners.bannerIdList.length > 0 && provderWebsite !== null && provderWebsite !== undefined;
      }),
      take(1)
    ).subscribe(
      ([b, p]) => {
        this.store.dispatch(sendBannerViewToAnalytic({payload:{bannerIdList: b, providerWebsiteId:p.providerWebsiteId, pageId: BannerHeaderPositionId.FrontPage}}))
      }
    )
    if(this.ssr.isPlatformBrowser()){
      this.store.dispatch(getFrontPageBannerList({
        payload: {
          targetId: this.targetId, 
          bannerHeadPositionId: BannerPositionEnum.Main_page
        }
      }));
    }
    
    this.frontPageTopBanner$ = this.store.select(selectFrontPageTopBanner);
    this.frontPageBottomBanner$ = this.store.select(selectFrontPageBottomBanner);

    this.store.dispatch(loadFavoriteAnnouncements());

    this.favoriteAnnouncements$ = this.store.pipe(select(selectFavoriteAnnouncements));
    
    this.isLoading$ = this.store.pipe(select(selectIsLoading));

    this.providerWebsiteUrlMap$ = this.store.pipe(select(ProviderWebsiteUrlMap));

    this.metaDataSubscription = this.store.pipe(select(selectCurrentWebsiteNameForSeo)).subscribe(d => {
      this.metaService.updateMetaData(d);
    });

    this.userType$ = this.store.select(selectCurrentUserType);
  }

  ngOnDestroy() {
    this.metaDataSubscription.unsubscribe()
  }
}
