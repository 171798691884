<div  *ngIf="{filterIsExpanded: filterIsExpanded$ | async,
              isLoading: isLoading$ | async,
              listBanner: listBanner$ | async,
              topBanner: topBanner$ | async,
              leftBanner: leftBanner$ | async,
              announcements: announcements$ | async,
              previewAnnouncements: previewAnnouncements$ | async,
              matchedAnnouncementCount: matchedAnnouncementCount$ | async,
              search: search$ | async,
              salaryOptions: salaryOptions$ | async,
              publishDateOptions: publishDateOptions$ | async,
              pageCount: pageCount$ | async,
              navItems: navItems$ | async,
              providerWebsiteUrlMap: providerWebsiteUrlMap$ | async,
              previousePageQueryParams: previousePageQueryParams$ | async,
              nextPageQueryParams: nextPageQueryParams$ | async,
              userType: userType$ | async} as data" class="container" (window:resize)="onResizeWindowWidth(data.filterIsExpanded)">
    <div class="ann-info" id="ann-info">
        <div class="ann-count-text" [innerHTML]="'label.FoundAnnouncemens' | translate : {announcementsSum: data.matchedAnnouncementCount, count: data.matchedAnnouncementCount } | safeHtml"></div>
    </div>

    <!-- Top Banner -->
    <div class="banner banner--top">
        <app-banner [banner]="data.topBanner" [pageId]="pageId"></app-banner>
    </div>
    
    <div class="wrapper">

        <div (click)="clickOnSideBar()" class="sticky" id="sticky" [style.bottom]="sidebarBottom" [style.top]="sidebarTop" [style.position]="sidebarPosition" (scroll)="onScroll()">
            
            <app-ann-search-filter #searchFilter></app-ann-search-filter>
           
            <!--Banner -->
            <div class="banner banner--left-sidebar">
                <app-banner [banner]="data.leftBanner" [pageId]="pageId"></app-banner>
            </div>       
        </div>
        <div *ngIf="!data.filterIsExpanded" class="ann-list" id="ann-list" [ngClass]="{'ann-list-margin': sidebarPosition == 'fixed' || sidebarPosition == 'absolute'}">
            <app-spinner [isLoading]="data.isLoading">
                <div class="ann">
                    <div *ngFor="let announcement of data.announcements; let i = index">
                        <app-announcement-item [userType]="data.userType" [providerWebsiteUrlMap]="data.providerWebsiteUrlMap" [announcement]="announcement"  [pageName] = "pageName.SearchResultsPage" [date]="date"></app-announcement-item>

                        <!--Banner-->
                        <div *ngIf="(i+1) % 20 == 0 && (i+1) != data.announcements.length" class="banner banner--list">
                            <app-banner [banner]="data.listBanner" [pageId]="pageId"></app-banner>
                        </div>
                    </div>
                </div>
            </app-spinner>

            <div *ngIf="data.matchedAnnouncementCount != 0" class="paging">
                <ng-container>                    
                    <hrra-routable-paging [currentPage]="data.search.filter.currentPage" 
                                          [pageCount]="data.pageCount" 
                                          [pagingNavItems]="data.navItems" 
                                          [previousePageQueryParams]="data.previousePageQueryParams"
                                          [nextPageQueryParams]="data.nextPageQueryParams">
                    </hrra-routable-paging>
                </ng-container>
            </div>
        </div>
    </div>
</div>