<app-spinner [isLoading]="isLoading$ | async">
    <div class="container" *ngIf="{year: workExperienceYears$ | async,
                                   month: workExperienceMonth$ | async,
                                   cvExportLoading: cvExportLoading$| async,
                                   jobseekerWorkExperiences: jobseekerWorkExperiences$ | async,
                                   jobseekerPersonalInfo: jobseekerPersonalInfo$ | async,
                                   jobseekerEducations: jobseekerEducations$ | async,
                                   documents: documents$ | async,
                                   documentInMod: documentInMod$ | async,
                                   educationMaxLevel: educationMaxLevel$ | async,
                                   languagesInMod: languagesInMod$ | async,
                                   jobseekerLanguages: jobseekerLanguages$ | async,
                                   timeZone: timeZone$ | async,
                                   photoIsInModeration: photoIsInModeration$ | async,
                                   documentTypeMap: documentTypeMap$ | async,
                                   currentUserType: currentUserType$ | async,
                                   currentLanguage: currentLanguage$ | async,
                                   moderationLastChangeDate: moderationLastChangeDate$ | async} as data">
      <div *ngIf="(jobseeker$ | async) as jobseeker" >
        <div class="head-info">
          <div *ngIf="jobseeker.lacksNecessaryInformation" class="lack-info">
            <div class="lack-info-icon">
              <svg-icon class="lack-info-icon__warning" src="/assets/icons/warning.svg"></svg-icon>
            </div>
            <div class="lack-info-text">
              <span class="lack-info-text__message">{{'message.JobseekerProfileLacksNecessaryInformation' | translate}}</span>
              <span class="lack-info-text__message-item" *ngFor="let lackItem of jobseeker.lackedFieldList; let last = last">
                {{necessaryFieldMap[lackItem] | translate}}{{last ? '' : '; '}}
              </span>
            </div>
          </div>
        </div>
        <mat-progress-bar *ngIf="data.cvExportLoading" mode="indeterminate"></mat-progress-bar>
  
        <hrra-jobseeker-profile-tile [userType]="data.currentUserType" [hasOutOfTileContent]="true">
          <hrra-jobseeker-personal-info tile-content [userType]="data.currentUserType" [timeZone]="data.timeZone" [currentLang]="data.currentLanguage" [personalInfo]="jobseekerPersonalInfo$ | async" (onEditPersonalInfo)="editPersonalInfo()" class="main-content"></hrra-jobseeker-personal-info>
          <hrra-jobseeker-photo [userType]="data.currentUserType" out-of-tile-content [photoHref]="jobseeker.photoHref" [photoIsInModeration]="data.photoIsInModeration" (onEditPhoto)="editPhoto($event, jobseeker.photoHref)" (onDeletPhoto)="deletePhoto(jobseekerId, data.moderationLastChangeDate)" class="photo-content"></hrra-jobseeker-photo>
        </hrra-jobseeker-profile-tile>
      
        <hrra-jobseeker-profile-tile [userType]="data.currentUserType" [title]="'label.WorkExperience' | translate" 
                                    [rightText]="(data.year  != 0 ? data.year : ' ') + ' ' + ('label.Year' | translate:{ count: data.year }) + ' ' + (data.month != 0 ? data.month : ' ') + ' ' + ('label.Month' | translate:{ count: data.month })">
           <div tile-content>
            <div *ngIf="data.jobseekerWorkExperiences?.length > 0">
              <div *ngFor="let workExperience of data.jobseekerWorkExperiences" class="main-content">
                <hrra-jobseeker-work-experience [userType]="data.currentUserType" [workExperience] = "workExperience" [timeZone]="data.timeZone" [currentLang]="data.currentLanguage" (onEditExperience)="editWorkExperience($event)" (onDeletExperience)="deleteExperience($event, jobseekerId, data.moderationLastChangeDate)" (onDownloadExperienceAttachment)="downloadExperienceAttachment($event, jobseekerId)"></hrra-jobseeker-work-experience>
              </div>
            </div>
            <div *ngIf="data.jobseekerWorkExperiences?.length == 0 && jobseeker.isWorkExperienceFilled" class="without-experience-text">{{'label.WithoutExperience' | translate}}</div>
           </div>
          
          <button *ngIf="!jobseeker.isWorkExperienceFilled" button class="without-experience-button" (click)="onIsWorkExperienceFilled(data.moderationLastChangeDate, jobseekerId)">{{'label.WithoutExperience' | translate}}</button>
          <app-advanced-button  (click)="onWorkExperienceCreate()" add-button [iconType]="iconButtonType.add" [text]="'label.Add' | translate" [type]="'uncoloured-blue'" class="add-button"></app-advanced-button>
        </hrra-jobseeker-profile-tile>
      
        <hrra-jobseeker-profile-tile [userType]="data.currentUserType" [title]="'label.Education' | translate" [rightText]="data.educationMaxLevel">
          <div tile-content *ngFor="let education of data.jobseekerEducations" class="main-content">
            <hrra-jobseeker-education [userType]="data.currentUserType" [education]="education" [timeZone]="data.timeZone" [currentLang]="data.currentLanguage" (onEditEducation)="editEducation($event)" (onDeletEducation)="deleteEducation($event, jobseekerId, data.moderationLastChangeDate)" (onDownloadEducationAttachment)="downloadEducationAttachment($event, jobseekerId)"></hrra-jobseeker-education>
          </div>
          <app-advanced-button (click)="onEducationCreate()" add-button [iconType]="iconButtonType.add" [text]="'label.Add' | translate" [type]="'uncoloured-blue'" class="add-button"></app-advanced-button>
        </hrra-jobseeker-profile-tile>
      
        <hrra-jobseeker-profile-tile [userType]="data.currentUserType" [title]="'label.Languages' | translate">
          <div tile-content *ngFor="let language of data.jobseekerLanguages" class="main-content main-content--language">
            <hrra-jobseejer-language 
              [userType]="data.currentUserType" 
              [language]="language" 
              [languageIsInMod]="data.languagesInMod[language.jobseekerLanguageId]" 
              (onEditLanguage)="editLanguage($event, data.moderationLastChangeDate)"
              (onDeleteLanguage)="deleteLanguage($event, data.moderationLastChangeDate)" 
              (onDownloadLanguageAttachment)="downloadLanguageAttachment($event, jobseekerId)"></hrra-jobseejer-language>
          </div>
          <app-advanced-button (click)="onLanguageCreate(jobseekerId, data.moderationLastChangeDate)" add-button [iconType]="iconButtonType.add" [text]="'label.Add' | translate" [type]="'uncoloured-blue'" class="add-button"></app-advanced-button>
        </hrra-jobseeker-profile-tile> 
      
        <hrra-jobseeker-profile-tile [userType]="data.currentUserType" [title]="'label.UploadedDocuments' | translate">
          <div tile-content *ngFor="let document of data.documents" class="main-content">
            <hrra-jobseeker-document [userType]="data.currentUserType" [document]="document" [documentTypeMap]="data.documentTypeMap" (onDeletDocument)="deleteDocument($event, data.moderationLastChangeDate)" [documentIsInMod]="data.documentInMod[document.jobseekerDocumentId]" (onDownloadDocment)="downloadDocument($event, jobseekerId)"></hrra-jobseeker-document>
          </div>
          <app-advanced-button (click)="onDocumentCreate(jobseekerId, data.moderationLastChangeDate)" add-button [iconType]="iconButtonType.add" [text]="'label.UploadDocuments' | translate" [type]="'uncoloured-blue'" class="add-button"></app-advanced-button>
        </hrra-jobseeker-profile-tile>
      </div>
    </div>
  </app-spinner>