<div *ngIf="{ locationsMap: locationsMap$ | async,
              levels: levels$ | async,
              selectedLocations: selectedLocations$ | async,
              firstDisabledLocationIndex: firstDisabledLocationIndex$ | async,
              isWildCardSelected: isWildCardSelected$ | async,
              selectedLocationsCount: selectedLocationsCount$ | async,
     abroadLocationIndex: abroadLocationIndex$ | async} as data">
    <form [formGroup]="locationForm" #ngForm="ngForm" class="form">
        <div formArrayName = 'locations' class="container" style="justify-content: space-between;">
            <div *ngFor="let level of data.levels; let i=index" [formGroupName] = "i" style="width: 100%;">
                <div style="display: flex; align-items: baseline;">
                    <mat-form-field>
                        <div class="autocomplete-input-container">
                            <input placeholder="{{'label.Choose' | translate}} {{locationNameMap[locationTypes[i]] | translate}}" 
                            class="form-item__value program__value autocomplete-input-container__value--autocomplete"
                            (input)="onFilter($event, level.parentId)"
                            type="text" 
                            matInput
                            [matAutocomplete]="auto" 
                            formControlName="locationId"
                            (blur)="checkLocation(i, data.locationsMap[level.parentId], level.parentId, data.selectedLocations[level.parentId])"/>
                            <svg-icon src="/assets/icons/chevron-down.svg" [svgClass]="'autocomplete-input-container__icon'"></svg-icon>
                        </div>
                      
                        <mat-autocomplete (optionSelected)="onChangeValue($event.option.value, level.parentId, i)" requireSelection #auto="matAutocomplete" [displayWith]="displayFn.bind(this, data.locationsMap[level.parentId])">
                            <ng-container *ngIf="level.parentId">
                                <mat-option *ngFor="let loc of data.locationsMap[level.parentId]" [value]="loc.locationId">
                                    {{ loc.name }}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                    
                    <div *ngIf="(requiredLevelCountList[i] && !data.isWildCardSelected.wildcardSelected) || (requiredLevelCountList[i] && data.isWildCardSelected.wildcardSelected && i < data.selectedLocationsCount)" style="margin-left: 0.25rem; height: 2.5rem;">
                        <svg-icon [src]="'assets/icons/required.svg'" class="required-icon"></svg-icon>
                    </div>
                </div>
                
                <mat-error *ngIf="locationForm.hasError(i) && locationForm.controls['locations'].touched" class="validation-message">{{'message.MandatoryField' | translate}}</mat-error>
               
                <div *ngIf="(locationLevelCount - lastHiddenLevelCount) <= i && data.abroadLocationIndex + 1 != i" class="hidden-container">
                    <mat-checkbox (change)="onChangeCheckboxValue($event)" [checked]="data.locationsMap[level.parentId]?.isHidden" formControlName="isHidden" class="hidden-container__checkbox"></mat-checkbox>
                    <div class="hidden-container__label">{{'label.Hide' | translate}}</div>
                    <svg-icon title="{{'label.HiddenLocationInfo' | translate}}" class="hidden-container__icon" class="lack-info-icon__warning" src="/assets/icons/info.svg"></svg-icon>
                </div>
            </div>
        </div>
    </form>
</div>
