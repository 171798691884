import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/root-store/state';
import { Observable, Subscription } from 'rxjs';
import {
  selectJobseekerPersonalInfo,
  selectJobseeker,
  selectJobseekerLanguages,
  selectTotalWorkExperienceMonths,
  selectTotalWorkExperienceYears,
  selectMaxEducationLevel,
  selectJobseekerWorkExperiences,
  languageInModeration,
  documentsInModeration,
  selectJobseekerDocuments,
  selectEducations,
  selectIsProfileLoading,
  selectIsCvExportLoading,
  selectPhotoIsInModeration,
  selectDocumentTypeListMap,
  selectJobseekerModerationLastChangeDate,
} from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/selectors';
import { IconButtonTypeEnum } from '@hrra/ui';
// import { JobseekerEducationViewModel } from '../shared/models/jobseeker-education-model';
import {
  Jobseeker,
  JobseekerDocument,
  JobseekerEducationViewModel,
  JobseekerLanguage,
  JobseekerWorkExperienceViewModel,
  NecessaryField,
  UserTypeEnum,
} from '@hrra/common-models';
import { JobseekerDialogService } from '../shared/services/jobseeker-dialog.service';
import {
  deleteJobseekerDocument,
  deleteJobseekerEducationForAdmin,
  deleteJobseekerLanguage,
  deleteJobseekerPhoto,
  deleteJobseekerWorkExperienceForAdmin,
  downloadDocumentAttachmentFromAdmin,
  downloadEducationAttachmentForAdmin,
  downloadLanguageAttachmentForAdmin,
  downloadWorkExperienceAttachmentForAdmin,
  jobseekerCvExport,
  setIsWorkExperienceFilledForAdmin,
} from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/actions';
import { MetaService } from 'src/app/shared/services/meta.service';
import {
  selectCurrentUserType,
  selectCurrentLanguage,
  selectCurrentWebsiteNameForSeo,
  selectTimeZone,
} from 'src/app/root-store/selectors';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { loadJobseekerProfileForAdmin } from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/actions';
import { JobseekerPersonalInfoEditForAdminComponent } from '../jobseeker-personal-info-edit-for-admin/jobseeker-personal-info-edit-for-admin.component';
import { loadJobseekerLookups } from 'src/app/root-store/jobseeker-store/actions';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-jobseeker-profile-for-admin',
  templateUrl: './jobseeker-profile-for-admin.component.html',
  styleUrls: ['./jobseeker-profile-for-admin.component.scss'],
})
export class JobseekerProfileForAdminComponent {
  metaDataSubscription: Subscription;

  jobseeker$: Observable<Jobseeker>;
  jobseekerPersonalInfo$: Observable<any>;
  jobseekerWorkExperiences$: Observable<JobseekerWorkExperienceViewModel[]>;
  jobseekerEducations$: Observable<JobseekerEducationViewModel[]>;
  jobseekerLanguages$: Observable<JobseekerLanguage[]>;
  workExperienceYears$: Observable<any>;
  workExperienceMonth$: Observable<any>;
  educationMaxLevel$: Observable<string>;
  documents$: Observable<JobseekerDocument[]>;
  moderationLastChangeDate$: Observable<Date>;

  languagesInMod$: Observable<{ [index: number]: boolean }>;
  jobseekerEducationInMod$: Observable<{ [index: number]: boolean }>;
  documentInMod$: Observable<{ [index: number]: boolean }>;

  isLoading$: Observable<boolean>;
  cvExportLoading$: Observable<boolean>;

  timeZone$: Observable<string>;
  photoIsInModeration$: Observable<boolean>;
  documentTypeMap$: Observable<any>;
  currentUserType$: Observable<UserTypeEnum>;
  currentLanguage$: Observable<string>;

  public iconButtonType: any;
  public necessaryField: any;

  public necessaryFieldMap: { [index: number]: string };

  public jobseekerId: number;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private jobseekerDialogService: JobseekerDialogService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private metaService: MetaService
  ) {
    (this.iconButtonType = IconButtonTypeEnum),
      (this.necessaryField = NecessaryField);
  }

  ngOnInit() {
    this.route.paramMap.subscribe(p => {
      this.jobseekerId = +p.get('jobseekerId');
      this.store.dispatch(loadJobseekerProfileForAdmin({ payload: { jobseekerId: this.jobseekerId } }));
    });

    this.store.dispatch(loadJobseekerLookups());

    this.jobseeker$ = this.store.pipe(select(selectJobseeker));
    this.jobseekerPersonalInfo$ = this.store.pipe(select(selectJobseekerPersonalInfo));
    this.jobseekerWorkExperiences$ = this.store.pipe(select(selectJobseekerWorkExperiences));
    this.jobseekerEducations$ = this.store.pipe(select(selectEducations));
    this.jobseekerLanguages$ = this.store.pipe(select(selectJobseekerLanguages));
    this.moderationLastChangeDate$ = this.store.pipe(select(selectJobseekerModerationLastChangeDate));
 
    this.jobseekerLanguages$ = this.store.pipe(select(selectJobseekerLanguages));
    this.store.pipe(select(selectJobseekerLanguages));

    this.workExperienceMonth$ = this.store.pipe(select(selectTotalWorkExperienceMonths));
    
    this.workExperienceYears$ = this.store.pipe(select(selectTotalWorkExperienceYears));

    this.educationMaxLevel$ = this.store.pipe(select(selectMaxEducationLevel));
    this.documents$ = this.store.pipe(select(selectJobseekerDocuments));

    this.languagesInMod$ = this.store.pipe(select(languageInModeration));
    this.documentInMod$ = this.store.pipe(select(documentsInModeration));

    this.isLoading$ = this.store.pipe(select(selectIsProfileLoading));
    this.cvExportLoading$ = this.store.pipe(select(selectIsCvExportLoading));

    this.timeZone$ = this.store.pipe(select(selectTimeZone));
    this.photoIsInModeration$ = this.store.pipe(select(selectPhotoIsInModeration));
    this.documentTypeMap$ = this.store.pipe(select(selectDocumentTypeListMap));
    this.currentUserType$ = this.store.pipe(select(selectCurrentUserType));
    this.currentLanguage$ = this.store.pipe(select(selectCurrentLanguage));

    this.metaDataSubscription = this.store
      .pipe(select(selectCurrentWebsiteNameForSeo))
      .subscribe((d) => {
        this.metaService.updateMetaData(d);
      });

    this.necessaryFieldMap = {
      [NecessaryField.Name]: 'label.Name',
      [NecessaryField.Surname]: 'label.Surname',
      [NecessaryField.CV]: 'label.CV',
      [NecessaryField.Phone]: 'label.Phone',
      [NecessaryField.City]: 'label.City',
      [NecessaryField.Gender]: 'label.Gender',
      [NecessaryField.BirthDate]: 'label.DateOfBirth',
      [NecessaryField.WorkExperience]: 'label.WorkExperience',
      [NecessaryField.Education]: 'label.Education',
      [NecessaryField.Language]: 'label.Languages',
    };
  }

  ngOnDestroy() {
    this.metaDataSubscription.unsubscribe();
  }

  onEducationCreate() {
    this.jobseekerDialogService.editJobseekerEducationForAdmin(null, true);
  }

  onLanguageCreate(jobseekerId: number, moderationLastChangeDate: Date) {
    this.jobseekerDialogService.editJobseekerLanguageForAdmin(null, null, jobseekerId, moderationLastChangeDate, true);
  }

  onDocumentCreate(jobseekerId: number, moderationLastChangeDate: Date) {
    this.jobseekerDialogService.uploadDocumentForAdmin(jobseekerId, moderationLastChangeDate);
  }

  onWorkExperienceCreate() {
    this.jobseekerDialogService.editJobseekerWorkExperienceForAdmin(true, null);
  }

  onIsWorkExperienceFilled(moderationLastChangeDate:Date, jobseekerId: number) {
    this.store.dispatch(setIsWorkExperienceFilledForAdmin({ payload: {isWorkExperienceFilled: true, moderationLastChangeDate: moderationLastChangeDate, jobseekerId: jobseekerId} }));
  }

  onCvExport() {
    this.store.dispatch(jobseekerCvExport());
  }

  editEducation(id: number) {
    this.jobseekerDialogService.editJobseekerEducationForAdmin(id, false);
  }

  deleteEducation(event: any, jobseekerId: number, moderationLastChangeDate: Date) {
    const educationId = event.id;
    const objectId = event.objectId;
    this.dialogService
      .openConfirmDialog('message.AreYouSure')
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(
            deleteJobseekerEducationForAdmin({ payload: { educationId, objectId, jobseekerId, moderationLastChangeDate } })
          );
        }
      });
  }

  downloadEducationAttachment(objectId: string, jobseekerId: number) {
    this.store.dispatch(
      downloadEducationAttachmentForAdmin({ payload: { objectId, jobseekerId } })
    );
  }

  editLanguage(event: any, moderationLastChangeDate: Date) {
    const languageId = event.id;
    const objectId = event.objectId;
    this.jobseekerDialogService.editJobseekerLanguageForAdmin(languageId, objectId, this.jobseekerId, moderationLastChangeDate, false);
  }

  deleteLanguage(event: any, moderationLastChangeDate: Date) {
    const languageId = event.id;
    const objectId = event.objectId;
    const jobseekerId = this.jobseekerId;
    this.dialogService
      .openConfirmDialog('message.AreYouSure')
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(
            deleteJobseekerLanguage({ payload: { languageId, objectId, jobseekerId, moderationLastChangeDate} })
          );
        }
      });
  }

  downloadLanguageAttachment(objectId: string, jobseekerId: number) {
    this.store.dispatch(
      downloadLanguageAttachmentForAdmin({ payload: { objectId, jobseekerId } })
    );
  }

  editWorkExperience(workExperience: JobseekerWorkExperienceViewModel) {
    this.jobseekerDialogService.editJobseekerWorkExperienceForAdmin(
      false,
      workExperience
    );
  }

  deleteExperience(event: any, jobseekerId: number, moderationLastChangeDate: Date) {
    const experienceId = event.id;
    const objectId = event.objectId;

    this.dialogService
      .openConfirmDialog('message.AreYouSure')
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(
            deleteJobseekerWorkExperienceForAdmin({ payload: { experienceId, objectId, jobseekerId, moderationLastChangeDate } })
          );
        }
      });
  }

  downloadExperienceAttachment(objectId: string, jobseekerId: number) {
    this.store.dispatch(
      downloadWorkExperienceAttachmentForAdmin({ payload: { objectId, jobseekerId } })
    );
  }

  editPersonalInfo() {
    this.dialog.open(JobseekerPersonalInfoEditForAdminComponent, {
      maxHeight: '100vh',
      panelClass: 'confirm-edit-dialog-container',
      id: 'personalInfo',
    });
  }

  editPhoto(isPhotoInModeration: boolean, photoHref: string) {
    this.jobseekerDialogService.editJobseekerPhotoForAdmin(
      photoHref,
      isPhotoInModeration
    );
  }

  deletePhoto(jobseekerId: number, moderationLastChangeDate: Date) {
    this.dialogService
      .openConfirmDialog('message.AreYouSure')
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(deleteJobseekerPhoto({ payload: { jobseekerId, moderationLastChangeDate } }));
        }
      });
  }

  deleteDocument(event: any, moderationLastChangeDate: Date) {
    const documentId = event.id;
    const objectId = event.objectId;
    this.dialogService
      .openConfirmDialog('message.AreYouSure')
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(
            deleteJobseekerDocument({ payload: { documentId: documentId, objectId: objectId, jobseekerId: this.jobseekerId, moderationLastChangeDate } })
          );
        }
      });
  }

  downloadDocument(objectId: string, jobseekerId: number) {
    this.store.dispatch(
      downloadDocumentAttachmentFromAdmin({ payload: { objectId, jobseekerId } })
    );
  }
}
