<div *ngIf="{photoIsSelected: photoIsSelected$ | async, 
             photoIsApproved: photoIsApproved$ | async,
             base64: base64$ | async,
             isUpdating: isUpdating$ | async,
             moderationLastChangeDate: moderationLastChangeDate$ | async,
            jobseekerId: jobseekerId$ | async} as data">
    <app-spinner [isLoading]="isUpdating$ | async" [isFixedPosition]="false">
        <div class="content">
            <div [innerHTML]="'text.JobseekerPhotoDescription' | translate | safeHtml" class="content__description"></div>
            <div style="display: inline-block; vertical-align: middle;" class="content__photo">
                <div class="photo-content" [ngClass]="{'without-photo':photoHref == null}">
                    <div *ngIf="(photoHref || data.photoIsApproved) && !data.photoIsSelected">
                        <img class="photo-content__img" [src]="data.photoIsApproved ? data.base64 : photoHref" alt="">
                    </div>
        
                    <image-cropper style="margin: auto;"
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="3 / 4"
                    [resizeToWidth]="300"
                    [resizeToHeight]="400"
                    [output]="'base64'"
                    format="png"
                    (imageCropped)="imageCropped($event)">
                    </image-cropper>
                </div>
    
                <input type="file"(change)="fileChangeEvent($event)" #hiddenfileinput style="display:none;"/>
                <div class="crop-buttons" [ngClass]="{'crop-buttons--approve-is-active' : data.photoIsSelected}">
                    <button (click)="hiddenfileinput.click()" [ngClass]="{'button--approve-is-active' : data.photoIsSelected}" class="button button--choose">{{(photoHref || data.photoIsSelected ? 'label.Change' : 'label.Select') | translate}}</button>
                    <button *ngIf="data.photoIsSelected" (click)="onApprove()" class="button button--approve">{{'label.Approve' | translate}}</button>
                </div>
            </div>
        </div>
    
        <mat-dialog-actions>
            <div class="buttons">
                <app-button type="submit" (click)="onSave(data.base64, data.moderationLastChangeDate, data.jobseekerId)" [text]="'label.Save' | translate" [type]="buttonType.coloured" [typeAttribute]="buttonAttributeType.submit" class="button button--save"></app-button>
                <app-button [mat-dialog-close] ="true" [text]="'label.Cancel' | translate" [type]="buttonType.uncoloured" [typeAttribute]="buttonAttributeType.button"></app-button>
            </div>
        </mat-dialog-actions>
    </app-spinner>
</div>

