import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AppState } from 'src/app/root-store/state';
import { Store, select } from '@ngrx/store';
import { selectGenderList, selectPersonalInfo, selectDrivingLicenseList, selectMonthList, selectYearListBirth, selectIsJobseekerProfileUpdating, selectDaysList, selectSameAsCurrentAddress, selectLegalAddress, selectAddress, selectVehicleTypes, selectPersonInfoHasOwnVehicle, selectJobseekerId, selectJobseekerModerationLastChangeDate } from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/selectors';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidationErrors, AbstractControl, FormControl } from '@angular/forms';
import { selectLocalities, selectLocationFirstLevelId, selectLocationLastHiddenLevelCount, selectLocationLevelCount, selectLocationRequiredLevelCount } from 'src/app/root-store/selectors';
import { JobseekerPersonalInfoModel } from '../shared/services/jobseeker.service';
import { editJobseekerPersonalInfoForAdmin, setSelectedBirthMonth, setSelectedBirthYear, updateJobseekerAddress, updateJobseekerLegalAddress, updateSameAsCurrentAddress } from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/actions';
import { Patterns } from '@hrra/core';
import { ButtonTypeAttributeEnum, ButtonTypeEnum } from '@hrra/ui';
import { NavigationEnd, Router } from '@angular/router';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BasicOptionItem, Locality } from '@hrra/common-models';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-jobseeker-personal-info-edit-for-admin',
  templateUrl: './jobseeker-personal-info-edit-for-admin.component.html',
  styleUrls: ['./jobseeker-personal-info-edit-for-admin.component.scss']
})
export class JobseekerPersonalInfoEditForAdminComponent implements OnInit {

  editForm: UntypedFormGroup;

  locations$: Observable<Locality[]>;
  genderList$: Observable<BasicOptionItem[]>;
  drivingLicenseList$: Observable<BasicOptionItem[]>;
  monthList$: Observable<BasicOptionItem[]>;
  yearListBirth$: Observable<BasicOptionItem[]>;
  selectPersonalInfo$: Observable<JobseekerPersonalInfoModel>;
  isUpdating$: Observable<boolean>;
  days$: Observable<number[]>;
  jobseekerId$: Observable<number>;
  moderationLastChangeDate$: Observable<Date>;

  public locationLevelCount$: Observable<number>;
  public locationRequiredLevelCount$: Observable<number>;
  public locationLastHiddenLevelCount$: Observable<number>;
  public locationFirstLevelId$: Observable<string>;

  public sameAsCurrentAddress$: Observable<boolean>;
  public legalAddress$: Observable<string>;
  public address$: Observable<string>;

  public vehicleTypes$: Observable<BasicOptionItem[]>;
  public hasOwnVehicle: Subscription;

  inputData: any;
  navigationSubs: Subscription;

  public buttonType;
  public buttonAttributeType: any;

  constructor(private store: Store<AppState>, private router: Router, public dialogRef: MatDialogRef<JobseekerPersonalInfoEditForAdminComponent>) {
    this.buttonType = ButtonTypeEnum;
    this.buttonAttributeType = ButtonTypeAttributeEnum;

    this.editForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, Validators.pattern(Patterns.jobseekerName)]),
      surname: new UntypedFormControl('', [Validators.required, Validators.pattern(Patterns.jobseekerName)]),
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(Patterns.emailNew)]),
      phone: new UntypedFormControl(null, [Validators.required, Validators.pattern(Patterns.phoneNumber)]),
      phone2: new UntypedFormControl(null, Validators.pattern(Patterns.phoneNumber)),
      address: new UntypedFormControl(null, Validators.pattern(Patterns.address)),
      gender: new UntypedFormControl(null),
      city: new UntypedFormControl(null),
      drivingLicenses: new UntypedFormControl([]),
      ownVehicle: new UntypedFormControl(null),
      birthMonth: new UntypedFormControl(null),
      birthYear: new UntypedFormControl(null),
      birthDay: new UntypedFormControl(null),
      extendedLocationPath: new UntypedFormControl(null, [Validators.required]),
      jobseekerVehicles:  new UntypedFormControl([])
    },{
      validators: [this.validateJobseekerVehicles]
    });

    this.navigationSubs = this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.dialogRef.close();
      }
    });
  }

  validateJobseekerVehicles(group : UntypedFormGroup) : ValidationErrors | null{
    if (group) {
      const hashOwnVehicle = group.controls['ownVehicle'].value;
      const vehicles: number[] = group.controls['jobseekerVehicles'].value;

      if(hashOwnVehicle && vehicles.length == 0)
      {
        return { fillVehicleType : true };
      }
    }
    return null;
  }
  
  ngOnInit(): void {
    this.locations$ = this.store.pipe(select(selectLocalities));
    this.genderList$ = this.store.pipe(select(selectGenderList));
    this.drivingLicenseList$ = this.store.pipe(select(selectDrivingLicenseList));
    this.monthList$ = this.store.pipe(select(selectMonthList));
    this.yearListBirth$ = this.store.pipe(select(selectYearListBirth));
    this.selectPersonalInfo$ = this.store.pipe(select(selectPersonalInfo));
    this.isUpdating$ = this.store.pipe(select(selectIsJobseekerProfileUpdating));
    this.days$ = this.store.pipe(select(selectDaysList));
    this.sameAsCurrentAddress$ = this.store.pipe(select(selectSameAsCurrentAddress));
    this.legalAddress$ = this.store.pipe(select(selectLegalAddress));
    this.address$ = this.store.pipe(select(selectAddress));
    this.vehicleTypes$ = this.store.pipe(select(selectVehicleTypes));
    this.jobseekerId$ = this.store.pipe(select(selectJobseekerId));

    this.moderationLastChangeDate$ = this.store.pipe(select(selectJobseekerModerationLastChangeDate));

    this.locationLevelCount$ = this.store.pipe(select(selectLocationLevelCount));
    this.locationRequiredLevelCount$ = this.store.pipe(select(selectLocationRequiredLevelCount));
    this.locationLastHiddenLevelCount$ = this.store.pipe(select(selectLocationLastHiddenLevelCount));
    this.locationFirstLevelId$ = this.store.pipe(select(selectLocationFirstLevelId));
    
    this.vehicleTypes$ = this.store.pipe(select(selectVehicleTypes));

    this.hasOwnVehicle = this.store.pipe(select(selectPersonInfoHasOwnVehicle),take(1)).subscribe((c) => {
      const hasOwnVehicle = this.editForm.get('jobseekerVehicles') as FormControl;
      if(c){
        hasOwnVehicle.enable();
      }else{
        hasOwnVehicle.disable();
      }
    });
  }

  onSave(sameAsCurrentAddress: boolean, legalAddress: string, jobseekerId: number, moderationLastChangeDate: Date){ 
    if(this.editForm.valid){
      var extendedLocations = this.editForm.get('extendedLocationPath').value.locations.filter(c => c.locationId != null);
      let updateData = <JobseekerPersonalInfoModel>{
        jobseekerId: jobseekerId,
        name: this.editForm.get('name').value,
        surname: this.editForm.get('surname').value,
        email: this.editForm.get('email').value,
        phone: this.editForm.get('phone').value,
        phone2: this.editForm.get('phone2').value,
        address: this.editForm.get('address').value,
        legalAddress: legalAddress,
        sameAsCurrentAddress: sameAsCurrentAddress,
        gender: this.editForm.get('gender').value,
        city: this.editForm.get('city').value,
        drivingLicenses: this.editForm.get('drivingLicenses').value,
        ownVehicle: this.editForm.get('ownVehicle').value,
        birthMonth: this.editForm.get('birthMonth').value,
        birthYear: this.editForm.get('birthYear').value,
        birthDay: this.editForm.get('birthDay').value,
        extendedLocationId: extendedLocations[extendedLocations.length - 1].locationId,
        jobseekerVehicles: this.editForm.get('jobseekerVehicles').value,
      }
      this.store.dispatch(editJobseekerPersonalInfoForAdmin({payload: { jobseekerPersonalInfoModel: updateData, moderationLastChangeDate: moderationLastChangeDate }}));
    }
  }

  onChangeOwnVehicle(){
    const hasOwnVehicle = this.editForm.controls['ownVehicle'].value;
    let jobseekerVehicles = this.editForm.controls['jobseekerVehicles'];

    if(!hasOwnVehicle){
      jobseekerVehicles.disable();
      jobseekerVehicles.setValue([]);
    }else{
      jobseekerVehicles.enable();
    }
  }

  updateJobseekerAddress(e: any){
    this.store.dispatch(updateJobseekerAddress({ payload: e.target.value }));
    
    if(e.target.value === ''){
      this.store.dispatch(updateSameAsCurrentAddress({ payload: false }));  
    }
  }

  updateSameAsCurrentAddress(checked: boolean){
    this.store.dispatch(updateSameAsCurrentAddress({ payload: checked }));  
  }

  updateJobseekerLegalAddress(e: any){
    this.store.dispatch(updateJobseekerLegalAddress({ payload: e.target.value }))
  }

  onSelectMonth(){
    let month = this.editForm.get('birthMonth').value;
    this.store.dispatch(setSelectedBirthMonth({payload: month}));
  }

  onSelectYear(){
    let year = this.editForm.get('birthYear').value;
    this.store.dispatch(setSelectedBirthYear({payload: year}));
  }

  ngOnDestroy() {
    this.navigationSubs.unsubscribe();
    this.hasOwnVehicle.unsubscribe();
  }
}
