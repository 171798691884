import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable, Subscription } from 'rxjs';
import { ButtonTypeAttributeEnum } from '@hrra/ui';
import { ButtonTypeEnum } from '@hrra/ui';
import { approveJobseekerPhotoForAdmin, editJobseekerPhotoForAdmin, insertCroppedImageForAdmin, selectJobseekerPhotoForAdmin } from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/actions';
import { selectPhotoIsSelected, selectBase64, selectIsPhotoApproved, selectIsJobseekerProfileUpdating, selectJobseekerModerationLastChangeDate, selectJobseekerId } from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/selectors';
import { AppState } from 'src/app/root-store/state';

@Component({
  selector: 'app-jobseeker-photo-edit-for-admin',
  templateUrl: './jobseeker-photo-edit-for-admin.component.html',
  styleUrls: ['./jobseeker-photo-edit-for-admin.component.scss']
})
export class JobseekerPhotoEditForAdminComponent implements OnInit {

  @ViewChild('hiddenfileinput') fileInput: any;
  
  navigationSubs: Subscription;

  public photoHref: string;
  public isPhotoPending: boolean;
  public buttonType: any;
  public buttonAttributeType: any;

  photoIsSelected$: Observable<boolean>;
  photoIsApproved$: Observable<boolean>;
  base64$: Observable<string>;
  isUpdating$: Observable<boolean>;
  moderationLastChangeDate$: Observable<Date>;
  jobseekerId$: Observable<number>;

  imageChangedEvent: any = '';

  constructor(private store: Store<AppState>, @Inject(MAT_DIALOG_DATA) public data, private router: Router, public dialogRef: MatDialogRef<JobseekerPhotoEditForAdminComponent>) {
    this.buttonType = ButtonTypeEnum;
    this.buttonAttributeType = ButtonTypeAttributeEnum;

    this.navigationSubs = this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.dialogRef.close();
      }
    });
   }

  ngOnInit(): void {
    this.photoHref = this.data.photoHref;
    this.isPhotoPending = this.data.isPhotoPending;

    this.photoIsSelected$ = this.store.pipe(select(selectPhotoIsSelected));
    this.photoIsApproved$ = this.store.pipe(select(selectIsPhotoApproved));
    this.base64$ = this.store.pipe(select(selectBase64));
    this.isUpdating$ = this.store.pipe(select(selectIsJobseekerProfileUpdating));
    this.moderationLastChangeDate$ = this.store.pipe(select(selectJobseekerModerationLastChangeDate));
    this.jobseekerId$ = this.store.pipe(select(selectJobseekerId));
  }
  
  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
      this.store.dispatch(selectJobseekerPhotoForAdmin());
  }

  imageCropped(event: ImageCroppedEvent) {
      this.store.dispatch(insertCroppedImageForAdmin({payload: {base64: event.base64}}));
      this.fileInput.nativeElement.value = '';
  }

  onSave(base64: string, moderationLastChangeDate: Date, jobseeekerId: number){
    if(base64 == null){
      this.dialogRef.close();
    }
    else{
      let result = base64.replace("data:image/png;base64,", "");
      this.store.dispatch(editJobseekerPhotoForAdmin({payload: { jobseekerPhotoModel: result, moderationLastChangeDate: moderationLastChangeDate, jobseekerId: jobseeekerId }}));
    }
  }

  onApprove(){
    this.store.dispatch(approveJobseekerPhotoForAdmin());
  }

  ngOnDestroy() {
    this.navigationSubs.unsubscribe();
  }
}