<div class="content">
    <div class="content--right">
        <div class="work-dates">
            <span>{{workExperience.dateFromMonth == null ? workExperience.dateFromYear : (workExperience.experienceDateFrom | date:'y MMMM':timeZone:currentLang)}} - </span>
            <span *ngIf="!workExperience.isCurrent">{{workExperience.dateToMonth == null ? workExperience.dateToYear : (workExperience.experienceDateTo | date:'y MMMM':timeZone:currentLang)}}</span>
            <span *ngIf="workExperience.isCurrent">{{'label.Present' | translate}}</span>
        </div>
        <div class="duration">
            <span *ngIf="workExperience.workExperienceDuration.year != 0">{{workExperience.workExperienceDuration.year}} {{'label.Year' | translate:{ count: workExperience.workExperienceDuration.year } }} </span>
            <span *ngIf="workExperience.workExperienceDuration.month != 0">{{workExperience.workExperienceDuration.month}} {{'label.Month' | translate:{ count: workExperience.workExperienceDuration.month } }}</span>
        </div>
    
        <div class="buttons">
            <app-icon-button *ngIf="userType === type.Jobseeker || userType === type.Internal" (click)="onEdit(workExperience)" [iconButtonType]="iconButtonType.edit" class="buttons__edit"></app-icon-button>
            <app-icon-button *ngIf="userType === type.Jobseeker || userType === type.Internal" (click)="onDelete(workExperience.jobseekerWorkExperienceId, workExperience.objectId)" [iconButtonType]="iconButtonType.delete" class="buttons__delete"></app-icon-button>
        </div>
    </div>

    <app-icon *ngIf="workExperience.isInModeration && (userType === type.Jobseeker || userType === type.Internal)" [tooltipText]="'label.IsInModeration' | translate"  [iconButtonType]="iconButtonType.moderation" class="mod-icon mod-icon--desktop"></app-icon>
    <div class="position">
        <app-icon *ngIf="workExperience.isInModeration" [tooltipText]="'label.IsInModeration' | translate"  [iconButtonType]="iconButtonType.moderation" class="mod-icon mod-icon--mobile"></app-icon>
        <span>{{workExperience.positionTitle}}</span>
    </div>

    <div class="info">
        <div class="info__employer">{{workExperience.employer}}</div>
        <div *ngFor="let headCategories of workExperience.groupedCategories" class="info__industry">
            <span class="head">{{headCategories.head}}: </span>
            <span class="sub">
                <span *ngFor="let categories of headCategories.subcategories, let last = last">
                    {{categories}}{{last ? '' : '; '}}
                </span>
            </span>
        </div>
        <div class="info__description" [ngClass]="{'info__description--filled' : workExperience.description != null}">{{workExperience.description}}</div>
    </div>

    <div *ngIf="workExperience.attachment != null" class="attachment" [ngClass]="{'attachment--filled' : workExperience.attachment}">
        <app-advanced-button (click)="downloadAttachment(workExperience.objectId)" [iconType]="iconButtonType.attachment" [text]="'label.Attachment' | translate" [type]="'blue'" class="add-button"></app-advanced-button>
    </div>
</div>
