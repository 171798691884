<div class="container">
    <div class="language">
        <div class="language__name">{{language.worldLanguage.name}}</div>
        <div class="language__level">{{language.languageProficiencyLevel.name}}</div>
    </div>
    <div class="attachment">
        <app-icon *ngIf="languageIsInMod" [tooltipText]="'label.IsInModeration' | translate" [iconButtonType]="iconButtonType.moderation" class="attachment__mod-icon"></app-icon>
        <app-advanced-button *ngIf="language.attachment" (click)="downloadAttachment(language.objectId)" [iconType]="iconButtonType.attachment" [text]="'label.Attachment' | translate" [type]="'blue'"></app-advanced-button>
    </div>
    <app-icon-button *ngIf="userType === type.Jobseeker || userType === type.Internal" [iconButtonType]="iconButtonType.edit" class="button-edit" (click)="onEdit(language.jobseekerLanguageId, language.objectId)"></app-icon-button>
    <app-icon-button *ngIf="userType === type.Jobseeker || userType === type.Internal" (click)="onDelete(language.jobseekerLanguageId, language.objectId)" [iconButtonType]="iconButtonType.delete" class="button-delete"></app-icon-button>
</div>
