import { JobseekerProfileState } from "./state";
import { createImmerReducer } from 'ngrx-immer/store';
import * as JobseekerProfileActions from './actions';
import { on, Action } from '@ngrx/store';
import { JobseekerEducationModel } from 'src/app/jobseeker/shared/services/jobseeker.service';
import * as RootActions from "../../actions";
import { state } from "@angular/animations";
import { EducationLevelEnum } from "@hrra/common-models";

let initialState: JobseekerProfileState = {
    jobseeker: null,
    loading: false,
    updateLoading: false,
    attachmentLoading: false,
    fileUploading: false,
    file: null,
    isFileSizeValid: true,

    educationUiState: {
        selectedJobseekerEducation: null,
        isInstitutionSelectModeOn: null,
        isProgramSelectModeOn: null,
    },

    workExperienceUiState: {
        workExperience: null,
        currentSelectedCategories: [],
        isCurrent: false
    },
    
    photoState: {
        isPhotoSelected: false,
        isPhotoApproved: false,
        base64: null
    },

    personalInfoState: {
        selectedMonth: null,
        selectedYear: null,
        selectedAddress: null,
        selectedLegalAddress: null,
        selectedSameAsCurrentAddress: null
    },
    
    cvExportState: {
        loading: false,
        cvExportFailureDetails: null
    }
}

const reducer = createImmerReducer(
    initialState,
    on(JobseekerProfileActions.loadJobseekerProfileForAdmin, state =>{
        state.loading = true;
        return state;
    }),
    on(JobseekerProfileActions.loadJobseekerProfileForAdminSuccess, (state, {payload}) => {
        state.jobseeker = payload;
        state.personalInfoState.selectedMonth = payload.birthMonth;
        state.personalInfoState.selectedYear = payload.birthYear;
        state.personalInfoState.selectedAddress = payload.address;
        state.personalInfoState.selectedLegalAddress = payload.legalAddress;
        state.personalInfoState.selectedSameAsCurrentAddress = payload.sameAsCurrentAddress;
        state.loading = false;
        return state;
    }),
    on(JobseekerProfileActions.loadJobseekerProfileForAdminFail, (state, {payload}) => {
        state.loading = false;
        return state;
    }),

    on(RootActions.logoutSuccess, (state, _) => {
        Object.assign(state, initialState);
        return state;
    }),

    //delete
    on(JobseekerProfileActions.deleteJobseekerDocument, (state, {payload}) => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerDocumentSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerDocumentFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),

    on(JobseekerProfileActions.deleteJobseekerLanguage, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerLanguageSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerLanguageFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),

    on(JobseekerProfileActions.deleteJobseekerEducationForAdmin, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerEducationForAdminSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerEducationForAdminFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),

    on(JobseekerProfileActions.deleteJobseekerWorkExperienceForAdmin, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerWorkExperienceForAdminSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerWorkExperienceForAdminFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),

    on(JobseekerProfileActions.deleteJobseekerPhoto, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerPhotoSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerPhotoFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),


    //downloads
    on(JobseekerProfileActions.downloadEducationAttachmentForAdmin, state => {
        state.attachmentLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.downloadEducationAttachmentForAdminSuccess, state => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadEducationAttachmentForAdminFail, (state, {payload}) => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadLanguageAttachmentForAdmin, state => {
        state.attachmentLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.downloadLanguageAttachmentForAdminSuccess, state => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadLanguageAttachmentForAdminFail, (state, {payload}) => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadDocumentAttachmentFromAdmin, state => {
        state.attachmentLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.downloadDocumentAttachmentFromAdminSuccess, state => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadDocumentAttachmentFromAdminFail, (state, {payload}) => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadWorkExperienceAttachmentForAdmin, state => {
        state.attachmentLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.downloadWorkExperienceAttachmentForAdminSuccess, state => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadWorkExpeienceAttachmentForAdminFail, (state, {payload}) => {
        state.attachmentLoading = false;
        return state;
    }),


    //edit jobseeker
    on(JobseekerProfileActions.editJobseekerPersonalInfoForAdmin, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerPersonalInfoForAdminSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerPersonalInfoForAdminFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),

    
    on(JobseekerProfileActions.editJobseekerEducationForAdmin, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerEducationForAdminSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerEducationForAdminFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.selectJobseekerEducationForAdmin, (state, {payload}) => {
        if(payload.educationId == null){
            let x: JobseekerEducationModel = <any>{};
            
            x.jobseekerEducationId = null;
            x.educationLevelId = null;
            x.educationalInstitutionId = null;
            x.educationalProgramId = null;
            x.educationalProgramName = null;
            x.institution = null;
            x.specialization = null;
            x.dateFromYear = null;
            x.dateFromMonth = null;
            x.dateToYear = null;
            x.dateToMonth = null;
            x.description = null;
            x.attachment = null;
            x.objectId = null;

            state.educationUiState.selectedJobseekerEducation = x;
        }

        else{
            let education = state.jobseeker.pendingModifiedJobseekerEducations.find(e => e.jobseekerEducationId == payload.educationId) ||
            state.jobseeker.jobseekerEducations.find(e => e.jobseekerEducationId == payload.educationId);

            let x: JobseekerEducationModel = <any>{};
            x.jobseekerEducationId = education?.jobseekerEducationId;
            x.educationLevelId = education?.educationLevel?.educationLevelId;
            x.educationalInstitutionId = education?.educationalInstitution?.educationalInstitutionId;
            x.educationalProgramId = education?.educationalProgram?.educationalProgramId;
            x.educationalProgramName = education?.educationalProgramName;
            x.institution = education?.institution;
            x.specialization = education?.specialization;
            x.dateFromYear = education?.dateFromYear;
            x.dateFromMonth = education?.dateFromMonth;
            x.dateToYear = education?.dateToYear;
            x.dateToMonth = education?.dateToMonth;
            x.description = education?.description;
            x.attachment = education?.attachment;
            x.objectId = education?.objectId;

            state.educationUiState.selectedJobseekerEducation = x;

            state.educationUiState.isInstitutionSelectModeOn = education.educationalInstitution != null;
            state.educationUiState.isProgramSelectModeOn = education.educationalProgram != null;
        }

        return state;
    }),
    on(JobseekerProfileActions.selectJobseekerEducationLevelForAdmin, (state, {payload}) => {
        state.educationUiState.selectedJobseekerEducation.educationLevelId = payload.levelId;
        if(payload.levelId == EducationLevelEnum.Bachelor || payload.levelId == EducationLevelEnum.Master || payload.levelId == EducationLevelEnum.Doctor || payload.levelId == EducationLevelEnum.College){
            state.educationUiState.isInstitutionSelectModeOn = true;
            state.educationUiState.isProgramSelectModeOn = true;
        }
        else{
            state.educationUiState.isInstitutionSelectModeOn = false;
            state.educationUiState.isProgramSelectModeOn = false;
        }
        return state;
    }),
    on(JobseekerProfileActions.institutionSelectModeForAdmin, state => {
        state.educationUiState.isInstitutionSelectModeOn = !state.educationUiState.isInstitutionSelectModeOn;
        if(!state.educationUiState.isInstitutionSelectModeOn){
            state.educationUiState.isProgramSelectModeOn = false;
        }
        return state;
    }),
    on(JobseekerProfileActions.programSelectModeForAdmin, state => {
        let programMode = state.educationUiState.isProgramSelectModeOn;
        state.educationUiState.isProgramSelectModeOn = !programMode;
        if(!programMode){
            state.educationUiState.isInstitutionSelectModeOn = true;
        }
        return state;
    }),
    on(JobseekerProfileActions.selectJobseekerEdcuationalInstitutionForAdmin, (state, {payload}) => {
        state.educationUiState.selectedJobseekerEducation.educationalInstitutionId = payload.institutionId;
        return state;
    }),
    on(JobseekerProfileActions.closeJobseekerEditEducationFormForAdmin, state => {
        state.educationUiState.selectedJobseekerEducation = null;
        state.educationUiState.isProgramSelectModeOn = null;
        state.educationUiState.isInstitutionSelectModeOn = null;
        state.file = null;
        state.isFileSizeValid = true;
        state.fileUploading = false;
        return state;
    }),

    on(JobseekerProfileActions.editJobseekerLanguageForAdmin, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerLanguageForAdminSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerLanguageForAdminFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.closeJobseekerEditLanguageFormForAdmin, state => {
        state.file = null;
        state.isFileSizeValid = true;
        state.fileUploading = false;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerWorkExperienceForAdmin, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerWorkExperienceForAdminSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerWorkExperienceForAdminFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.setJobseekerWorkExperienceForAdmin, (state, { payload }) => {
        state.workExperienceUiState.workExperience = payload;

        if (payload != null){
            state.workExperienceUiState.currentSelectedCategories = payload?.categories.map(e => e.category.id);
            state.workExperienceUiState.isCurrent = payload?.isCurrent;
        }
        
        return state;
    }),
    on(JobseekerProfileActions.setSelectedCategoriesForAdmin, (state, { payload }) => {
        state.workExperienceUiState.currentSelectedCategories = payload;
        return state;
    }),
    on(JobseekerProfileActions.closeWorkExperienceEditFormForAdmin, state => {
        state.workExperienceUiState.currentSelectedCategories = [];
        state.workExperienceUiState.isCurrent = false;
        state.workExperienceUiState.workExperience = null;
        state.file = null;
        state.isFileSizeValid = true;
        state.fileUploading = false;
        return state;
    }),
    on(JobseekerProfileActions.changeWorkExperienceIsCurrentForAdmin, state => {
        state.workExperienceUiState.isCurrent = !state.workExperienceUiState.isCurrent;
        return state;
    }),
    on(JobseekerProfileActions.setIsWorkExperienceFilledForAdmin, (state, { payload }) => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.setIsWorkExperienceFilledForAdminSuccess, (state, { payload }) => {
        state.jobseeker = payload;
        state.updateLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.setIsWorkExperienceFilledForAdminFail, (state, { payload }) => {
        state.updateLoading = false;
        return state;
    }),

    //file upload
    on(JobseekerProfileActions.uploadTempFileForAdmin, (state, {payload}) => {
        state.fileUploading = true;
        return state;
    }),
    on(JobseekerProfileActions.uploadTempFileForAdminSuccess, (state, {payload}) => {
        state.fileUploading = false;
        state.file = payload;
        return state;
    }),
    on(JobseekerProfileActions.uploadTempFileForAdminFail, (state, {payload}) => {
        state.fileUploading = false;
        return state;
    }),
    on(JobseekerProfileActions.closeJobseekerUploadDocumentFormForAdmin, state => {
        state.file = null;
        state.isFileSizeValid = true;
        state.fileUploading = false;
        return state;
    }),
    on(JobseekerProfileActions.setFileSizeValidation, (state, { payload }) => {
        state.isFileSizeValid = payload;
        if(!payload){
            state.file = null
        }
        return state;
    }),

    //document upload
    on(JobseekerProfileActions.uploadJobseekerDocumentForAdmin, (state, {payload}) => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.uploadJobseekerDocumentForAdminSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.uploadJobseekerDocumentForAdminFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),

    //edit photo
    on(JobseekerProfileActions.editJobseekerPhotoForAdmin, (state, { payload }) => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerPhotoForAdminSuccess, (state, { payload }) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerPhotoForAdminFail, (state, { payload }) => {
        state.updateLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.insertCroppedImageForAdmin, (state, { payload }) => {
        state.photoState.base64 = payload.base64;
        return state;
    }),
    on(JobseekerProfileActions.selectJobseekerPhotoForAdmin, state => {
        state.photoState.isPhotoSelected = true;
        return state;
    }),
    on(JobseekerProfileActions.closeJobseekerPhotoEditFormForAdmin, state => {
        state.photoState.isPhotoApproved = false;
        state.photoState.isPhotoSelected = false;
        state.photoState.base64 = null;
        return state;
    }),
    on(JobseekerProfileActions.approveJobseekerPhotoForAdmin, state => {
        state.photoState.isPhotoApproved = true;
        state.photoState.isPhotoSelected = false;
        return state;
    }),
    on(JobseekerProfileActions.setSelectedBirthMonth, (state, { payload }) => {
        state.personalInfoState.selectedMonth = payload;
        return state;
    }),
    on(JobseekerProfileActions.setSelectedBirthYear, (state, { payload }) => {
        state.personalInfoState.selectedYear = payload;
        return state;
    }),
    on(JobseekerProfileActions.updateJobseekerAddress, (state, {payload}) => {
        state.personalInfoState.selectedAddress = payload;
        return state;
    }),
    on(JobseekerProfileActions.updateSameAsCurrentAddress, (state, {payload}) => {
        state.personalInfoState.selectedSameAsCurrentAddress = payload;
        return state;
    }),
    on(JobseekerProfileActions.updateJobseekerLegalAddress, (state, {payload}) => {
        state.personalInfoState.selectedLegalAddress = payload;
        return state;
    }),

    //cvExport
    on(JobseekerProfileActions.jobseekerCvExport, state => {
        state.cvExportState.loading = true;
        return state;
    }),
    on(JobseekerProfileActions.jobseekerCvExportSuccess, state => {
        state.cvExportState.loading = false;
        return state;
    }),
    on(JobseekerProfileActions.jobseekerCvExportFailed, (state, { payload }) => {
        state.cvExportState.loading = false;
        state.cvExportState.cvExportFailureDetails = payload;
        return state;
    })
);

export function jobseekerProfileForAdminReducer(state: JobseekerProfileState | undefined, action: Action) {
    return reducer(state, action);
}

