import { createAction, props } from '@ngrx/store';
import { ApiError } from '@hrra/core';
import { ActionPayload } from "@hrra/core";
import { JobseekerPersonalInfoModel, JobseekerEducationModel, FileUploadResultViewModel, JobseekerLanguageModel, JobseekerDocumentViewModel } from 'src/app/jobseeker/shared/services/jobseeker.service';
import { Jobseeker, JobseekerWorkExperienceViewModel } from '@hrra/common-models';


export enum Actions{
    LoadJobseekerProfile = '[Jobseeker profile] Load jobseeker profile',
    LoadJobseekerProfileSuccess = '[Jobseeker profile] Load jobseeker profile success',
    LoadJobseekerProfileFail = '[Jobseeker profile] Load jobseeker profile fail',

    DeleteJobseekerDocument ='[Jobseeker profile] Delete jobseeker document',
    DeleteJobseekerDocumentSuccess ='[Jobseeker profile] Delete jobseeker document success',
    DeleteJobseekerDocumentFail ='[Jobseeker profile] Delete jobseeker document fail',
  
    DeleteJobseekerLanguage ='[Jobseeker profile] Delete jobseeker language',
    DeleteJobseekerLanguageSuccess ='[Jobseeker profile] Delete jobseeker language success',
    DeleteJobseekerLanguageFail ='[Jobseeker profile] Delete jobseeker language fail',

    DeleteJobseekerEducation ='[Jobseeker profile] Delete education education',
    DeleteJobseekerEducationSuccess ='[Jobseeker profile] Delete jobseeker education success',
    DeleteJobseekerEducationFail ='[Jobseeker profile] Delete jobseeker education fail',

    DeleteJobseekerWorkExperience ='[Jobseeker profile] Delete jobseeker work experience',
    DeleteJobseekerWorkExperienceSuccess ='[Jobseeker profile] Delete jobseeker work experience success',
    DeleteJobseekerWorkExperienceFail ='[Jobseeker profile] Delete jobseeker work experience fail',

    DeleteJobseekerPhoto ='[Jobseeker profile] Delete jobseeker photo',
    DeleteJobseekerPhotoSuccess ='[Jobseeker profile] Delete jobseeker photo success',
    DeleteJobseekerPhotoFail ='[Jobseeker profile] Delete jobseeker photo fail',

    DownloadEducationAttachment = '[Jobseeke profile] Download education attachment',
    DownloadEducationAttachmentSuccess = '[Jobseeker profile] Download education attachment success',
    DownloadEducationAttachmentFail = '[Jobseeker profile] Download education attachment fail',

    DownloadLanguageAttachment = '[Jobseeke profile] Download language attachment',
    DownloadLanguageAttachmentSuccess = '[Jobseeker profile] Download language attachment success',
    DownloadLanguageAttachmentFail = '[Jobseeker profile] Download language attachment fail',

    DownloadDocumentAttachment = '[Jobseeke profile] Download document attachment',
    DownloadDocumentAttachmentSuccess = '[Jobseeker profile] Download document attachment success',
    DownloadDocumentAttachmentFail = '[Jobseeker profile] Download document attachment fail',

    DownloadWorkExperienceAttachment = '[Jobseeke profile] Download work experience attachment',
    DownloadWorkExperienceAttachmentSuccess = '[Jobseeker profile] Download work experience attachment success',
    DownloadWorkExperienceAttachmentFail = '[Jobseeker profile] Download work experience attachment fail',

    EditJobseekerPersonalInfo ='[Jobseeker profile] Edit jobseeker personal info',
    EditJobseekerPersonalInfoSuccess ='[Jobseeker profile] Edit jobseeker personal info success',
    EditJobseekerPersonalInfoFail ='[Jobseeker profile] Edit jobseeker personal info fail',


    //UploadTempFile
    UploadTempFile = '[Jobseeker profile] Upload temp file',
    UploadTempFileSuccess = '[Jobseeker profile] Upload temp file success',
    UploadTempFileFail = '[Jobseeker profile] Upload temp file fail',

    SetFileSizeValidation = '[Jobseeker profile] Set file size validation',

    //add/edit education
    EditJobseekerEducation = '[Jobseeker profile] Edit jobseeker education',
    EditJobseekerEducationSuccess = '[Jobseeker profile] Edit jobseeker education success',
    EditJobseekerEducationFail = '[Jobseeker profile] Edit jobseeker education fail',

    SelectJobseekerEducation = '[Jobseeker profile] selectJobseekerEducation',
    SelectJobseekerEducationLevel = '[Jobseeker profile] Select education level',
    InstitutionSelectMode = '[Jobseeker profile] University select mod',
    ProgramSelectMode = '[Jobseeker profile] Program select mod',
    SelectJobseekerEdcuationalInstitution = '[Jobseeker profile] Select educational institution',
    CloseJobseekerEditEducationForm = '[Jobseeker profile] Close Education Form',

    //add/edit language
    EditJobseekerLanguage = '[Jobseeker profile] Edit jobseeker language',
    EditJobseekerLanguageSuccess = '[Jobseeker profile] Edit jobseeker language success',
    EditJobseekerLanguageFail = '[Jobseeker profile] Edit jobseeker language fail',

    CloseJobseekerEditLanguageForm = '[Jobseeker profile] Close language Form',

    //upload document
    UploadJobseekerDocument = '[Jobseeker profile] Upload jobseeker document',
    UploadJobseekerDocumentSuccess = '[Jobseeker profile] Upload jobseeker document success',
    UploadJobseekerDocumentFail = '[Jobseeker profile] Upload jobseeker document fail',

    CloseJobseekerUploadDocumentForm = '[Jobseeker profile] Close upload document Form',

    //add/edit work experience
    EditJobseekerWorkExperience = '[Jobseeker profile] Edit jobseeker work experience',
    EditJobseekerWorkExperienceSuccess = '[Jobseeker profile] Edit jobseeker work experience success',
    EditJobseekerWorkExperienceFail = '[Jobseeker profile] Edit jobseeker work experience fail',

    SetIsWorkExperienceFilled = '[Jobseeker profile] Set is work experience filled',
    SetIsWorkExperienceFilledSuccess = '[Jobseeker profile] Set is work experience filled success',
    SetIsWorkExperienceFilledFail = '[Jobseeker profile] Set is work experience filled fail',

    SetSelectedCategories = '[Jobseeker profile] Set selected categories',
    SetJobseekerWorkExperience = '[Jobseeker profile] Set jobseeker work experience',
    CloseWorkExperienceEditForm = '[Jobseeker profile] Close work experience edit form',
    ChangeWorkExperienceIsCurrent = '[Jobseeker profile] Close work experience is current',

    //add/edit photo
    EditJobseekePhoto = '[Jobseeker profile] Edit jobseeker photo',
    EditJobseekerPhotoSuccess = '[Jobseeker profile] Edit jobseeker photo success',
    EditJobseekerPhotoFail = '[Jobseeker profile] Edit jobseeker photo fail',
    
    SelectJobeekerPhoto = '[Jobseeker profile] Select jobseeker photo',
    CloseJobseekerPhotoEditForm = '[Jobseeker profile] Close jobseeker photo edit form',
    ApproveJobseekerPhoto = '[Jobseeker profile] Approve jobseeker photo',
    InsertCroppedImage = '[Jobseeker profile] Insert cropped image',

    //birthdate
    SetSelectedBirthMonth = '[Jobseeker profile] Set selected birth month',
    SetSelectedBirthYear = '[Jobseeker profile] Set selected birth year',

    UpdateJobseekerAddress = '[Jobseeker profile] Update Jobseeker Address',
    UpdateSameAsCurrentAddress = '[Jobseeker profile] Update Same As Current Address',
    UpdateJobseekerLegalAddress = '[Jobseeker profile] Update Jobseeker Legal Address',

    //CV export
    JobseekerCvExport = '[jobseeker profile] Jobseeker cv export',
    JobseekerCvExportSuccess = '[jobseeker profile] Jobseeker cv export success',
    JobseekerCvExportFailed = '[jobseeker profile] Load jobseeker cv export failed'
}


export const uploadTempFile = createAction(
    Actions.UploadTempFile,
    props<ActionPayload<FormData>>()
);

export const uploadTempFileSuccess = createAction(
    Actions.UploadTempFileSuccess,
    props<ActionPayload<FileUploadResultViewModel>>()
);

export const uploadTempFileFail = createAction(
    Actions.UploadTempFileFail,
    props<ActionPayload<ApiError>>()
);

export const setFileSizeValidation = createAction(
    Actions.SetFileSizeValidation,
    props<ActionPayload<boolean>>()
);

export const loadJobseekerProfile = createAction(
    Actions.LoadJobseekerProfile,
);

export const loadJobseekerProfileSuccess = createAction(
    Actions.LoadJobseekerProfileSuccess,
    props<ActionPayload<Jobseeker>>()
);

export const loadJobseekerProfileFail = createAction(
    Actions.LoadJobseekerProfileFail,
    props<ActionPayload<ApiError>>()
);

//#region  delete actions
export const deleteJobseekerDocument = createAction(
    Actions.DeleteJobseekerDocument,
    props<ActionPayload<{documentId: number, moderationLastChangeDate:Date, objectId:string}>>()
);
export const deleteJobseekerDocumentSuccess = createAction(
    Actions.DeleteJobseekerDocumentSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const deleteJobseekerDocumentFail = createAction(
    Actions.DeleteJobseekerDocumentFail,
    props<ActionPayload<ApiError>>()
);


export const deleteJobseekerLanguage = createAction(
    Actions.DeleteJobseekerLanguage,
    props<ActionPayload<{languageId: number, moderationLastChangeDate:Date, objectId:string}>>()
);
export const deleteJobseekerLanguageSuccess = createAction(
    Actions.DeleteJobseekerLanguageSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const deleteJobseekerLanguageFail = createAction(
    Actions.DeleteJobseekerLanguageFail,
    props<ActionPayload<ApiError>>()
);


export const deleteJobseekerEducation = createAction(
    Actions.DeleteJobseekerEducation,
    props<ActionPayload<{educationId: number, moderationLastChangeDate:Date, objectId:string}>>()
);
export const deleteJobseekerEducationSuccess = createAction(
    Actions.DeleteJobseekerEducationSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const deleteJobseekerEducationFail = createAction(
    Actions.DeleteJobseekerEducationFail,
    props<ActionPayload<ApiError>>()
);


export const deleteJobseekerWorkExperience = createAction(
    Actions.DeleteJobseekerWorkExperience,
    props<ActionPayload<{experienceId: number, moderationLastChangeDate:Date, objectId:string}>>()
);
export const deleteJobseekerWorkExperienceSuccess = createAction(
    Actions.DeleteJobseekerWorkExperienceSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const deleteJobseekerWorkExperienceFail = createAction(
    Actions.DeleteJobseekerWorkExperienceFail,
    props<ActionPayload<ApiError>>()
);

export const deleteJobseekerPhoto = createAction(
    Actions.DeleteJobseekerPhoto,
    props<ActionPayload<{moderationLastChangeDate:Date}>>()
);
export const deleteJobseekerPhotoSuccess = createAction(
    Actions.DeleteJobseekerPhotoSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const deleteJobseekerPhotoFail = createAction(
    Actions.DeleteJobseekerPhotoFail,
    props<ActionPayload<ApiError>>()
);
//#endregion


//#region  downloads
export const downloadEducationAttachment = createAction(
    Actions.DownloadEducationAttachment,
    props<ActionPayload<{jobseekerEducationObjectId: string}>>()
);

export const downloadEducationAttachmentSuccess = createAction(
    Actions.DownloadEducationAttachmentSuccess
);

export const downloadEducationAttachmentFail = createAction(
    Actions.DownloadEducationAttachmentFail,
    props<ActionPayload<ApiError>>()
);


export const downloadLanguageAttachment = createAction(
    Actions.DownloadLanguageAttachment,
    props<ActionPayload<{jobseekerLanguageObjectId: string}>>()
);

export const downloadLanguageAttachmentSuccess = createAction(
    Actions.DownloadLanguageAttachmentSuccess
);

export const downloadLanguageAttachmentFail = createAction(
    Actions.DownloadLanguageAttachmentFail,
    props<ActionPayload<ApiError>>()
);


export const downloadDocumentAttachment = createAction(
    Actions.DownloadDocumentAttachment,
    props<ActionPayload<{documentObjectId: string}>>()
);

export const downloadDocumentAttachmentSuccess = createAction(
    Actions.DownloadDocumentAttachmentSuccess
);

export const downloadDocumentAttachmentFail = createAction(
    Actions.DownloadDocumentAttachmentFail,
    props<ActionPayload<ApiError>>()
);


export const downloadWorkExperienceAttachment = createAction(
    Actions.DownloadWorkExperienceAttachment,
    props<ActionPayload<{workExperienceObjectId: string}>>()
);

export const downloadWorkExperienceAttachmentSuccess = createAction(
    Actions.DownloadWorkExperienceAttachmentSuccess
);

export const downloadWorkExpeienceAttachmentFail = createAction(
    Actions.DownloadWorkExperienceAttachmentFail,
    props<ActionPayload<ApiError>>()
);
//#endregion


//#region add/edit
export const editJobseekerPersonalInfo = createAction(
    Actions.EditJobseekerPersonalInfo,
    props<ActionPayload<{jobseekerPersonalInfoModel:JobseekerPersonalInfoModel, moderationLastChangeDate:Date}>>()
);
export const editJobseekerPersonalInfoSuccess = createAction(
    Actions.EditJobseekerPersonalInfoSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const editJobseekerPersonalInfoFail = createAction(
    Actions.EditJobseekerPersonalInfoFail,
    props<ActionPayload<ApiError>>()
);

export const editJobseekerEducation = createAction(
    Actions.EditJobseekerEducation,
    props<ActionPayload<{jobseekerEducationalModel: JobseekerEducationModel, moderationLastChangeDate: Date}>>()
);
export const editJobseekerEducationSuccess = createAction(
    Actions.EditJobseekerEducationSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const editJobseekerEducationFail = createAction(
    Actions.EditJobseekerEducationFail,
    props<ActionPayload<ApiError>>()
);

export const selectJobseekerEducation = createAction(
    Actions.SelectJobseekerEducation,
    props<ActionPayload<{educationId: number}>>()
);

export const selectJobseekerEducationLevel = createAction(
    Actions.SelectJobseekerEducationLevel,
    props<ActionPayload<{levelId: number}>>()
);

export const institutionSelectMode = createAction(
    Actions.InstitutionSelectMode
);

export const programSelectMode = createAction(
    Actions.ProgramSelectMode
);

export const selectJobseekerEdcuationalInstitution = createAction(
    Actions.SelectJobseekerEdcuationalInstitution,
    props<ActionPayload<{institutionId: number}>>()
);

export const closeJobseekerEditEducationForm = createAction(
    Actions.CloseJobseekerEditEducationForm
);



export const editJobseekerLanguage = createAction(
    Actions.EditJobseekerLanguage,
    props<ActionPayload<{jobseekerLanguageModel: JobseekerLanguageModel, moderationLastChangeDate: Date}>>()
);

export const editJobseekerLanguageSuccess = createAction(
    Actions.EditJobseekerLanguageSuccess,
    props<ActionPayload<Jobseeker>>()
);

export const editJobseekerLanguageFail = createAction(
    Actions.EditJobseekerLanguageFail,
    props<ActionPayload<ApiError>>()
);

export const closeJobseekerEditLanguageForm = createAction(
    Actions.CloseJobseekerEditLanguageForm
);


export const uploadJobseekerDocument = createAction(
    Actions.UploadJobseekerDocument,
    props<ActionPayload<{jobseekerDocumentModel: JobseekerDocumentViewModel, moderationLastChangeDate: Date}>>()
);
export const uploadJobseekerDocumentSuccess = createAction(
    Actions.UploadJobseekerDocumentSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const uploadJobseekerDocumentFail = createAction(
    Actions.UploadJobseekerDocumentFail,
    props<ActionPayload<ApiError>>()
);
export const closeJobseekerUploadDocumentForm = createAction(
    Actions.CloseJobseekerUploadDocumentForm
);

export const editJobseekerWorkExperience = createAction(
    Actions.EditJobseekerWorkExperience,
    props<ActionPayload<any>>() //tmp any
);

export const editJobseekerWorkExperienceSuccess = createAction(
    Actions.EditJobseekerWorkExperienceSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const editJobseekerWorkExperienceFail = createAction(
    Actions.EditJobseekerWorkExperienceFail,
    props<ActionPayload<ApiError>>()
);

export const setIsWorkExperienceFilled = createAction(
    Actions.SetIsWorkExperienceFilled,
    props<ActionPayload<{ isWorkExperienceFilled: boolean, moderationLastChangeDate: Date }>>()
);

export const setIsWorkExperienceFilledSuccess = createAction(
    Actions.SetIsWorkExperienceFilledSuccess,
    props<ActionPayload<Jobseeker>>()
);

export const setIsWorkExperienceFilledFail = createAction(
    Actions.SetIsWorkExperienceFilledFail,
    props<ActionPayload<ApiError>>()
);

export const setSelectedCategories = createAction(
    Actions.SetSelectedCategories,
    props<ActionPayload<number[]>>()
);

export const setJobseekerWorkExperience = createAction(
    Actions.SetJobseekerWorkExperience,
    props<ActionPayload<JobseekerWorkExperienceViewModel>>()
);

export const closeWorkExperienceEditForm = createAction(
    Actions.CloseWorkExperienceEditForm
);

export const changeWorkExperienceIsCurrent = createAction(
    Actions.ChangeWorkExperienceIsCurrent
);

export const editJobseekerPhoto = createAction(
    Actions.EditJobseekePhoto,
    props<ActionPayload<{jobseekerPhotoModel: string, moderationLastChangeDate: Date}>>()
);
export const editJobseekerPhotoSuccess = createAction(
    Actions.EditJobseekerPhotoSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const editJobseekerPhotoFail = createAction(
    Actions.EditJobseekerPhotoFail,
    props<ActionPayload<ApiError>>()
);
export const selectJobseekerPhoto = createAction(
    Actions.SelectJobeekerPhoto
);
export const insertCroppedImage = createAction(
    Actions.InsertCroppedImage,
    props<ActionPayload<{base64: string}>>()
);
export const closeJobseekerPhotoEditForm = createAction(
    Actions.CloseJobseekerPhotoEditForm
);
export const approveJobseekerPhoto = createAction(
    Actions.ApproveJobseekerPhoto
);
//#endregion

export const setSelectedBirthMonth = createAction(
    Actions.SetSelectedBirthMonth,
    props<ActionPayload<number>>()
);

export const setSelectedBirthYear = createAction(
    Actions.SetSelectedBirthYear,
    props<ActionPayload<number>>()
);

export const updateJobseekerAddress = createAction(
    Actions.UpdateJobseekerAddress,
    props<ActionPayload<string>>()
);

export const updateSameAsCurrentAddress = createAction(
    Actions.UpdateSameAsCurrentAddress,
    props<ActionPayload<boolean>>()
);

export const updateJobseekerLegalAddress = createAction(
    Actions.UpdateJobseekerLegalAddress,
    props<ActionPayload<string>>()
);

//CV export
export const jobseekerCvExport = createAction(
    Actions.JobseekerCvExport
);

export const jobseekerCvExportSuccess = createAction(
    Actions.JobseekerCvExportSuccess
);

export const jobseekerCvExportFailed = createAction(
    Actions.JobseekerCvExportFailed,
    props<ActionPayload<ApiError>>()
);