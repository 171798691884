<div *ngIf = "{docuentTypeList: docuentTypeList$ | async,
              selectedFile: selectedFile$ | async,
              isFileUploading: isFileUploading$ | async,
              isUpdating: isUpdating$ | async,
              selectedFileText: selectedFileText$ | async,
              systemConfigs: systemConfigs$ | async,
              isFileSizeValid: isFileSizeValid$ | async
              } as data">
    <app-spinner [isLoading]="isUpdating$ | async" [isFixedPosition]="false">
        <form [formGroup]="editForm" #ngForm="ngForm" class="form">
            <div class="title">{{'label.UploadDocuments' | translate}}</div>
    
            <div class="form-item attachment">
                <input type="file" style="display:none;" #hiddenfileinput (change)="onFileSelect($event, data.systemConfigs.jobseeker.jobseekerAttachmentMaxSize)"/>
                <div class="attachment__text">{{ 'label.UploadDocuments' | translate }}</div>
                <button (click)="hiddenfileinput.click()" class="attachment__choose-button" type="button">{{'label.Select' | translate}}</button>
                <div *ngIf="data.selectedFile?.filename != null" class="selected-file">
                    {{data.selectedFileText}}
                </div>
                <mat-progress-bar *ngIf="data.isFileUploading" mode="indeterminate"></mat-progress-bar>

                <div>
                    <mat-error *ngIf="data.selectedFile?.filename == null && ngForm.submitted">
                        {{'message.MandatoryField' | translate}}
                    </mat-error>
                </div>
                <div *ngIf="!data.isFileSizeValid && data.isFileSizeValid != null">
                    <mat-error>
                        {{'message.JobseekerFileSizeValidation' | translate}}
                    </mat-error>
                </div>
            </div>
    
            <div class="form-item type">
                <label class="form-item__label type__label">
                    {{'label.DocumentType' | translate}}
                </label>
    
                <select class="form-item__value type__value" formControlName="documentTypeId">
                    <option *ngFor="let type of data.docuentTypeList" [ngValue]="type.value">
                        {{type.name}}
                    </option>
                </select>

                <mat-error *ngIf="editForm.controls['documentTypeId'].hasError('required') && (editForm.controls['documentTypeId'].touched || ngForm.submitted)">
                    {{'message.MandatoryField' | translate}}
                </mat-error>
            </div>
    
            <div class="form-item comment">
                <label class="form-item__label comment__label">
                    {{'label.Comment' | translate}}
                </label>
    
                <input class="form-item__value comment__value" type="text" formControlName="comment" name="comment">
            </div>
    
            <mat-dialog-actions>
                <div class="buttons">
                    <app-button (click)="onSave(data.selectedFile, data.isFileSizeValid)" [text]="'label.Save' | translate" [type]="buttonType.coloured" [typeAttribute]="buttonAttributeType.submit" class="button-save"></app-button>
                    <app-button [mat-dialog-close] ="true" [text]="'label.Cancel' | translate" [type]="buttonType.uncoloured" [typeAttribute]="buttonAttributeType.button"></app-button>
                </div>
            </mat-dialog-actions>
        </form>
    </app-spinner>
</div>
