export enum EntityAttributeEnum{
    Unspecified = 0,
    Customer_Name = 2,
    Customer_Name2 = 3,
    Customer_IdNumber = 4,
    Customer_Logo = 5,
    Customer_LogoThumbnail1 = 6,
    Customer_City = 7,
    Customer_Type = 8,
    Customer_Industry = 9,
    Customer_LegalName = 10,
    Customer_LegalAddress = 11,
    Customer_VatStatus = 12,
    Customer_Website = 13,
    Customer_PublicEmail = 14,
    Customer_NumberOfEmployeesFrom = 15,
    Customer_NumberOfEmployeesTo = 16,
    Customer_Description = 17,

    Customer_Agent_User = 18,
    Customer_Agent_IsProfileOwner = 19,
    Customer_Agent_Name = 20,
    Customer_Agent_Surname = 21,
    Customer_Agent_Email = 22,
    Customer_Agent_Phone1Number = 23,
    Customer_Agent_Phone1Extension = 24,
    Customer_Agent_MobilePhoneNumber = 25,
    Customer_Agent_MobilePhoneNumber2 = 26,
    Customer_Agent_Position = 27,

    Customer_Location_Title = 28,
    Customer_Location_Address = 29,
    Customer_Location_City = 30,
    Customer_Location_Country = 31,
    Customer_Location_Latitude = 32,
    Customer_Location_Longitude = 33,

    Jobseeker_User = 34,
    Jobseeker_Name = 35,
    Jobseeker_Surname = 36,
    Jobseeker_Address = 37,

    Jobseeker_Locality = 38,
    Jobseeker_Gender = 39,
    Jobseeker_DateOfBirth = 40,
    Jobseeker_Email = 41,
    Jobseeker_PhoneNumber = 42,
    Jobseeker_PhoneNumber2 = 43,
    Jobseeker_Photo = 44,
    Jobseeker_Attachment = 45,
    //Jobseeker_Attachment_DocumentType = 46,
    //Jobseeker_Attachment_Description = 47,
    //Jobseeker_Attachment_Filename = 48,
    Jobseeker_DrivingLicence = 49,
    Jobseeker_IsVehicleOwner = 134,
    Jobseeker_Privacy = 50,
    Jobseeker_OriginWebsite = 51,

    Jobseeker_ProfileAttachment_DocumentType = 52,
    Jobseeker_ProfileAttachment_Label = 53,
    Jobseeker_ProfileAttachment_Filename = 54,
    Jobseeker_ProfileAttachment_Description = 55,

    Jobseeker_Education_Level = 56,
    Jobseeker_Education_DateFromYear = 57,
    Jobseeker_Education_DateFromMonth = 58,
    Jobseeker_Education_DateToYear = 59,
    Jobseeker_Education_DateToMonth = 60,
    Jobseeker_Education_EducationalInstitutionId = 61,
    Jobseeker_Education_EducationalProgramId = 62,
    Jobseeker_Education_Institution = 63,
    Jobseeker_Education_EducationalProgramName = 64,
    Jobseeker_Education_Specialization = 65,
    Jobseeker_Education_Description = 66,
    Jobseeker_Education_File = 67,

    Jobseeker_WorkExperience_DateFromYear = 68,
    Jobseeker_WorkExperience_DateFromMonth = 69,
    Jobseeker_WorkExperience_DateToYear = 70,
    Jobseeker_WorkExperience_DateToMonth = 71,
    Jobseeker_WorkExperience_IsCurrent = 72,
    Jobseeker_WorkExperience_Employer = 73,
    Jobseeker_WorkExperience_PositionTitle = 74,
    Jobseeker_WorkExperience_Description = 75,
    Jobseeker_WorkExperience_Category = 76,
    Jobseeker_WorkExperience_File = 77,

    Announcement_ProductFamilyGroupId = 78,
    Announcement_Type = 79,
    Announcement_Module = 80,
    Announcement_IsPriority = 81,
    Announcement_CustomerName = 82,
    Announcement_CustomerName2 = 83,
    Announcement_Title = 84,
    Announcement_Title2 = 85,
    Announcement_Description = 86,
    Announcement_Description2 = 87,
    Announcement_IsAnonymous = 88,
    Announcement_IsWithLogo = 89,
    Announcement_OccupationCategory = 90,
    Announcement_ContactPerson = 91,
    Announcement_HideContactPerson = 92,
    Announcement_EmploymentForm = 93,
    Announcement_BusinessTravel = 94,
    Announcement_DurationFrom = 95,
    Announcement_DurationTo = 96,
    Announcement_SalaryFrom = 97,
    Announcement_SalaryTo = 98,
    Announcement_HideSalary = 99,
    Announcement_RequiredLanguage = 100,
    Announcement_WorkExperienceFrom = 101,
    Announcement_WorkExperienceTo = 102,
    Announcement_EducationLevel = 103,
    Announcement_TargetAudience = 104,
    Announcement_Address_City = 105,
    Announcement_Address_CustomerLocation = 106,
    Announcement_HideLocationExceptCity = 107,
    Announcement_DrivingLicence = 108,
    Announcement_Logo_Main = 109,
    Announcement_Logo_Thumbnail1 = 110,
    Announcement_Attachment = 111,
    Announcement_ApplicationMethod = 112,
    Announcement_Application_Email = 113,
    Announcement_Application_IsEmailSameAsContactPerson = 114,
    Announcement_Application_PhoneNumber = 115,
    Announcement_Application_IsPhoneSameAsContactPerson = 116,
    Announcement_Application_WebsiteUrl = 117,
    Announcement_Application_ForwardApplicationsToEmail = 118,
    Announcement_Application_RecipientCustomerAgent = 119,
    Announcement_Application_ApplicationUrl = 120,
    Announcement_Application_ApplicationAddress = 121,
    Announcement_PublishDate = 122,
    Announcement_DeadlineDate = 123,
    Announcement_Product = 124,
    Announcement_IsEventSpecific = 125,
    Announcement_Event = 126,
    Announcement_IsWithBonus = 127,
    Announcement_IsMatchingEligible = 128,
    Announcement_IsSuitableForStudent = 129,

    Jobseeker_Language_File = 130,
    Jobseeker_DailyDigestOn = 131,

    Announcement_Language_WorldLanguage = 132,
    Announcement_Language_IsMandatory = 133,
    Announcement_IsWorkFromHome = 135,

    Announcement_Bonus = 136,
    Announcement_WorkExperienceType = 137,

    Customer_Branding_AnnouncementDetailsDesktopBgImage = 138,
    Customer_Branding_AnnouncementDetailsMobileBgImage = 139,

    Customer_Branding_CustomerCoverImage = 140,

    Announcement_ExtendedLocation_LocationId = 141,
    Announcement_ExtendedLocation_LastVisibleLocationId = 142,

    Jobseeker_ExtendedLocationId = 143,

    Jobseeker_LegalAddress = 144,
    Jobseeker_SameAsCurrentAddress = 145,
}

export enum AnnouncementModerationAttributeEnum
{
    CustomerName = 1,
    Type = 2,
    Title = 3,
    Title2 = 4,
    Description = 5,
    Description2 = 6,
    IsAnonymous = 7,
    IsWithLogo = 8,
    OccupationCategory = 9,
    ContactPerson = 10,
    HideContactPerson = 11,
    EmploymentForm = 12,
    BusinessTravel = 13,
    DurationFrom = 14,
    DurationTo = 15,
    SalaryFrom = 16,
    SalaryTo = 17,
    HideSalary = 18,
    RequiredLanguage = 19,
    WorkExperienceFrom = 20,
    WorkExperienceTo = 21,
    EducationLevel = 22,
    DrivingLicence = 23,
    Logo_Main = 24,
    Logo_Thumbnail1 = 25,
    Attachment = 26,
    ApplicationMethod = 27,
    Application_Email = 28,
    Application_IsEmailSameAsContactPerson = 29,
    Application_PhoneNumber = 30,
    Application_ApplicationUrl = 31,
    Application_ApplicationAddress = 32,
    PublishDate = 33,
    DeadlineDate = 34,
    IsWithBonus = 35,
    IsSuitableForStudent = 36,
    IsWorkFromHome = 37,
    Bonus = 38,
    WorkExperienceType = 39,
    ExtendedLocations = 40,
    Address_City = 41
}

export enum JobseekerModerationAttributeEnum
{
    Name = 1,
    Surname = 2,
    Email = 3,
    PhoneNumber = 4,
    PhoneNumber2 = 5,
    Gender = 6,
    DateOfBirth = 7,
    Locality = 8,
    ExtendedLocationId = 9,
    Address = 10,
    LegalAddress = 12,
    DrivingLicense = 13,
    IsVehicleOwner = 14,
    VehicleType = 15,
    Photo = 16,
    WorkExperience = 17,
    Education = 18,
    Language = 19,
    Attachment = 20,
    IsWorkExperienceFilled = 21
}