<app-spinner [isLoading]="isUpdating$ | async" [isFixedPosition]="false">
    <div *ngIf="{ 
        personInfo: selectPersonalInfo$ | async,
        sameAsCurrentAddress: sameAsCurrentAddress$ | async,
        legalAddress: legalAddress$ | async,
        address: address$ | async,
        locationLevelCount: locationLevelCount$ | async,
        locationRequiredLevelCount: locationRequiredLevelCount$ | async,
        locationLastHiddenLevelCount: locationLastHiddenLevelCount$ | async,
        firstLevelParentLocationId: locationFirstLevelId$ | async,
        jobseekerId: jobseekerId$ | async,
        moderationLastChangeDate: moderationLastChangeDate$ | async
    } as data">
        <form [formGroup]="editForm" #ngForm="ngForm" [appConnectForm]="data.personInfo" class="form">
            <div class="form-item name">
                <label class="form-item__label name__label">{{'label.Name' | translate}}</label>
                <div class="validation-group validation-group--phone">
                    <input class="form-item__value name__value" type="text" formControlName="name" name="name">
                    <mat-error *ngIf="editForm.controls['name'].hasError('required') && (editForm.controls['name'].touched || ngForm.submitted)" class="validation-message">{{'message.MandatoryField' | translate}}</mat-error>
                    <mat-error *ngIf="editForm.controls['name'].hasError('pattern') && (editForm.controls['name'].touched || ngForm.submitted)" class="validation-message">{{'message.IncorrectFormat' | translate}}</mat-error>
                </div>
            </div>
        
            <div class="form-item surname">
                <label class="form-item__label surname__label">{{'label.Surname' | translate}}</label>
                <div class="validation-group validation-group--phone">
                    <input class="form-item__value surname__value" type="text" formControlName="surname" name="surname">
                    <mat-error *ngIf="editForm.controls['surname'].hasError('required') && (editForm.controls['surname'].touched || ngForm.submitted)" class="validation-message">{{'message.MandatoryField' | translate}}</mat-error>
                    <mat-error *ngIf="editForm.controls['surname'].hasError('pattern') && (editForm.controls['surname'].touched || ngForm.submitted)" class="validation-message">{{'message.IncorrectFormat' | translate}}</mat-error>
                </div>
            </div>
        
            <div class="form-item email">
                <label class="form-item__label email__label">{{'label.Email' | translate}}</label>
                <div class="validation-group">
                    <input class="form-item__value email__value" type="email" formControlName="email" name="email">
                    <mat-error *ngIf="editForm.controls['email'].hasError('required') && (editForm.controls['email'].touched || ngForm.submitted)" class="validation-message">{{'message.MandatoryField' | translate}}</mat-error>
                    <mat-error *ngIf="editForm.controls['email'].hasError('pattern') && (editForm.controls['email'].touched || ngForm.submitted)" class="validation-message">{{'message.IncorrectFormat' | translate}}</mat-error>
                </div>
            </div>
        
            <div class="form-item phone">
                <label class="form-item__label phone__label">{{'label.Phone' | translate}}</label>
                <div class="validation-group validation-group--phone">
                    <input class="form-item__value phone__value1" maxlength="30" type="text" formControlName="phone" name="phone">
                    <mat-error *ngIf="editForm.controls['phone'].hasError('required') && (editForm.controls['phone'].touched || ngForm.submitted)" class="validation-message">{{'message.MandatoryField' | translate}}</mat-error>
                    <mat-error *ngIf="editForm.controls['phone'].hasError('pattern') && (editForm.controls['phone'].touched || ngForm.submitted)" class="validation-message">{{'message.IncorrectFormat' | translate}}</mat-error>
                </div>
        
                <div class="validation-group">
                    <input class="form-item__value phone__value2" maxlength="30" type="text" formControlName="phone2" name="phone2">
                    <mat-error *ngIf="editForm.controls['phone2'].invalid" class="validation-message">{{'message.IncorrectFormat' | translate}}</mat-error>
                </div>
            </div>
        
            <div class="form-item gender">
                <label class="form-item__label gender__label">{{'label.Gender' | translate}}</label>
                <select class="form-item__value gender__value" formControlName="gender">
                    <option *ngFor="let gender of genderList$ | async" [ngValue]="gender.value">
                        {{gender.name}}
                    </option>
                </select>
            </div>
        
            <div class="form-item birth-date">
                <label class="form-item__label birth-date__label">{{'label.DateOfBirth' | translate}}</label>
                <div class="birth-date-value-group">
                    <select class="form-item__value birth-date__value-day" formControlName="birthDay">
                        <option *ngFor="let day of (days$ | async)" [ngValue]="day">
                            {{day}}
                        </option>
                    </select>
                    <select class="form-item__value birth-date__value-month" formControlName="birthMonth" (change)="onSelectMonth()">
                        <option *ngFor="let month of monthList$ | async" [ngValue]="month.value">
                            {{month.name}}
                        </option>
                    </select>
                    <select class="form-item__value birth-date__value-year" formControlName="birthYear" (change)="onSelectYear()">
                        <option *ngFor="let year of yearListBirth$ | async" [ngValue]="year.value">
                            {{year.name}}
                        </option>
                    </select>
                </div>
            </div>
        
            <div class="form-item locality">
                <label class="form-item__label locality__label">{{'label.City' | translate}}</label>
                <!--<select class="form-item__value locality__value" formControlName="city">
                    <option  *ngFor="let location of locations$ | async" [ngValue]="location.localityId">
                        {{location.name}}
                    </option>
                </select>-->
                <hrra-location-picker
                    class="form-item__label locality__value"
                    formControlName="extendedLocationPath"
                    [locationLevelCount]="data.locationLevelCount"
                    [firstLevelParentLocationId]="data.firstLevelParentLocationId"
                    [requiredLevelCount]="data.locationRequiredLevelCount"
                    [lastHiddenLevelCount]="data.locationLastHiddenLevelCount">
                </hrra-location-picker>

                <span class="locality__message">{{ 'text.JobseekerExtendedLocationEditText' | translate }}</span>
            </div>
        
            <div class="form-item address">
                <label class="form-item__label address__label">{{'label.Address' | translate}}</label>
                <div class="validation-group validation-group--address">
                    <input (keyup)="updateJobseekerAddress($event)" class="form-item__value address__value" type="text" formControlName="address">
                    <mat-error *ngIf="editForm.controls['address'].invalid" class="validation-message validation-message--address">{{'message.IncorrectFormat' | translate}}</mat-error>
                </div>
            </div>
    
            <div class="form-item legal-address">
                <label class="form-item__label legal-address__label">{{'label.LegalAddress' | translate}}</label>
                <mat-checkbox [checked]="data.sameAsCurrentAddress" class="legal-address__checkbox" (change)="updateSameAsCurrentAddress($event.checked)">
                    {{'label.SameAsCurrentAddress' | translate}}
                </mat-checkbox>
            </div>  
            
            <div class="form-item address">
                <label class="form-item__label address__label">{{'label.LegalAddress' | translate}}</label>
                <div class="validation-group validation-group--address">
                    <input class="form-item__value address__value" type="text" [value]="data.legalAddress" (keyup)="updateJobseekerLegalAddress($event)" [attr.disabled]="data?.sameAsCurrentAddress? '' : null">
                </div>
            </div>
        
            <div class="form-item driving-licence">
                <label class="form-item__label driving-licence__label">{{'label.DrivingLicence' | translate}}</label>
                <mat-select class="form-item__value driving-licence__value" formControlName="drivingLicenses" multiple panelClass="personal-info-select-driving-licence">
                    <mat-option *ngFor="let drivingLicense of drivingLicenseList$ | async" [value]="drivingLicense.value">{{ drivingLicense.name}}</mat-option>
                </mat-select>
            </div>
        
            <div class="form-item own-vehicle">
                <label class="form-item__label own-vehicle__label">{{'label.OwnTransport' | translate}}</label>
                <mat-radio-group class="form-item__value own-vehicle__value" formControlName="ownVehicle" (change)="onChangeOwnVehicle()"> 
                    <mat-radio-button class="own-vehicle__value--yes" [value]="true">{{'label.Yes' | translate}}</mat-radio-button>
                    <mat-radio-button class="own-vehicle__value--no" [value]="false">{{'label.No' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="form-item">
                <label class="form-item__label">{{'label.TransportType' | translate}}</label>
                <mat-select class="form-item__value" [disableOptionCentering]="true" multiple formControlName="jobseekerVehicles" panelClass="personal-info-select-vehicle-type">
                    <mat-option *ngFor="let vehicleType of vehicleTypes$ | async" [value]="vehicleType.value">{{ vehicleType.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.hasError('fillVehicleType') && (editForm.controls['jobseekerVehicles'].touched || ngForm.submitted)" class="validation-message">{{'message.MandatoryField' | translate}}</mat-error>
            </div>
        
            <mat-dialog-actions>
                <div class="buttons">
                    <app-button (click)="onSave(data.sameAsCurrentAddress, data.legalAddress, data.jobseekerId, data.moderationLastChangeDate)" [disabled]="!ngForm.valid" [text]="'label.Save' | translate" [type]="buttonType.coloured" [typeAttribute]="buttonAttributeType.submit" class="button-submit"></app-button>
                    <app-button [mat-dialog-close] ="true" [text]="'label.Cancel' | translate" [type]="buttonType.uncoloured" [typeAttribute]="buttonAttributeType.button"></app-button>
                </div>
            </mat-dialog-actions>
        </form>
    </div>
</app-spinner>