import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { JobseekerEducationEditComponent } from '../../jobseeker-education-edit/jobseeker-education-edit.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/root-store/state';
import { closeJobseekerEditEducationForm, closeJobseekerEditLanguageForm, closeJobseekerPhotoEditForm, closeJobseekerUploadDocumentForm, closeWorkExperienceEditForm, selectJobseekerEducation } from 'src/app/root-store/jobseeker-store/jobseeker-profile/actions';
import { JobseekerLanguageEditComponent } from '../../jobseeker-language-edit/jobseeker-language-edit.component';
import { JobseekerDocumentUploadComponent } from '../../jobseeker-document-upload/jobseeker-document-upload.component';
import { JobseekerWorkExperienceEditComponent } from '../../jobseeker-work-experience-edit/jobseeker-work-experience-edit.component';
import { JobseekerPhotoEditComponent } from '../../jobseeker-photo-edit/jobseeker-photo-edit.component';
import { JobseekerWorkExperienceViewModel } from '@hrra/common-models';
import { JobseekerPhotoEditForAdminComponent } from '../../jobseeker-photo-edit-for-admin/jobseeker-photo-edit-for-admin.component';
import { JobseekerWorkExperienceEditForAdminComponent } from '../../jobseeker-work-experience-edit-for-admin/jobseeker-work-experience-edit-for-admint.component';
import { JobseekerEducationEditForAdminComponent } from '../../jobseeker-education-edit-for-admin/jobseeker-education-edit-for-admin.component';
import { closeJobseekerEditEducationFormForAdmin, closeJobseekerPhotoEditFormForAdmin, closeWorkExperienceEditFormForAdmin, closeJobseekerEditLanguageFormForAdmin, selectJobseekerEducationForAdmin, closeJobseekerUploadDocumentFormForAdmin } from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/actions';
import { JobseekerLanguageEditForAdminComponent } from '../../jobseeker-language-edit-for-admin/jobseeker-language-edit-for-admin.component';
import { JobseekerDocumentUploadForAdminComponent } from '../../jobseeker-document-upload-for-admin/jobseeker-document-upload-for-admin.component';

@Injectable({
  providedIn: 'root'
})
export class JobseekerDialogService {

  constructor(private dialog: MatDialog, private store: Store<AppState>) { }

  editJobseekerEducation(id: number, isCreateForm: boolean){

    let title = isCreateForm ? 'label.EducationCreate' : 'label.EducationEdit';

    let dialogHref = this.dialog.open(JobseekerEducationEditComponent, {
      data:{
        educationid : id,
        title: title,
        isCreateForm: isCreateForm
      },
      maxHeight: '100vh',
      panelClass: 'confirm-edit-dialog-container',
      id: 'education'
    });

    dialogHref.afterClosed().subscribe(() => {
      this.store.dispatch(closeJobseekerEditEducationForm());
    });
    
    this.store.dispatch(selectJobseekerEducation({payload: {educationId: id}}));
    
  }

  editJobseekerEducationForAdmin(id: number, isCreateForm: boolean){
    let title = isCreateForm ? 'label.EducationCreate' : 'label.EducationEdit';

    let dialogHref = this.dialog.open(JobseekerEducationEditForAdminComponent, {
      data:{
        educationid : id,
        title: title,
        isCreateForm: isCreateForm
      },
      maxHeight: '100vh',
      panelClass: 'confirm-edit-dialog-container',
      id: 'education'
    });

    dialogHref.afterClosed().subscribe(() => {
      this.store.dispatch(closeJobseekerEditEducationFormForAdmin());
    });
    
    this.store.dispatch(selectJobseekerEducationForAdmin({payload: {educationId: id}}));
  }

  editJobseekerLanguage(languageIdInfo: {id:number, objectId: string}, isCreateForm: boolean){
    let title = isCreateForm ? 'label.LanguageCreate' : 'label.LanguageEdit';

    let dialogHref = this.dialog.open(JobseekerLanguageEditComponent, {
      data:{
        languageId : languageIdInfo?.id,
        title: title,
        isCreateForm: isCreateForm,
        objectId: languageIdInfo?.objectId
      },
      maxHeight: '100vh',
      panelClass: 'jobseeker-edit-language-dialog-container',
      id: 'language'
    });

    dialogHref.afterClosed().subscribe(() => {
      this.store.dispatch(closeJobseekerEditLanguageForm());
    })
  }

  editJobseekerLanguageForAdmin(languageId: number, objectId: string, jobseekerId: number, moderationLastChangeDate: Date, isCreateForm: boolean){
    let title = isCreateForm ? 'label.LanguageCreate' : 'label.LanguageEdit';

    let dialogHref = this.dialog.open(JobseekerLanguageEditForAdminComponent, {
      data:{
        languageId : languageId,
        title: title,
        jobseekerId: jobseekerId,
        objectId: objectId,
        moderationLastChangeDate: moderationLastChangeDate,
        isCreateForm: isCreateForm
      },
      maxHeight: '100vh',
      panelClass: 'jobseeker-edit-language-dialog-container',
      id: 'language'
    });

    dialogHref.afterClosed().subscribe(() => {
      this.store.dispatch(closeJobseekerEditLanguageFormForAdmin());
    })
  }

  uploadDocument(){  
    let dialogHref = this.dialog.open(JobseekerDocumentUploadComponent, {
      maxHeight: '100vh',
      panelClass: 'jobseeker-document-upload-dialog-container',
      id: 'document'
    });

    dialogHref.afterClosed().subscribe(() => {
      this.store.dispatch(closeJobseekerUploadDocumentForm());
    })
  }

  uploadDocumentForAdmin(jobseekerId: number, moderationLastChangeDate: Date){  
    let dialogHref = this.dialog.open(JobseekerDocumentUploadForAdminComponent, {
      maxHeight: '100vh',
      panelClass: 'jobseeker-document-upload-dialog-container',
      data: {
        jobseekerId: jobseekerId,
        moderationLastChangeDate: moderationLastChangeDate
      },
      id: 'document'
    });

    dialogHref.afterClosed().subscribe(() => {
      this.store.dispatch(closeJobseekerUploadDocumentFormForAdmin());
    })
  }

  editJobseekerWorkExperience(isCreateForm: boolean, workExperience: JobseekerWorkExperienceViewModel){
    let title = isCreateForm ? 'label.WorkExperienceCreate' : 'label.WorkExperienceEdit';

    let dialogHref = this.dialog.open(JobseekerWorkExperienceEditComponent, {
      data:{
        workExperience: workExperience,
        title: title,
        isCreateForm: isCreateForm
      },
      maxHeight: '100vh',
      panelClass: 'confirm-edit-dialog-container',
      id: 'workExperience'
    });  
    
    dialogHref.afterClosed().subscribe(() => {
      this.store.dispatch(closeWorkExperienceEditForm());
    });
  }

  editJobseekerWorkExperienceForAdmin(isCreateForm: boolean, workExperience: JobseekerWorkExperienceViewModel){
    let title = isCreateForm ? 'label.WorkExperienceCreate' : 'label.WorkExperienceEdit';

    let dialogHref = this.dialog.open(JobseekerWorkExperienceEditForAdminComponent, {
      data:{
        workExperience: workExperience,
        title: title,
        isCreateForm: isCreateForm
      },
      maxHeight: '100vh',
      panelClass: 'confirm-edit-dialog-container',
      id: 'workExperience'
    });  
    
    dialogHref.afterClosed().subscribe(() => {
      this.store.dispatch(closeWorkExperienceEditFormForAdmin());
    });
  }

  editJobseekerPhoto(photoHref: string, isPhotoPending: boolean){  
    let dialogHref = this.dialog.open(JobseekerPhotoEditComponent, {
      maxHeight: '100vh',
      panelClass: 'confirm-edit-dialog-container',
      id: 'photo',

      data:{
        photoHref:photoHref,
        isPhotoPending: isPhotoPending
      }
    });

    dialogHref.afterClosed().subscribe(() => {
      this.store.dispatch(closeJobseekerPhotoEditForm());
    });
  }

  editJobseekerPhotoForAdmin(photoHref: string, isPhotoPending: boolean){  
    let dialogHref = this.dialog.open(JobseekerPhotoEditForAdminComponent, {
      maxHeight: '100vh',
      panelClass: 'confirm-edit-dialog-container',
      id: 'photo',

      data:{
        photoHref:photoHref,
        isPhotoPending: isPhotoPending
      }
    });

    dialogHref.afterClosed().subscribe(() => {
      this.store.dispatch(closeJobseekerPhotoEditFormForAdmin());
    });
  }
}


