import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { select, Store } from '@ngrx/store';
import { ButtonTypeEnum } from '@hrra/ui';
import { IconButtonTypeEnum } from '@hrra/ui';
import { AppState } from 'src/app/root-store/state';
import { Observable, Subscription } from 'rxjs';
import { uploadJobseekerDocumentForAdmin, uploadTempFileForAdmin } from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/actions';
import { selectDocumentTypeList, selectFileName, isFileUploading, selectIsJobseekerProfileUpdating, selectSelectedFileText, selectIsFileSizeValid } from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/selectors';
import { FileUploadResultViewModel, JobseekerDocumentViewModel } from '../shared/services/jobseeker.service';
import { ButtonTypeAttributeEnum } from '@hrra/ui';
import { JobseekerFileService } from '../shared/services/jobseeker-file.service';
import { SystemConfig } from 'src/app/shared/models/system-config-model';
import { selectSystemConfigs } from 'src/app/root-store/selectors';
import { NavigationEnd, Router } from '@angular/router';
import { BasicOptionItem } from '@hrra/common-models';

@Component({
  selector: 'app-jobseeker-document-upload-for-admin',
  templateUrl: './jobseeker-document-upload-for-admin.component.html',
  styleUrls: ['./jobseeker-document-upload-for-admin.component.scss']
})
export class JobseekerDocumentUploadForAdminComponent implements OnInit {
  
  navigationSubs: Subscription;

  editForm: UntypedFormGroup;

  docuentTypeList$: Observable<BasicOptionItem[]>;
  selectedFile$: Observable<FileUploadResultViewModel>;
  isFileUploading$: Observable<boolean>;
  isUpdating$: Observable<boolean>;
  selectedFileText$: Observable<String>;
  isFileSizeValid$: Observable<boolean>;
  systemConfigs$: Observable<SystemConfig>;

  public buttonType: any;
  public iconButtonType: any;
  public buttonAttributeType: any;

  public jobseekerId: number;
  public moderationLastChangeDate: Date;

  constructor(private store: Store<AppState>, @Inject(MAT_DIALOG_DATA) public data, private router: Router, public dialogRef: MatDialogRef<JobseekerDocumentUploadForAdminComponent>, private fileService: JobseekerFileService) { 
    this.buttonType = ButtonTypeEnum;
    this.iconButtonType = IconButtonTypeEnum;
    this.buttonAttributeType = ButtonTypeAttributeEnum;

    this.navigationSubs = this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.dialogRef.close();
      }
    });
  }

  ngOnInit(): void {
    this.docuentTypeList$ = this.store.pipe(select(selectDocumentTypeList));
    this.selectedFile$ = this.store.pipe(select(selectFileName));
    this.isFileUploading$ = this.store.pipe(select(isFileUploading));
    this.isUpdating$ = this.store.pipe(select(selectIsJobseekerProfileUpdating));
    this.selectedFileText$ = this.store.pipe(select(selectSelectedFileText));
    this.isFileSizeValid$ = this.store.pipe(select(selectIsFileSizeValid));
    this.systemConfigs$ = this.store.pipe(select(selectSystemConfigs));

    this.editForm = new UntypedFormGroup({
      documentTypeId: new UntypedFormControl(null, [Validators.required]),
      comment: new UntypedFormControl(null)
    })
  }

  onSave(filename: FileUploadResultViewModel, isFileSizeValid: boolean){
    if(this.editForm.valid && isFileSizeValid){
      let document = <JobseekerDocumentViewModel>{
        documentTypeId: this.editForm.get('documentTypeId').value,
        comment: this.editForm.get('comment').value,
        fileName: filename?.filename
      }
  
      this.store.dispatch(uploadJobseekerDocumentForAdmin({payload: { document, jobseekerId: this.data.jobseekerId, moderationLastChangeDate: this.data.moderationLastChangeDate }}));
    }
  }

  ngOnDestroy() {
    this.navigationSubs.unsubscribe();
  }

  onFileSelect(event, fileLimit){
    this.fileService.uploadTempFileForAdmin(event, fileLimit, this.data.jobseekerId);
  }
}
