import { createAction, props } from '@ngrx/store';
import { ApiError } from '@hrra/core';
import { ActionPayload } from "@hrra/core";
import { JobseekerPersonalInfoModel, JobseekerEducationModel, FileUploadResultViewModel, JobseekerLanguageModel, JobseekerDocumentViewModel, JobseekerWorkExperienceModel } from 'src/app/jobseeker/shared/services/jobseeker.service';
import { Jobseeker, JobseekerWorkExperience, JobseekerWorkExperienceViewModel } from '@hrra/common-models';
import { Action } from 'rxjs/internal/scheduler/Action';


export enum Actions{
    LoadJobseekerProfileForAdmin = '[Admin Jobseeker Profile] Load Admin jobseeker profile for admin',
    LoadJobseekerProfileForAdminSuccess = '[Admin Jobseeker Profile] Load Admin jobseeker profile for admin success',
    LoadJobseekerProfileForAdminFail = '[Admin Jobseeker Profile] Load Admin jobseeker profile for admin fail',

    DeleteJobseekerDocument ='[Admin Jobseeker profile] Delete jobseeker document',
    DeleteJobseekerDocumentSuccess ='[Admin Jobseeker profile] Delete jobseeker document success',
    DeleteJobseekerDocumentFail ='[Admin Jobseeker profile] Delete jobseeker document fail',

    //DeleteJobseekerDocumentFromAdmin ='[Admin Jobseeker profile] Delete jobseeker document',
    //DeleteJobseekerDocumentFromAdminSuccess ='[Admin Jobseeker profile] Delete jobseeker document success',
    //DeleteJobseekerDocumentFromAdminFail ='[Admin Jobseeker profile] Delete jobseeker document fail',
  
    DeleteJobseekerLanguage ='[Admin Jobseeker profile] Delete jobseeker language',
    DeleteJobseekerLanguageSuccess ='[Admin Jobseeker profile] Delete jobseeker language success',
    DeleteJobseekerLanguageFail ='[Admin Jobseeker profile] Delete jobseeker language fail',

    DeleteJobseekerEducationForAdmin ='[Admin Jobseeker profile] Delete education education',
    DeleteJobseekerEducationForAdminSuccess ='[Admin Jobseeker profile] Delete jobseeker education success',
    DeleteJobseekerEducationForAdminFail ='[Admin Jobseeker profile] Delete jobseeker education fail',

    DeleteJobseekerWorkExperienceForAdmin ='[Admin Jobseeker profile] Delete jobseeker work experience',
    DeleteJobseekerWorkExperienceForAdminSuccess ='[Admin Jobseeker profile] Delete jobseeker work experience success',
    DeleteJobseekerWorkExperienceForAdminFail ='[Admin Jobseeker profile] Delete jobseeker work experience fail',

    DeleteJobseekerPhoto ='[Admin Jobseeker profile] Delete jobseeker photo',
    DeleteJobseekerPhotoSuccess ='[Admin Jobseeker profile] Delete jobseeker photo success',
    DeleteJobseekerPhotoFail ='[Admin Jobseeker profile] Delete jobseeker photo fail',

    DeleteJobseekerPhotoFromAdmin ='[Admin Jobseeker profile] Delete jobseeker photo',
    DeleteJobseekerPhotoFromAdminSuccess ='[Admin Jobseeker profile] Delete jobseeker photo success',
    DeleteJobseekerPhotoFromAdminFail ='[Admin Jobseeker profile] Delete jobseeker photo fail',

    DownloadEducationAttachmentForAdmin = '[Admin Jobseeke profile] Download education attachment',
    DownloadEducationAttachmentForAdminSuccess = '[Admin Jobseeker profile] Download education attachment success',
    DownloadEducationAttachmentForAdminFail = '[Admin Jobseeker profile] Download education attachment fail',

    DownloadLanguageAttachmentForAdmin = '[Admin Jobseeke profile] Download language attachment',
    DownloadLanguageAttachmentForAdminSuccess = '[Admin Jobseeker profile] Download language attachment success',
    DownloadLanguageAttachmentForAdminFail = '[Admin Jobseeker profile] Download language attachment fail',

    DownloadDocumentAttachmentFromAdmin = '[Admin Jobseeke profile] Download document attachment',
    DownloadDocumentAttachmentFromAdminSuccess = '[Admin Jobseeker profile] Download document attachment success',
    DownloadDocumentAttachmentFromAdminFail = '[Admin Jobseeker profile] Download document attachment fail',

    DownloadWorkExperienceAttachmentForAdmin = '[Admin Jobseeke profile] Download work experience attachment',
    DownloadWorkExperienceAttachmentForAdminSuccess = '[Admin Jobseeker profile] Download work experience attachment success',
    DownloadWorkExperienceAttachmentForAdminFail = '[Admin Jobseeker profile] Download work experience attachment fail',

    EditJobseekerPersonalInfoForAdmin ='[Admin Jobseeker profile] Edit jobseeker personal info',
    EditJobseekerPersonalInfoForAdminSuccess ='[Admin Jobseeker profile] Edit jobseeker personal info success',
    EditJobseekerPersonalInfoForAdminFail ='[Admin Jobseeker profile] Edit jobseeker personal info fail',


    //UploadTempFile
    UploadTempFileForAdmin = '[Admin Jobseeker profile] Upload temp file',
    UploadTempFileForAdminSuccess = '[Admin Jobseeker profile] Upload temp file success',
    UploadTempFileForAdminFail = '[Admin Jobseeker profile] Upload temp file fail',

    SetFileSizeValidation = '[Admin Jobseeker profile] Set file size validation',

    //add/edit education
    EditJobseekerEducationForAdmin = '[Admin Jobseeker profile] Edit jobseeker education',
    EditJobseekerEducationForAdminSuccess = '[Admin Jobseeker profile] Edit jobseeker education success',
    EditJobseekerEducationForAdminFail = '[Admin Jobseeker profile] Edit jobseeker education fail',

    SelectJobseekerEducationForAdmin = '[Admin Jobseeker profile] selectJobseekerEducation',
    SelectJobseekerEducationLevelForAdmin = '[Admin Jobseeker profile] Select education level',
    InstitutionSelectModeForAdmin = '[Admin Jobseeker profile] University select mod',
    ProgramSelectModeForAdmin = '[Admin Jobseeker profile] Program select mod',
    SelectJobseekerEdcuationalInstitutionForAdmin = '[Admin Jobseeker profile] Select educational institution',
    CloseJobseekerEditEducationFormForAdmin = '[Admin Jobseeker profile] Close Education Form',

    //add/edit language
    EditJobseekerLanguageForAdmin = '[Admin Jobseeker profile] Edit jobseeker language',
    EditJobseekerLanguageForAdminSuccess = '[Admin Jobseeker profile] Edit jobseeker language success',
    EditJobseekerLanguageForAdminFail = '[Admin Jobseeker profile] Edit jobseeker language fail',

    CloseJobseekerEditLanguageFormForAdmin = '[Admin Jobseeker profile] Close language Form',

    //upload document
    UploadJobseekerDocumentForAdmin = '[Admin Jobseeker profile] Upload jobseeker document',
    UploadJobseekerDocumentForAdminSuccess = '[Admin Jobseeker profile] Upload jobseeker document success',
    UploadJobseekerDocumentForAdminFail = '[Admin Jobseeker profile] Upload jobseeker document fail',

    CloseJobseekerUploadDocumentFormForAdmin = '[Admin Jobseeker profile] Close upload document Form',

    //add/edit work experience
    EditJobseekerWorkExperienceForAdmin = '[Admin Jobseeker profile] Edit jobseeker work experience',
    EditJobseekerWorkExperienceForAdminSuccess = '[Admin Jobseeker profile] Edit jobseeker work experience success',
    EditJobseekerWorkExperienceForAdminFail = '[Admin Jobseeker profile] Edit jobseeker work experience fail',

    SetIsWorkExperienceFilledForAdmin = '[Admin Jobseeker profile] Set is work experience filled',
    SetIsWorkExperienceFilledForAdminSuccess = '[Admin Jobseeker profile] Set is work experience filled success',
    SetIsWorkExperienceFilledForAdminFail = '[Admin Jobseeker profile] Set is work experience filled fail',

    SetSelectedCategoriesForAdmin = '[Admin Jobseeker profile] Set selected categories',
    SetJobseekerWorkExperienceForAdmin = '[Admin Jobseeker profile] Set jobseeker work experience',
    CloseWorkExperienceEditFormForAdmin = '[Admin Jobseeker profile] Close work experience edit form',
    ChangeWorkExperienceIsCurrentForAdmin = '[Admin Jobseeker profile] Close work experience is current',

    //add/edit photo
    EditJobseekePhotoForAdmin = '[Admin Jobseeker profile] Edit jobseeker photo',
    EditJobseekerPhotoForAdminSuccess = '[Admin Jobseeker profile] Edit jobseeker photo success',
    EditJobseekerPhotoForAdminFail = '[Admin Jobseeker profile] Edit jobseeker photo fail',
    
    SelectJobeekerPhoto = '[Admin Jobseeker profile] Select jobseeker photo',
    CloseJobseekerPhotoEditFormForAdmin = '[Admin Jobseeker profile] Close jobseeker photo edit form',
    ApproveJobseekerPhoto = '[Admin Jobseeker profile] Approve jobseeker photo',
    InsertCroppedImage = '[Admin Jobseeker profile] Insert cropped image',

    //birthdate
    SetSelectedBirthMonth = '[Admin Jobseeker profile] Set selected birth month',
    SetSelectedBirthYear = '[Admin Jobseeker profile] Set selected birth year',

    UpdateJobseekerAddress = '[Admin Jobseeker profile] Update Jobseeker Address',
    UpdateSameAsCurrentAddress = '[Admin Jobseeker profile] Update Same As Current Address',
    UpdateJobseekerLegalAddress = '[Admin Jobseeker profile] Update Jobseeker Legal Address',

    //CV export
    JobseekerCvExport = '[Admin jobseeker profile] Jobseeker cv export',
    JobseekerCvExportSuccess = '[Admin jobseeker profile] Jobseeker cv export success',
    JobseekerCvExportFailed = '[Admin jobseeker profile] Load jobseeker cv export failed'
}


export const uploadTempFileForAdmin = createAction(
    Actions.UploadTempFileForAdmin,
    props<ActionPayload<FormData>>()
);

export const uploadTempFileForAdminSuccess = createAction(
    Actions.UploadTempFileForAdminSuccess,
    props<ActionPayload<FileUploadResultViewModel>>()
);

export const uploadTempFileForAdminFail = createAction(
    Actions.UploadTempFileForAdminFail,
    props<ActionPayload<ApiError>>()
);

export const setFileSizeValidation = createAction(
    Actions.SetFileSizeValidation,
    props<ActionPayload<boolean>>()
);

//jobseeker profile for admin
export const loadJobseekerProfileForAdmin = createAction(
    Actions.LoadJobseekerProfileForAdmin,
    props<ActionPayload<{ jobseekerId: number }>>()
);

export const loadJobseekerProfileForAdminSuccess = createAction(
    Actions.LoadJobseekerProfileForAdminSuccess,
    props<ActionPayload<Jobseeker>>()
)

export const loadJobseekerProfileForAdminFail = createAction(
    Actions.LoadJobseekerProfileForAdminFail,
    props<ActionPayload<ApiError>>()
)

//#region  delete actions
export const deleteJobseekerDocument = createAction(
    Actions.DeleteJobseekerDocument,
    props<ActionPayload<{documentId: string, objectId: string, jobseekerId: number, moderationLastChangeDate: Date}>>()
);
export const deleteJobseekerDocumentSuccess = createAction(
    Actions.DeleteJobseekerDocumentSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const deleteJobseekerDocumentFail = createAction(
    Actions.DeleteJobseekerDocumentFail,
    props<ActionPayload<ApiError>>()
);

/*export const deleteJobseekerDocumentFromAdmin = createAction(
    Actions.DeleteJobseekerDocumentFromAdmin,
    props<ActionPayload<{documentId: string, objectId: string, jobseekerId: number, moderationLastChangeDate: Date}>>()
);
export const deleteJobseekerDocumentFromAdminSuccess = createAction(
    Actions.DeleteJobseekerDocumentFromAdminSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const deleteJobseekerDocumentFromAdminFail = createAction(
    Actions.DeleteJobseekerDocumentFromAdminFail,
    props<ActionPayload<ApiError>>()
);*/


export const deleteJobseekerLanguage = createAction(
    Actions.DeleteJobseekerLanguage,
    props<ActionPayload<{languageId: number, jobseekerId: number, objectId: string, moderationLastChangeDate: Date}>>()
);
export const deleteJobseekerLanguageSuccess = createAction(
    Actions.DeleteJobseekerLanguageSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const deleteJobseekerLanguageFail = createAction(
    Actions.DeleteJobseekerLanguageFail,
    props<ActionPayload<ApiError>>()
);


export const deleteJobseekerEducationForAdmin = createAction(
    Actions.DeleteJobseekerEducationForAdmin,
    props<ActionPayload<{educationId: number, objectId: string, jobseekerId: number, moderationLastChangeDate: Date}>>()
);
export const deleteJobseekerEducationForAdminSuccess = createAction(
    Actions.DeleteJobseekerEducationForAdminSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const deleteJobseekerEducationForAdminFail = createAction(
    Actions.DeleteJobseekerEducationForAdminFail,
    props<ActionPayload<ApiError>>()
);


export const deleteJobseekerWorkExperienceForAdmin = createAction(
    Actions.DeleteJobseekerWorkExperienceForAdmin,
    props<ActionPayload<{experienceId: number, objectId: string, jobseekerId: number, moderationLastChangeDate: Date}>>()
);
export const deleteJobseekerWorkExperienceForAdminSuccess = createAction(
    Actions.DeleteJobseekerWorkExperienceForAdminSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const deleteJobseekerWorkExperienceForAdminFail = createAction(
    Actions.DeleteJobseekerWorkExperienceForAdminFail,
    props<ActionPayload<ApiError>>()
);

export const deleteJobseekerPhoto = createAction(
    Actions.DeleteJobseekerPhoto,
    props<ActionPayload<{ jobseekerId: number, moderationLastChangeDate: Date }>>()
);
export const deleteJobseekerPhotoSuccess = createAction(
    Actions.DeleteJobseekerPhotoSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const deleteJobseekerPhotoFail = createAction(
    Actions.DeleteJobseekerPhotoFail,
    props<ActionPayload<ApiError>>()
);

export const deleteJobseekerPhotoFromAdmin = createAction(
    Actions.DeleteJobseekerPhotoFromAdmin,
    props<ActionPayload<{ jobseekerId: number, moderationLastChangeDate: Date }>>()
);
export const deleteJobseekerPhotoFromAdminSuccess = createAction(
    Actions.DeleteJobseekerPhotoFromAdminSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const deleteJobseekerPhotoFromAdminFail = createAction(
    Actions.DeleteJobseekerPhotoFromAdminFail,
    props<ActionPayload<ApiError>>()
);
//#endregion


//#region  downloads
export const downloadEducationAttachmentForAdmin = createAction(
    Actions.DownloadEducationAttachmentForAdmin,
    props<ActionPayload<{objectId: string, jobseekerId: number}>>()
);

export const downloadEducationAttachmentForAdminSuccess = createAction(
    Actions.DownloadEducationAttachmentForAdminSuccess
);

export const downloadEducationAttachmentForAdminFail = createAction(
    Actions.DownloadEducationAttachmentForAdminFail,
    props<ActionPayload<ApiError>>()
);


export const downloadLanguageAttachmentForAdmin = createAction(
    Actions.DownloadLanguageAttachmentForAdmin,
    props<ActionPayload<{objectId: string, jobseekerId: number}>>()
);

export const downloadLanguageAttachmentForAdminSuccess = createAction(
    Actions.DownloadLanguageAttachmentForAdminSuccess
);

export const downloadLanguageAttachmentForAdminFail = createAction(
    Actions.DownloadLanguageAttachmentForAdminFail,
    props<ActionPayload<ApiError>>()
);


export const downloadDocumentAttachmentFromAdmin = createAction(
    Actions.DownloadDocumentAttachmentFromAdmin,
    props<ActionPayload<{objectId: string, jobseekerId: number}>>()
);

export const downloadDocumentAttachmentFromAdminSuccess = createAction(
    Actions.DownloadDocumentAttachmentFromAdminSuccess
);

export const downloadDocumentAttachmentFromAdminFail = createAction(
    Actions.DownloadDocumentAttachmentFromAdminFail,
    props<ActionPayload<ApiError>>()
);


export const downloadWorkExperienceAttachmentForAdmin = createAction(
    Actions.DownloadWorkExperienceAttachmentForAdmin,
    props<ActionPayload<{objectId: string, jobseekerId: number}>>()
);

export const downloadWorkExperienceAttachmentForAdminSuccess = createAction(
    Actions.DownloadWorkExperienceAttachmentForAdminSuccess
);

export const downloadWorkExpeienceAttachmentForAdminFail = createAction(
    Actions.DownloadWorkExperienceAttachmentForAdminFail,
    props<ActionPayload<ApiError>>()
);
//#endregion


//#region add/edit
export const editJobseekerPersonalInfoForAdmin = createAction(
    Actions.EditJobseekerPersonalInfoForAdmin,
    props<ActionPayload<{jobseekerPersonalInfoModel:JobseekerPersonalInfoModel, moderationLastChangeDate:Date}>>()
);
export const editJobseekerPersonalInfoForAdminSuccess = createAction(
    Actions.EditJobseekerPersonalInfoForAdminSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const editJobseekerPersonalInfoForAdminFail = createAction(
    Actions.EditJobseekerPersonalInfoForAdminFail,
    props<ActionPayload<ApiError>>()
);

export const editJobseekerEducationForAdmin = createAction(
    Actions.EditJobseekerEducationForAdmin,
    props<ActionPayload<{ jobseekerEducationalModel: JobseekerEducationModel, moderationLastChangeDate: Date, jobseekerId :number }>>()
);
export const editJobseekerEducationForAdminSuccess = createAction(
    Actions.EditJobseekerEducationForAdminSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const editJobseekerEducationForAdminFail = createAction(
    Actions.EditJobseekerEducationForAdminFail,
    props<ActionPayload<ApiError>>()
);

export const selectJobseekerEducationForAdmin = createAction(
    Actions.SelectJobseekerEducationForAdmin,
    props<ActionPayload<{educationId: number}>>()
);

export const selectJobseekerEducationLevelForAdmin = createAction(
    Actions.SelectJobseekerEducationLevelForAdmin,
    props<ActionPayload<{levelId: number}>>()
);

export const institutionSelectModeForAdmin = createAction(
    Actions.InstitutionSelectModeForAdmin
);

export const programSelectModeForAdmin = createAction(
    Actions.ProgramSelectModeForAdmin
);

export const selectJobseekerEdcuationalInstitutionForAdmin = createAction(
    Actions.SelectJobseekerEdcuationalInstitutionForAdmin,
    props<ActionPayload<{institutionId: number}>>()
);

export const closeJobseekerEditEducationFormForAdmin = createAction(
    Actions.CloseJobseekerEditEducationFormForAdmin
);

export const editJobseekerLanguageForAdmin = createAction(
    Actions.EditJobseekerLanguageForAdmin,
    props<ActionPayload<{ language: JobseekerLanguageModel, jobseekerId: number, moderationLastChangeDate: Date }>>()
);

export const editJobseekerLanguageForAdminSuccess = createAction(
    Actions.EditJobseekerLanguageForAdminSuccess,
    props<ActionPayload<Jobseeker>>()
);

export const editJobseekerLanguageForAdminFail = createAction(
    Actions.EditJobseekerLanguageForAdminFail,
    props<ActionPayload<ApiError>>()
);

export const closeJobseekerEditLanguageFormForAdmin = createAction(
    Actions.CloseJobseekerEditLanguageFormForAdmin
);


export const uploadJobseekerDocumentForAdmin = createAction(
    Actions.UploadJobseekerDocumentForAdmin,
    props<ActionPayload<{ document: JobseekerDocumentViewModel, jobseekerId: number, moderationLastChangeDate: Date }>>()
);
export const uploadJobseekerDocumentForAdminSuccess = createAction(
    Actions.UploadJobseekerDocumentForAdminSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const uploadJobseekerDocumentForAdminFail = createAction(
    Actions.UploadJobseekerDocumentForAdminFail,
    props<ActionPayload<ApiError>>()
);
export const closeJobseekerUploadDocumentFormForAdmin = createAction(
    Actions.CloseJobseekerUploadDocumentFormForAdmin
);

export const editJobseekerWorkExperienceForAdmin = createAction(
    Actions.EditJobseekerWorkExperienceForAdmin,
    props<ActionPayload<{ jobseekerWorkexperience: JobseekerWorkExperienceModel, jobseekerId: number, moderationLastChangeDate: Date }>>()
);

export const editJobseekerWorkExperienceForAdminSuccess = createAction(
    Actions.EditJobseekerWorkExperienceForAdminSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const editJobseekerWorkExperienceForAdminFail = createAction(
    Actions.EditJobseekerWorkExperienceForAdminFail,
    props<ActionPayload<ApiError>>()
);

export const setIsWorkExperienceFilledForAdmin = createAction(
    Actions.SetIsWorkExperienceFilledForAdmin,
    props<ActionPayload<{isWorkExperienceFilled: boolean, moderationLastChangeDate:Date, jobseekerId: number}>>()
);

export const setIsWorkExperienceFilledForAdminSuccess = createAction(
    Actions.SetIsWorkExperienceFilledForAdminSuccess,
    props<ActionPayload<Jobseeker>>()
);

export const setIsWorkExperienceFilledForAdminFail = createAction(
    Actions.SetIsWorkExperienceFilledForAdminFail,
    props<ActionPayload<ApiError>>()
);

export const setSelectedCategoriesForAdmin = createAction(
    Actions.SetSelectedCategoriesForAdmin,
    props<ActionPayload<number[]>>()
);

export const setJobseekerWorkExperienceForAdmin = createAction(
    Actions.SetJobseekerWorkExperienceForAdmin,
    props<ActionPayload<JobseekerWorkExperienceViewModel>>()
);

export const closeWorkExperienceEditFormForAdmin = createAction(
    Actions.CloseWorkExperienceEditFormForAdmin
);

export const changeWorkExperienceIsCurrentForAdmin = createAction(
    Actions.ChangeWorkExperienceIsCurrentForAdmin
);

export const editJobseekerPhotoForAdmin = createAction(
    Actions.EditJobseekePhotoForAdmin,
    props<ActionPayload<{jobseekerPhotoModel: string, moderationLastChangeDate: Date, jobseekerId: number}>>()
);
export const editJobseekerPhotoForAdminSuccess = createAction(
    Actions.EditJobseekerPhotoForAdminSuccess,
    props<ActionPayload<Jobseeker>>()
);
export const editJobseekerPhotoForAdminFail = createAction(
    Actions.EditJobseekerPhotoForAdminFail,
    props<ActionPayload<ApiError>>()
);
export const selectJobseekerPhotoForAdmin = createAction(
    Actions.SelectJobeekerPhoto
);
export const insertCroppedImageForAdmin = createAction(
    Actions.InsertCroppedImage,
    props<ActionPayload<{base64: string}>>()
);
export const closeJobseekerPhotoEditFormForAdmin = createAction(
    Actions.CloseJobseekerPhotoEditFormForAdmin
);
export const approveJobseekerPhotoForAdmin = createAction(
    Actions.ApproveJobseekerPhoto
);
//#endregion

export const setSelectedBirthMonth = createAction(
    Actions.SetSelectedBirthMonth,
    props<ActionPayload<number>>()
);

export const setSelectedBirthYear = createAction(
    Actions.SetSelectedBirthYear,
    props<ActionPayload<number>>()
);

export const updateJobseekerAddress = createAction(
    Actions.UpdateJobseekerAddress,
    props<ActionPayload<string>>()
);

export const updateSameAsCurrentAddress = createAction(
    Actions.UpdateSameAsCurrentAddress,
    props<ActionPayload<boolean>>()
);

export const updateJobseekerLegalAddress = createAction(
    Actions.UpdateJobseekerLegalAddress,
    props<ActionPayload<string>>()
);

//CV export
export const jobseekerCvExport = createAction(
    Actions.JobseekerCvExport
);

export const jobseekerCvExportSuccess = createAction(
    Actions.JobseekerCvExportSuccess
);

export const jobseekerCvExportFailed = createAction(
    Actions.JobseekerCvExportFailed,
    props<ActionPayload<ApiError>>()
);