<header class="header">
    <nav class="navigation">
        <ul class="navigation-list">
            <li class="list-item logo">
                <a *ngIf="!isActive('/')" class="logo__link" [routerLink]="['/']">
                    <img class="logo__img" src="assets/img/common/logo.svg" alt="" />
                </a>
                <a *ngIf="isActive('/')" class="logo__link" [routerLink]="['/']">
                    <img class="logo__img" src="assets/img/common/logo.svg" alt="" />
                </a>
            </li>
    
            <ul class="header-items header-items--small-size">
                <li *ngIf="!isBurgerMenuOpen" class="profile profile--small-size">
                    <a [routerLink]="['/admin/edit']">
                        <img class="profile__icon--mobile" src="assets/img/header/jobseeker-profile-icon.svg" alt="" />
                    </a>
                </li>
                <li class="burger-menu">
                    <button class="menu burger-menu__btn" (click)="toggleBurgerMenu()" >
                        <img class="burger-menu__icon" src="assets/img/header/burger-menu-icon.svg" alt="" />
                    </button>
                </li>
            </ul>

            <ul [ngClass]="{'burger-menu--opened': isBurgerMenuOpen}" class="header-items basic-list" appClickOutside (clickOutside)="closeDropdown()" menuClass="menu">
                <li class="basic-list__item profile profile--desktop">
                    <div (click)="toggleProfile()" class="menu">
                        <div class="text profile__jobseeker-name">
                          {{ user?.name }}
                        </div>
                        <img class="profile__icon" src="assets/img/header/jobseeker-profile-icon.svg" alt="" />
                        <img class="profile__down-arrow-icon" src="assets/img/common/down-arrow-icon.svg" alt="" />
                      </div>
                </li>

                <ul *ngIf="isProfileOpen" class="profile-list">
                    <li class="profile-list__item language" *ngIf="defaultLanguage !== null && alternativeLanguage !== null">
                        <div (click)="toggleLanguage()" class="profile-list__text" menuNavItem>
                            {{ (currentLanguage == defaultLanguage ? ('label.lang-'+ alternativeLanguage) : ('label.lang-'+ defaultLanguage)) | translate }}
                        </div>
                    </li>
                    <li class="profile-list__item sign-out">
                        <a (click)="onSignOutClick()" style="cursor: pointer;" menuNavItem>
                            <div class="profile-list__text">{{ 'label.SignOut' | translate }}</div>
                        </a>
                    </li>
                </ul>
            </ul>
        </ul>
    </nav>
</header>
