import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserTypeEnum } from '@hrra/common-models';
import { IconButtonTypeEnum } from '@hrra/ui';

@Component({
  selector: 'hrra-jobseeker-photo',
  templateUrl: './jobseeker-photo.component.html',
  styleUrls: ['./jobseeker-photo.component.scss']
})
export class JobseekerPhotoComponent implements OnInit {

  public iconButtonType: any;
  public type: any;
  
  @Input() photoHref: string;
  @Input() photoIsInModeration: boolean;
  @Input() userType: UserTypeEnum;

  @Output() onEditPhoto: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onDeletPhoto: EventEmitter<void> = new EventEmitter<void>();
  
  constructor() {this.iconButtonType = IconButtonTypeEnum; this.type = UserTypeEnum }

  ngOnInit(): void {
  }

  public onEdit(isInModeration: boolean){
    this.onEditPhoto.emit(isInModeration);
  }

  public onDelete(){
    this.onDeletPhoto.emit();
  }
}
