import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { uploadTempFileForAdmin } from 'src/app/root-store/jobseeker-store/jobseeeker-profile-for-admin/actions';
import { setFileSizeValidation, uploadTempFile } from 'src/app/root-store/jobseeker-store/jobseeker-profile/actions';
import { AppState } from 'src/app/root-store/state';

@Injectable({
  providedIn: 'root'
})
export class JobseekerFileService {

  public uploadTempFile(event, fileLimit){
    let isFileUploadCanceled = event.target.files.length == 0;
    let file = event.target.files[0];

    let fileSize = !isFileUploadCanceled ? file.size : 0;
    let isFileSizeValid = fileLimit > fileSize;
    this.store.dispatch(setFileSizeValidation({payload: isFileSizeValid}));
    
    if(!isFileUploadCanceled){
      const fd = new FormData();
      fd.append('file', file);
      if(isFileSizeValid){
        this.store.dispatch(uploadTempFile({payload: fd}));
      }
    }
  }

  public uploadTempFileForAdmin(event, fileLimit, jobseekerId){
    let isFileUploadCanceled = event.target.files.length == 0;
    let file = event.target.files[0];

    let fileSize = !isFileUploadCanceled ? file.size : 0;
    let isFileSizeValid = fileLimit > fileSize;
    this.store.dispatch(setFileSizeValidation({payload: isFileSizeValid}));
    
    if(!isFileUploadCanceled){
      const fd = new FormData();
      fd.append('file', file);
      fd.append('jobseekerId', jobseekerId);
      if(isFileSizeValid){
        this.store.dispatch(uploadTempFileForAdmin({payload: fd }));
      }
    }
  }
  
  constructor(private store: Store<AppState>) { }
}
